.newsletterBlock {
    .lazyBackground {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .wrapperInput {
        h2 { color: #fff; }
        h5 { margin-bottom: 40px; }

        p {
            @include medium-title();
            color: #fff !important;
        }

        .input-field {
            display: inline-block;
            width: 100%;
            max-width: 460px;
            margin-bottom: 0;

            label {
                color: #fff;
            }

            input {
                color: #fff;
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:active
            input:-webkit-autofill:focus input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 1000px #000 inset !important;
                -webkit-text-fill-color: #fff !important;
            }

            input:-webkit-autofill::first-line {
                font: 18px/45px $font1;
            }

            @media screen and (max-width: 600px) {
                display: block;
                width: 100%;
            }

            .submitNewsletter {
                position: absolute;
                width: 16px;
                height: 45px;
                top: 50%;
                right: 0;
                transform: translateY(-50%);

                svg {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    fill: $color4;
                    transition: fill 300ms;
                }

                &:hover svg { fill: $color2; }
            }
        }
    }
}
