.packagesBlock {
    position: relative;
    display: flex;
    flex-direction: column;

    .titleSection {
        margin-bottom: 75px;

        @media screen and (max-width: 1024px) { margin-bottom: 50px; }
        @media screen and (max-width: 768px)  { margin-bottom: 30px; }
    }

    .wrapper {
        display: flex;
        height: 100%;
        justify-content: center;

        .forfait {
            &:first-child:nth-last-child(1) {
                width: 50%;
                margin-right: 0;
            }
            &:only-child {
                @media screen and (max-width: 900px)  {
                    width: 100% !important;
                }
            }
        }

        @media screen and (max-width: 900px)  { flex-wrap: wrap; }
    }

    .btn {
        margin-top: 75px;

        @media screen and (max-width: 1024px) { margin-top: 50px; }
        @media screen and (max-width: 768px)  { margin-top: 30px; }
    }
}
