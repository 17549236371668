// IMPORTATION __________
@import "../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../node_modules/slick-carousel/slick/slick.scss";

// Stylesheet
    // Base
    @import 'stylesheet/base/variables.scss';
    @import 'stylesheet/base/mixins.scss';
    @import 'stylesheet/base/resets.scss';
    @import 'stylesheet/base/grid.scss';
    @import 'stylesheet/base/fonts.scss';
    @import 'stylesheet/base/base.scss';
    @import 'stylesheet/base/buttons.scss';
    @import 'stylesheet/base/links.scss';
    @import 'stylesheet/base/titles-and-texts.scss';
    @import 'stylesheet/base/dynamic.scss';
    @import 'stylesheet/base/helper.scss';

// Components
//@import 'components/tiroirs.scss';
@import 'components/hamburgerBtn.scss';
@import 'components/blockStyle1.scss';
@import 'components/blockStyle2.scss';
@import 'components/blockStyle3.scss';
@import 'components/pictureBlock.scss';
@import 'components/buttonBlock.scss';
@import 'components/stickyBtn.scss';
    // Animation
    @import 'components/animation/scrollFire.scss';
    @import 'components/animation/transition.scss';
    // Formulaire
    @import 'components/formulaire/formulaire.scss';
    @import 'components/formulaire/input.scss';
    @import 'components/formulaire/select.scss';
    @import 'components/formulaire/textarea.scss';
    @import 'components/formulaire/radiobox.scss';

// Partials
@import 'partials/header.scss';
@import 'partials/footer.scss';
    // Overlays
    @import 'partials/overlays/share.scss';
    @import 'partials/overlays/menu.scss';
    @import 'partials/overlays/search.scss';
    @import 'partials/overlays/modal.scss';
    @import 'partials/overlays/overlay-cookies.scss';
    // Blocks
    @import 'partials/blocks/newsletter.scss';
    @import 'partials/blocks/packages.scss';
    @import 'partials/blocks/convenience.scss';
    @import 'partials/blocks/header.scss';
    @import 'partials/blocks/rooms.scss';
    @import 'partials/blocks/bandeau.scss';
    @import 'partials/blocks/pagination.scss';
    @import 'partials/blocks/events.scss';
    @import 'partials/blocks/news.scss';
    @import 'partials/blocks/sectionBlock.scss';
    @import 'partials/blocks/package.scss';
    @import 'partials/blocks/searchResult.scss';

// Pages
@import 'pages/generic.scss';
@import 'pages/home.scss';
@import 'pages/inns.scss';
@import 'pages/inn.scss';
@import 'pages/error.scss';
@import 'pages/contact.scss';
@import 'pages/jobs.scss';
@import 'pages/jobsDetails.scss';
@import 'pages/news.scss';
@import 'pages/newsDetails.scss';
@import 'pages/events.scss';
@import 'pages/eventsDetails.scss';
@import 'pages/packages.scss';
@import 'pages/packagesDetails.scss';
@import 'pages/search.scss';

// Stylesheet
    // Modules
    @import 'stylesheet/modules/form';
    @import 'stylesheet/modules/field';
    @import 'stylesheet/modules/today';
    @import 'stylesheet/modules/side-and-list';
    @import 'stylesheet/modules/search-results';
    @import 'stylesheet/modules/rubrics';
    @import 'stylesheet/modules/documents';
    @import 'stylesheet/modules/gallery';
    @import 'stylesheet/modules/calendar';
    @import 'stylesheet/modules/alerts';
    @import 'stylesheet/modules/arrowSection';
    @import 'stylesheet/modules/transitions';

        // Overlays
        @import 'stylesheet/modules/overlays/mobile-menu';
        @import 'stylesheet/modules/overlays/dropdown';
        @import 'stylesheet/modules/overlays/calendar';

        // Forms
        @import 'stylesheet/modules/forms/module-forms';
        @import 'stylesheet/modules/forms/module-input';
        @import 'stylesheet/modules/forms/module-textarea';
        @import 'stylesheet/modules/forms/module-checkbox';
        @import 'stylesheet/modules/forms/module-radio';
        @import 'stylesheet/modules/forms/module-file';
        @import 'stylesheet/modules/forms/module-select';
        @import 'stylesheet/modules/forms/module-newsletter';
        @import 'stylesheet/modules/forms/module-search';
        @import 'stylesheet/modules/forms/module-dropzone';

    // States
    @import 'stylesheet/states/states';

        // Overlays
        @import 'stylesheet/states/overlays/mobile-menu';
        @import 'stylesheet/states/overlays/dropdown';
        @import 'stylesheet/states/overlays/fast-links';
        @import 'stylesheet/states/overlays/calendar';
