// Le bouton
.btn {
    padding: 25px 50px;
    font: 12px/1 $font1b;
    color: $color1;
    border: 1px solid $color4;
    text-transform: uppercase;
    transition: color 300ms, border 300ms, background-color 300ms;

    @media screen and (max-width: 900px) { padding: 20px 40px; }

    &:hover {
        color: $color4;
    }

    &.banner {
        margin: 0 auto;
    }

    &.darkWhite {
        color: #fff;
        background: $color1;
        border: none;
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;

        svg {
            width: 22px;
            height: 22px;
            transition: stroke 300ms;
            stroke: #fff;
            fill: transparent;
            margin-left: 10px;
        }

        &:hover {
            color: $color4;
            svg {
                stroke: $color4;
            }
        }
    }

    &.full {
        color: $color2;
        background: $color1;

        &:hover {
            color: #fff;
        }
    }

    &.white {
        color: #fff;
        border: 1px solid #fff;

        &.full {
            color: $color1;
            background: #fff;

            &:hover { color: $color4; }
        }

        &:hover { color: $color4; }
    }

    &.form {
        color: #fff;
        cursor: pointer;

        &.white {
            background-color: transparent;
            border: 1px solid #fff;

            &.disabled {
                cursor: default;
                border: 1px solid rgba(#fff, 0.2);
                background-color: rgba(#fff, 0.2);
            }
        }

        &.darkWhite {
            background-color: $color1;
            border: none;

            &.disabled {
                cursor: default;
                background-color: rgba($color1, 0.6);

                &:hover {
                    color: #fff;
                }
            }
        }
    }

}
