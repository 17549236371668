.overlay-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 105;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
        cursor: default;
    }

    &__container {
        position: absolute;
        padding: 105px 85px 85px 85px;
        width: 740px;
        top: 50%;
        left: 50%;
        background: #fff;
        text-align: center;
        opacity: 0;
        z-index: 151;
        pointer-events: none;
        transform: translateX(-50%);
        transition: opacity 800ms $easeInOutQuart, transform 800ms $easeInOutQuart;

        @media screen and (max-width: 1200px) { width: 580px; }
        @media screen and (max-width: 1024px) { width: 525px; padding: 105px 50px 50px 50px; }
        @media screen and (max-width: 600px)  {
            width: auto;
            right: 20px;
            left: 20px;
            padding: 105px 20px 50px;
            transform: none;
        }
    }

    &__title {
        margin-bottom: 30px;
        font-size: rem(38);

        @media screen and (max-width: 1024px) { font-size: rem(33); }
        @media screen and (max-width: 768px)  { font-size: rem(28); }
        @media screen and (max-width: 500px)  { font-size: rem(25); }
    }

    &__desc {
        margin-bottom: 30px;
    }

    &__close {
        position: absolute;
        width: 75px;
        height: 75px;
        top: 0;
        right: 0;
        cursor: pointer;
        background-color: #000;

        &:hover { &:before, &:after { background: $color5; }}

        &:before, &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: #fff;
            transition: background 300ms;
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg) ; }
        &:after { transform: translate(-50%, -50%) rotate(-45deg) ; }
    }
}

.show-overlay-modal {
    .overlay-modal {
        pointer-events: all;
        visibility: visible;
        transition: none;

        &__background { background: rgba(#000, 0.4); }

        &__container {
            opacity: 1;
            pointer-events: all;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 600px) { transform: translateY(-50%); }
        }
    }
}
