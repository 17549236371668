.roomsBlock {
    position: relative;

    #slickRoomsNav {
        position: absolute;
        top: 250px;
        right: 52%;
        transform: rotate(-90deg) translateX(0.5px);
        z-index: 1;

        @media screen and (max-width: 1400px) { right: 50%; }
        @media screen and (max-width: 1000px) { right: 49%; }
        @media screen and (max-width: 900px)  {
            top: 80px;
            right: auto;
            left: 30px;
            transform: none;
        }
        @media screen and (max-width: 768px)  {
            top: 430px;
            left: 50%;
            transform: translateX(calc(-50% - 0.5px));
        }
        @media screen and (max-width: 600px) { top: 330px; }

        a {
            color: $color5;
            font: 11px/1 $font1;
            text-transform: uppercase;
            letter-spacing: 2px;
            transition: color 300ms;

            &:first-child {
                margin-right: 30px;
            }

            &:hover {
                color: $color4;
            }
        }
    }

    #slickRooms {
        .slick-list, .slick-track { height: 100%; }
        .slick-list {
            .slick-slide {
                @media screen and (max-width: 768px) { text-align: center; }

                &.slick-active {
                    .bigImage div { transform: translateX(0%); transition: transform 500ms $ease; }
                    .image div { transform: translateX(0%); transition: transform 500ms $ease; }
                }

                .bigImage {
                    position: absolute;
                    width: 45%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    overflow: hidden;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    @media screen and (max-width: 1100px) { width: 53%; right: 0; }
                    @media screen and (max-width: 768px)  {
                        position: relative;
                        width: 100%;
                        height: 400px;
                    }
                    @media screen and (max-width: 600px)  { height: 300px; }

                    div {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        transform: translateX(100%);
                        transition: transform 500ms $ease 500ms;
                    }
                }

                .image {
                    position: relative;
                    height: 400px;
                    margin-bottom: 50px;
                    overflow: hidden;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    @media screen and (max-width: 1500px) { height: 300px; }
                    @media screen and (max-width: 900px)  { height: 250px; margin-top: 25px; }
                    @media screen and (max-width: 768px)  {
                        max-width: 350px;
                        margin: 0 auto 20px;
                    }
                    @media screen and (max-width: 600px) { display: none; }

                    div {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        transform: translateX(100%);
                        transition: transform 500ms $ease 500ms;
                    }
                }

                h4 {
                    margin-bottom: 35px;

                    @media screen and (max-width: 768px)  { margin-bottom: 20px; }
                }

                p {
                    font: 15px/2 $font1l;
                    color: $color5;

                    &:nth-child(2) {
                        font-size: 12px;
                        color: $color1;
                    }
                }

                .btn {
                    margin-top: 50px;

                    @media screen and (max-width: 1024px) { margin-top: 30px; }
                    @media screen and (max-width: 768px)  { margin-top: 20px; }
                }
            }
        }
    }
}
