// Le fond noir de chaque popup ou sidepanel
#overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0);
    visibility: hidden;
    z-index: 9;
    transition: background 500ms ease, visibility 0ms ease 300ms, z-index 0ms ease 700ms;
}

// Titre de section dans l'accueil
.titleSection {
    text-align: center;
}


.pagination {
    width: 72px;

    span {
        font: 22px/1 $font1;
        color: #fff;

        &.current {
            position: relative;
            padding-right: 20px;

            &:before {
                content: '';
                position: absolute;
                width: 1px;
                height: 20px;
                top: 50%;
                right: 8px;
                transform: translateY(-50%);
                background: #fff;

                @media screen and (max-width: 768px) { background: $color4; }
            }
        }

        &.total {
            font: 12px/1 $font1b;
        }
    }
}

.image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


//
// OCTOBER
//
// Les messages d'erreurs et de succès d'october
html > body > p.flash-message {
    position: fixed;
    width: 500px;
    padding: 20px 30px 20px 15px;
    margin-left: -250px;
    top: 15px;
    left: 50%;
    color: #000;
    font: 14px/1 $font1l;
    z-index: 10300;
    word-wrap: break-word;
    text-shadow: none;
    text-align: center;
    box-shadow: 0 3px 10px rgba(0,0,0,0.12), 0 3px 10px rgba(0,0,0,0.24);
    border-radius: 0px;

    &.error { background: $color3; }
    &.success { background: $color3; }

    &.fade {
        opacity: 0;
        filter: alpha(opacity=0);
        transform: scale(0.9);
        transition: all 0.5s, width 0s;
    }

    &.fade.in {
        opacity: 1;
        filter: alpha(opacity=100);
        transform: scale(1);
    }

    button.close {
        float: none;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -11px;
        color: #000;
        font-size: 21px;
        line-height: 1;
        font-weight: bold;
        opacity: 0.5;
        filter: alpha(opacity=20);
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        outline: none;
        transition: opacity 300ms;

        &:hover {
            color: #000;
            opacity: 1;
        }
    }
}


// La barre de chargement
body .stripe-loading-indicator , .stripe-loading-indicator {
    .stripe, .stripe-loaded {
        background: $color2;
    }
}













/*
|--------------------------------------------------------------------------
| PADDING X
|--------------------------------------------------------------------------
*/
:root {
    --x-padding-160: 160px;
    --x-padding-80: 80px;
    --x-padding-50: 50px;

    @media screen and (min-width: 1601px) {
        --x-padding-160: 15vw;
    }
    @media screen and (max-width: 1400px) {
        --x-padding-160: 130px;
    }
    @media screen and (max-width: 1200px) {
        --x-padding-160: 100px;
    }
    @media screen and (max-width: 1024px) {
        --x-padding-160: 50px;
        --x-padding-80: 50px;
    }
    @media screen and (max-width: 768px) {
        --x-padding-160: 30px;
        --x-padding-80: 30px;
        --x-padding-50: 30px;
    }
    @media screen and (max-width: 600px) {
        --x-padding-160: 25px;
        --x-padding-80: 25px;
        --x-padding-50: 25px;
    }
    @media screen and (max-width: 320px) {
        --x-padding-50: 20px;
    }
}

.x-padding-160 { @extend .right-padding-160; @extend .left-padding-160; }
.right-padding-160 { padding-right: var(--x-padding-160); }
.left-padding-160 { padding-left:  var(--x-padding-160); }

.x-padding-80 { @extend .right-padding-80; @extend .left-padding-80; }
.right-padding-80 { padding-right: var(--x-padding-80); }
.left-padding-80 { padding-left:  var(--x-padding-80); }

.x-padding-50 { @extend .right-padding-50; @extend .left-padding-50; }
.right-padding-50 { padding-right: var(--x-padding-50); }
.left-padding-50 { padding-left:  var(--x-padding-50); }



/*
|--------------------------------------------------------------------------
| PADDING Y
|--------------------------------------------------------------------------
*/
:root {
    --y-padding-160: 160px;
    --y-padding-120: 120px;
    --y-padding-100: 100px;
    --y-padding-90: 90px;
    --y-padding-80: 80px;
    --y-padding-70: 70px;
    --y-padding-60: 60px;
    --y-padding-50: 50px;
    --y-padding-40: 40px;
    --y-padding-30: 30px;

    @media screen and (max-width: 1400px) {
        --y-padding-160: 140px;
        --y-padding-120: 110px;
    }
    @media screen and (max-width: 1200px) {
        --y-padding-160: 120px;
        --y-padding-120: 110px;
    }
    @media screen and (max-width: 1024px) {
        --y-padding-160: 110px;
        --y-padding-120: 100px;
        --y-padding-100: 90px;
        --y-padding-90:  80px;
        --y-padding-80:  70px;
        --y-padding-50:  40px;
    }
    @media screen and (max-width: 768px)  {
        --y-padding-160: 90px;
        --y-padding-120: 80px;
        --y-padding-100: 80px;
        --y-padding-90:  70px;
        --y-padding-80:  60px;
        --y-padding-70:  50px;
        --y-padding-60:  40px;
        --y-padding-50:  30px;
        --y-padding-40:  30px;
    }
    @media screen and (max-width: 500px)  {
        --y-padding-160: 50px;
        --y-padding-120: 50px;
        --y-padding-100: 50px;
        --y-padding-90:  50px;
        --y-padding-80:  50px;
        --y-padding-70:  40px;
        --y-padding-60:  30px;
    }
}

.y-padding-160 { @extend .top-padding-160; @extend .bottom-padding-160; }
.top-padding-160 { padding-top: var(--y-padding-160); }
.bottom-padding-160 { padding-bottom: var(--y-padding-160); }

.y-padding-120 { @extend .top-padding-120; @extend .bottom-padding-120; }
.top-padding-120 { padding-top: var(--y-padding-120); }
.bottom-padding-120 { padding-bottom: var(--y-padding-120); }

.y-padding-100 { @extend .top-padding-100; @extend .bottom-padding-100; }
.top-padding-100 { padding-top: var(--y-padding-100); }
.bottom-padding-100 { padding-bottom: var(--y-padding-100); }

.y-padding-90 { @extend .top-padding-90; @extend .bottom-padding-90; }
.top-padding-90 { padding-top: var(--y-padding-90); }
.bottom-padding-90 { padding-bottom: var(--y-padding-90); }

.y-padding-80 { @extend .top-padding-80; @extend .bottom-padding-80; }
.top-padding-80 { padding-top: var(--y-padding-80); }
.bottom-padding-80 { padding-bottom: var(--y-padding-80); }

.y-padding-70 { @extend .top-padding-70; @extend .bottom-padding-70; }
.top-padding-70 { padding-top: var(--y-padding-70); }
.bottom-padding-70 { padding-bottom: var(--y-padding-70); }

.y-padding-60 { @extend .top-padding-60; @extend .bottom-padding-60; }
.top-padding-60 { padding-top: var(--y-padding-60); }
.bottom-padding-60 { padding-bottom: var(--y-padding-60); }

.y-padding-50 { @extend .top-padding-50; @extend .bottom-padding-50; }
.top-padding-50 { padding-top: var(--y-padding-50); }
.bottom-padding-50 { padding-bottom: var(--y-padding-50); }

.y-padding-40 { @extend .top-padding-40; @extend .bottom-padding-40; }
.top-padding-40 { padding-top: var(--y-padding-40); }
.bottom-padding-40 { padding-bottom: var(--y-padding-40); }

.y-padding-30 { @extend .top-padding-30; @extend .bottom-padding-30; }
.top-padding-30 { padding-top: var(--y-padding-30); }
.bottom-padding-30 { padding-bottom: var(--y-padding-30); }




/*
|--------------------------------------------------------------------------
| margin Y
|--------------------------------------------------------------------------
*/
:root {
    --y-margin-70: 70px;
    --y-margin-50: 50px;
    --y-margin-40: 40px;
    --y-margin-30: 30px;
    --y-margin-20: 20px;

    @media screen and (max-width: 1200px) {
        --y-margin-70: 60px;
    }
    @media screen and (max-width: 1024px) {
        --y-margin-70: 50px;
        --y-margin-50: 40px;
        --y-margin-40: 30px;
        --y-margin-30: 25px;
    }
    @media screen and (max-width: 768px)  {
        --y-margin-70: 40px;
        --y-margin-50: 30px;
        --y-margin-30: 20px;
        --y-margin-20: 15px;
    }
    @media screen and (max-width: 500px)  {
        --y-margin-70: 30px;
        --y-margin-50: 25px;
        --y-margin-40: 20px;
        --y-margin-30: 15px;
        --y-margin-20: 10px;
    }
}

.y-margin-70 { @extend .top-margin-70; @extend .bottom-margin-70; }
.top-margin-70 { margin-top: var(--y-margin-70); }
.bottom-margin-70 { margin-bottom: var(--y-margin-70); }

.y-margin-50 { @extend .top-margin-50; @extend .bottom-margin-50; }
.top-margin-50 { margin-top: var(--y-margin-50); }
.bottom-margin-50 { margin-bottom: var(--y-margin-50); }

.y-margin-40 { @extend .top-margin-40; @extend .bottom-margin-40; }
.top-margin-40 { margin-top: var(--y-margin-40); }
.bottom-margin-40 { margin-bottom: var(--y-margin-40); }

.y-margin-30 { @extend .top-margin-30; @extend .bottom-margin-30; }
.top-margin-30 { margin-top: var(--y-margin-30); }
.bottom-margin-30 { margin-bottom: var(--y-margin-30); }

.y-margin-20 { @extend .top-margin-20; @extend .bottom-margin-20; }
.top-margin-20 { margin-top: var(--y-margin-20); }
.bottom-margin-20 { margin-bottom: var(--y-margin-20); }



/*
|--------------------------------------------------------------------------
| IMAGES
|--------------------------------------------------------------------------
*/
picture {
    display: block;
    font-size: 0;

    img { width: 100%; height: 100%; }

    [data-object-fit='cover'] {
        object-fit: cover;
        font-family: 'object-fit: cover;';
    }
}

.stripe-loading-indicator {
    display: none;
}

.share-link {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 15px 30px;
    font: 700 rem(12)/1 $font1;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: $color1;
    transition: color 300ms;

    @media screen and (max-width: 768px) { padding: 10px 20px; }

    &__svg {
        position: relative;
        width: 14px;
        height: 14px;
        margin-left: 10px;
        top: 3px;
        fill: #fff;
        stroke: #fff;
        transition: stroke 300ms, fill 300ms;
    }

    &:hover {
        color: $color4;

        .share-link__svg {
            fill: $color4;
            stroke: $color4;
        }
    }
}


input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill,
select:-webkit-autofill:hover, select:-webkit-autofill:focus { -webkit-box-shadow: 0 0 0 1000px #fff inset !important; }

input:-webkit-autofill {
    -webkit-text-fill-color: $color1 !important;
}
