// UN TEXTAREA __________
.text-field {
    textarea {
        width: 100%;
        height: 45px;
        padding: 15px 0 0 0;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $color1;
        font: 18px/1.1 $font1;
        color: $color1;
        resize: none;
        overflow: hidden;
        transition: border 300ms;

        &:focus {
            border-bottom-color: $color1;
        }
        &.valid {
            border-bottom: 1px solid $color1;
        }
        &.error { border-bottom-color: #eb3f3f !important; }
    }

    label {
        position: absolute;
        top: 0px;
        left: 0px;
        font: 18px/45px $font1;
        color: $color1;
        cursor: text;
        transition: 300ms $ease;

        span {
            font-size: 15px;
            color: $color2;
            transition: color 300ms, font-size 300ms;
        }
    }

    textarea:focus + label,
    textarea.valid + label,
    textarea.error + label,
    textarea.notEmpty + label {
        min-height: 90px;
        top: -12px;
        font-size: 11px;
        line-height: 12px;
        cursor: default;
        pointer-events: none;

        span {
            font-size: 11px;
            color: $color5;
        }
    }

    &.white {
        textarea {
            border-bottom-color: $color5;
            color: #fff;

            &:focus { border-bottom-color: $color2; }
            &.valid { border-bottom-color: $color2; }
        }

        label {
            color: #fff;
        }

        textarea:focus + label,
        textarea.valid + label,
        textarea.error + label,
        textarea.notEmpty + label {
            color: $color5;
            border-bottom-color: $color2;
        }
    }
}
