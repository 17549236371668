.overlay-dropdown {
    position: relative;
    width: 100%;
    visibility: hidden;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }

    &__container {
        position: absolute;
        width: 100%;
        height: 0px;
        top: 0;
        left: 0;
        background: #fff;
        border-top: 1px solid rgba($color1, 0.2);
        overflow: hidden;
    }

    &__section {
        position: absolute;
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        visibility: hidden;
        opacity: 0;
        max-height: calc(100vh - 140px);
        scrollbar-width: none;

        &::-webkit-scrollbar { display: none; }
    }

    &__sub-section-container {
        display: inline-block;
        text-align: left;
        transform: translateY(100px);
    }

    &__sub-section-title {
        margin-bottom: 35px;
        @extend .tiny-title;

        @media screen and (max-width: 1400px) { margin-bottom: 30px; }
        @media screen and (max-width: 1200px) { margin-bottom: 20px; }
    }

    &__list {
        height: 350px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    &__list-item {
        &:not(:last-child) {
            margin-bottom: 15px;
            margin-right: 80px;

            @media screen and (max-width: 1200px), screen and (max-height: 700px) { margin-bottom: 10px; margin-right: 70px; }
        }
    }

    &__link {
        font-size: rem(22);
        color: $color1;
        transition: color 300ms $easeOutCubic;

        &:hover {
            color: $color2;
        }
    }
}
