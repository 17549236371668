.side-and-list {
    --news-list-gaps: 60px;
    --news-list-max-width: 950px;

    @media screen and (max-width: 1300px) { --news-list-gaps: 40px; }
    @media screen and (max-width: 1200px) { --news-list-gaps: 30px; }
    @media screen and (max-width: 1024px) { --news-list-gaps: 50px; }
    @media screen and (max-width: 900px)  { --news-list-gaps: 35px; }
    @media screen and (max-width: 768px)  { --news-list-gaps: 20px; }
    @media screen and (max-width: 600px)  { --news-list-gaps: 30px; }
}

.side-and-list {
    &__container {
        background: $color3;
        padding-top: 60px;
        @extend .bottom-padding-120;

        @media screen and (max-width: 1024px) {
            padding-right: var(--x-padding-160);
            padding-left: var(--x-padding-160);
        }
        @media screen and (max-width: 500px) { padding-top: 50px; }
    }

    &__grid {
        display: flex;
        flex-flow: row wrap;
    }

    &__side {
        position: sticky;
        top: var(--news-list-gaps);
        width: 325px;
        height: 100%;
        z-index: 2;

        @media screen and (max-width: 1200px) { width: 275px; }
        @media screen and (max-width: 1024px) { width: 100%; position: relative; top: 0; }
        @media screen and (max-width: 600px)  { height: auto; }
    }

    &__main {
        flex: 1;
        @extend .x-padding-160;


    }

    &__infos, &__list, &__pagination {
        max-width: var(--news-list-max-width);
        margin: 0 auto;

        @media screen and (max-width: 1440px) { padding-left: 0; }
        @media screen and (max-width: 1024px) { padding-right: 0; }
    }

    &__infos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @extend .bottom-padding-30;

        @media screen and (max-width: 600px) { padding-top: 20px; }
        @media screen and (max-width: 500px) { display: block; padding-top: 20px; }
    }

    &__infos-title {
        @extend .large-title;

        @media screen and (max-width: 500px) { text-align: center; }
    }

    &__infos-btn {
        @media screen and (max-width: 500px) { display: none; }
    }

    &__list {
        display: flex;
        flex-flow: row wrap;
    }

    &__category {
        margin-left: 60px;
        margin-bottom: 10px;

        @media screen and (max-width: 1200px) { margin-left: 30px; }
        @media screen and (max-width: 1024px) { display: none; }
    }

    &__pagination {
        @extend .top-padding-80;
    }

    .field {
        display: none;
        width: calc(50% - (var(--news-list-gaps) / 2));
        margin-bottom: var(--news-list-gaps);
        z-index: 2;

        @media screen and (max-width: 1024px) { display: block; }
        @media screen and (max-width: 600px)  { width: 100%; margin-bottom: 0; }
    }
}
