.hamburger {
    position: relative;
    width: 22px;
    height: 15px;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background: #fff;
        opacity: 1;
        left: 0%;
        transition: 250ms;

        &:nth-child(1) { top: 0px; }
        &:nth-child(2), &:nth-child(3) { top: calc(50% - 0.5px); }
        &:nth-child(4) { top: 14px; }
    }
}

.showOverlayMenu {
    .hamburger {
        span {
            &:nth-child(1) { width: 0%; opacity: 0; left: 50%; }
            &:nth-child(2) { transform: rotate(45deg); }
            &:nth-child(3) { transform: rotate(-45deg); }
            &:nth-child(4) { width: 0%; opacity: 0; left: 50%; }
        }
    }
}
