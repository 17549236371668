.blockStyle1 {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 350px;
    overflow: hidden;

    .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: transform 500ms $ease, opacity 300ms;
    }

    .wrapper {
        position: absolute;
        top: 20px;
        left: 20px;

        p {
            display: inline;
            font: 12px/1.5 $font1b;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 1px;
            box-shadow: 0px 1px 0px 0px $color4;
            transition: box-shadow 500ms;
        }
    }

    &:hover {
        .image { transform: scale(1.1); }
        .wrapper p { box-shadow: 0px 1px 0px 0px #fff; }
    }
}
