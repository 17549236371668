// FORMULAIRE __________
// Form
form {
    display: block;
    width: 100%;

    h3 {
        color: $color3;
    }
}

// Error
div.error {
    position: absolute;
    padding-top: 7px;
    top: 100%;
    font: 12px/1 $font1;
    color: #eb3f3f;
}

.white div.error {
    color: #eb3f3f;
}

.input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field {
    position: relative;
    width: 100%;
    margin-bottom: 80px;

    @media screen and (max-width: 1024px) { margin-bottom: 65px; }
    @media screen and (max-width: 600px) { margin-bottom: 45px; }
}
