.forfait {
    display: flex;
    width: 100%;
    padding: 0;

    @media screen and (max-width: 900px) { margin-bottom: 40px; }
    @media screen and (max-width: 768px) { margin-bottom: 35px; }
    @media screen and (max-width: 600px) { margin-bottom: 30px; }
    @media screen and (max-width: 500px) { margin-bottom: 25px; }

    &:nth-of-type(1) {
        margin-right: 30px;

        @media screen and (max-width: 1400px) { margin-right: 20px; }
        @media screen and (max-width: 1200px) { margin-right: 10px; }
        @media screen and (max-width: 900px)  { margin-right: 0; }
    }
    &:nth-of-type(2) {
        margin-left: 30px;

        @media screen and (max-width: 1400px) { margin-left: 20px; }
        @media screen and (max-width: 1200px) { margin-left: 10px; }
        @media screen and (max-width: 900px)  { margin-left: 0; }
    }

    &:last-child { margin-bottom: 0; }

    .image-container {
        position: relative;
        min-width: 170px;
        width: 170px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        @media screen and (max-width: 1300px) { min-width: 140px; width: 140px; }
        @media screen and (max-width: 900px) { min-width: 170px; width: 170px; }
        @media screen and (max-width: 600px) { min-width: 140px; width: 140px; }
        @media screen and (max-width: 500px) { display: none; }

        .lazyBackground {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            transition: transform 300ms $ease;
        }
    }


    .content {
        width: 100%;
        display: flex;
        flex-direction: column;

        .info {
            display: flex;
            align-items: center;
            flex-grow: 1;
            background-color: $color1;
            padding: 70px 40px 50px;
            min-height: 300px;

            @media screen and (max-width: 1024px) { padding: 20px 25px; }
            @media screen and (max-width: 768px)  { min-height: 250px; }
            @media screen and (max-width: 500px)  { min-height: 1px; padding: 30px 25px; }

            h5 {
                font-size: 12px;
            }

            h3 {
                color: #fff;
                padding-top: 40px;

                @media screen and (max-width: 1024px) { padding-top: 25px; }
                @media screen and (max-width: 768px)  { padding-top: 20px; }
                @media screen and (max-width: 600px)  { padding-top: 15px; }
                @media screen and (max-width: 500px)  { padding-top: 10px; }
            }

            p {
                padding-top: 30px;
                font: 15px/1.5 $font1;
                color: $color4;

                @media screen and (max-width: 1024px) { padding-top: 25px; display: none; }
                @media screen and (max-width: 900px)  { display: block; }
                @media screen and (max-width: 768px)  { padding-top: 20px; }
                @media screen and (max-width: 600px)  { padding-top: 15px; }
                @media screen and (max-width: 500px)  { padding-top: 10px; }
            }
        }

        .price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #000;
            padding: 30px;

            @media screen and (max-width: 1200px) { padding: 25px 30px; }
            @media screen and (max-width: 1024px) { padding: 20px 25px; }
            @media screen and (max-width: 768px)  { padding: 15px 25px; }

            h3 {
                color: $color2;

                span {
                    font-size: 15px;
                }
            }

            .btn {
                height: 64px;
                width: 64px;
                border: 1px solid $color5;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: border 300ms $ease;
                padding: 0;
                margin: 0;

                @media screen and (max-width: 1200px) { width: 55px; height: 55px; }
                @media screen and (max-width: 1024px) { width: 50px; height: 50px; }
                @media screen and (max-width: 768px)  { width: 45px; height: 45px; }

                svg {
                    height: 25%;
                    width: 25%;
                    fill: #fff;
                    transition: fill 300ms $ease;
                }
            }
        }
    }

    &:hover .content .price .btn svg {
        fill: $color5;
    }

    &:hover .lazyBackground {
        transform: scale(1.1);
    }
}
