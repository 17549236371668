.searchResult {
    border-bottom: 1px solid $color4;
    padding-bottom: 60px;
    margin-bottom: 60px;
    width: 100%;

    &:last-child() {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }

    @media screen and (max-width: 1200px) { padding-bottom: 55px; margin-bottom: 55px; }
    @media screen and (max-width: 1024px) { padding-bottom: 50px; margin-bottom: 50px; }
    @media screen and (max-width: 768px)  { padding-bottom: 40px; margin-bottom: 40px; }
    @media screen and (max-width: 500px)  { padding-bottom: 30px; margin-bottom: 30px; }

    &:hover {
        .btn {
            color: $color4;
        }
    }

    h2 {
        font-size: rem(28);
        margin-bottom: 30px;

        @media screen and (max-width: 1024px) { font-size: rem(26); }
        @media screen and (max-width: 768px)  { font-size: rem(24); }
        @media screen and (max-width: 500px)  { font-size: rem(22); }
        @media screen and (max-width: 375px)  { font-size: rem(21); }
    }

    h5 {
        font-size: rem(12);
        color: $color5;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 30px;
    }

    .btn {
        display: inline-block;
    }
}
