.calendar  {
    padding: 0 60px;
    background: #fff;

    @media screen and (max-width: 1024px) {
        padding: 0 40px;
    }
}

.datepicker {
    position: relative;
    width: 100%;
    direction: ltr;
    padding-top: 15px;
}

.datepicker-rtl {
    direction: rtl;
}

.datepicker-rtl.dropdown-menu {
    left: auto;
}

.datepicker-rtl table tr td span {
    float: right;
}

.datepicker-dropdown {
    top: 0;
    left: 0;
}

.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $color4;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid $color4;
}

.datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.datepicker {

    // GLOBAL --------
    table {
        width: 100%;
        table-layout: fixed;
        word-wrap: break-word;
    }
    // ---------------

    // HEAD ----------
    .datepicker-switch, .prev, .next {
        transition: opacity 300ms;
    }

    .prev, .next {
        width: 25px;
        font-size: 0;
        background-image: url('../images/content/icons/arrow.svg');
        background-repeat: no-repeat;
        background-size: 15px 12px;
        cursor: pointer;

        &.disabled {
            visibility: hidden;
        }
    }

    .datepicker-switch {
        width: calc(100% - 50px);
        font: rem(15)/1 $font1b;
        color: $color1;
        text-transform: uppercase;
    }

    .prev {
        transform: scale(-1, -1);
        background-position: 50% 50%;
        transition: background 300ms $easeOutCubic;

        &:hover {
            background-position: 60% 50%;
        }
    }

    .next {
        background-position: 50% 50%;
        transition: background 300ms $easeOutCubic;

        &:hover {
            background-position: 60% 50%;
        }
    }
    // ---------------

    // DAYS ----------
    .dow {
        width: 38px;
        padding-top: 40px;
        padding-bottom: 10px;
        font: rem(12)/1 $font1b;
        color: $color1;
        text-align: center;
        cursor: default;

        @media screen and (max-width: 1024px) { padding-top: 30px; }
        @media screen and (max-width: 1024px) { width: 34px; }
    }
    // ---------------

    // DATES ---------
    .day {
        position: relative;
        width: 38px;
        height: 38px;
        font: rem(12)/1 $font1;
        color: $color5;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;

        @media screen and (max-width: 1024px) { width: 34px; height: 34px; }

        &:before {
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            transition: background 300ms;

            @media screen and (max-width: 1024px) { width: 32px; height: 32px; }
        }

        // TODO: À ajouter à la classe voulu dans le calendrier lorsqu'il sera dynamisé
        // &:nth-child(5):after {
        //     content: '';
        //     position: absolute;
        //     width: 5px;
        //     height: 5px;
        //     top: 10px;
        //     right: 6px;
        //     transform: translate(-50%, -50%);
        //     background-color: $color1;
        //     z-index: 1;
        //     border-radius: 50%;

        //     @media screen and (max-width: 1024px) { width: 4px; height: 4px; right: 6px; }
        // }

        &.disabled, &.disabled:hover {
            background: none;
            cursor: default;

            &:after{
                display: none;
            }
        }

        &:not(.disabled){

            &:after{
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                top: 10px;
                right: 10px;
                transform: translate(-50%, -50%);
                background-color: $color1;
                z-index: 1;
                border-radius: 50%;

                @media screen and (max-width: 600px) { width: 4px; height: 4px; right: 22px; }
            }

            &:hover {
                &:before {
                    background: $color3;
                }
            }
        }
    }

    .old, .new {
        color: $color4;

        &.day.active {
            &:before {
                background: #fff !important;
            }
        }
        &.day.today {
            &:before {
                background: #fff !important;
            }
        }
    }

    .day.today {

        &:before {
            content: '';
            position: absolute;
            width: 14px;
            height: 2px;
            top: auto;
            bottom: 10px;
            left: 50%;
            background-color: $color1;
            border-radius: 0;
            z-index: 1;
            transform: translateX(-50%);

            @media screen and (max-width: 425px) { bottom: 8px; }
        }

        &:hover {
            &:before {
                background-color: $color1;
            }
        }

        &.active {
            &:before {
                content: '';
                position: absolute;
                width: 36px;
                height: 36px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
                transition: background 300ms;

                @media screen and (max-width: 1024px) { width: 32px; height: 32px; }
            }
        }
    }

    .day.active {
        cursor: default;
        color: #fff;

        &:before {
            background: $color1;
        }
        &:after {
            background: #fff;
        }

        &:hover {
            &:before {
                background: $color1;
            }
        }
    }
    // ---------------

    // MONTHS --------
    .month, .year, .decade, .century {
        float: left;
        width: 25%;
        height: 30px;
        font: rem(12)/30px $font1;
        color: $color2;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;

        &:hover {
            background: $color3;
        }

        &.focused {
            color: #fff;
            background: $color1;
        }
    }
    // ---------------
}
