#page-inn {

    #scrollUp {
        @media screen and (max-width: 1024px) {
            bottom: 147px;

            &.footerMobile {
                bottom: 45px;
            }
        }
    }

    .wrapperBtnReserve {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;

        #btnReserve {
            display: block;
            position: fixed;
            width: auto;
            padding: 40px 50px;
            bottom: 0;
            right: 0;
            font: 22px/1 $font2b;
            color: $color1;
            background: $color2;
            text-align: center;
            transition: color 300ms;

            &:hover {
                color: #fff;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 40px 0;
            }
        }
    }

    .headerBlock {
        .myBreadcrumb {
            .btn {
                @media screen and (max-width: 768px) { display: none; }
            }
        }

        .content {
            .title {
                position: relative;

                ul {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    display: flex;

                    @media screen and (max-width: 1300px) { position: relative; margin-top: 30px; bottom: 0; }
                    @media screen and (max-width: 768px) { height: auto; justify-content: center; }

                    li {
                        position: relative;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        @media screen and (max-width: 768px) { flex-direction: column; }

                        &:first-child {
                            margin-right: 85px;

                            @media screen and (max-width: 1400px) { margin-right: 50px; }
                        }

                        svg {
                            width: 60px;
                            height: 60px;
                            fill: #fff;
                            margin-right: 20px;

                            @media screen and (max-width: 768px) {
                                margin-right: 0;
                            }
                        }

                        p {
                            display: inline-block;
                            font: 12px/1.5 $font1b;
                            color: $color4;
                            text-transform: uppercase;
                            letter-spacing: 1px;

                            @media screen and (max-width: 768px) {
                                margin: 30px 0;
                            }

                            &.dimension span span:not(.old) { color: #fff; }

                            & > span {
                                display: block;
                                margin-top: 5px;
                                font: 22px/1 $font2b;

                                & > .old {
                                    padding-right: 10px;
                                    color: $color4;
                                    text-decoration: line-through;

                                    @media screen and (max-width: 768px) { display: none; }
                                }

                                & > span:not(.old) {
                                    color: $color2;

                                    @media screen and (max-width: 768px) { color: #fff; }

                                    span { font-size: 15px; text-transform: lowercase; }
                                }
                            }
                        }
                    }
                }
            }
        }

        .tags {
            p {
                position: relative;
                display: inline-block;
                margin: 20px 50px 0 0;
                font: rem(15)/1 $font1l;
                color: #fff;

                @media screen and (max-width: 768px) {
                    float: left;
                    width: 25%;
                    padding-top: 40px;
                    margin: 30px 0 0 0;
                    text-align: center;
                }
                @media screen and (max-width: 600px) { width: 33.33%; margin-right: 0; }
                @media screen and (max-width: 400px) { width: 50%; }

                svg {
                    margin-right: 5px;
                    width: 30px;
                    height: 30px;
                    vertical-align: middle;
                    fill: #fff;

                    @media screen and (max-width: 768px) {
                        position: absolute;
                        margin-right: 0;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    &.big {
                        width: 33px;
                        height: 33px;
                    }
                }
            }
        }
    }

    .picturesBlock {
        background: $color1;

        &:before { background: #fff; }
        .pagination span { color: #fff; }

        .btn2 {
            .title {
                color: #000;
                transition: color 300ms ease-in-out;
            }

            .title:hover {
                color: #fff;
                transition: color 300ms ease-in-out;
            }
        }
    }

    .textBlock {
        text-align: center;

        p {
            margin-top: 75px;
            padding: 0 30px;

            @media screen and (max-width: 1200px) { margin-top: 50px; }
            @media screen and (max-width: 1024px) { padding: 0; margin-top: 30px; }
            @media screen and (max-width: 768px) { margin-top: 20px; }
        }
    }

    .convenienceBlock {
        position: relative;
        margin-top: 80px;
        padding-right: 80px;
        padding-left: 80px;

        @media screen and (max-width: 1024px) { padding-right: 0; padding-left: 0; }

        .textCenter {
            margin-top: 50px;
        }

        .row {
            .col12 {
                height: 900px;

                @media screen and (max-width: 1400px) { height: 800px; }
                @media screen and (max-width: 1200px) { height: 700px; }
                @media screen and (max-width: 1024px) { height: 600px; }
                @media screen and (max-width: 768px)  { height: 500px; }
            }
        }

        .convenience {
            position: absolute;
            width: 450px;
            top: -80px;
            left: calc(50% - 225px);
            text-align: center;

            @media screen and (max-width: 1024px) { width: 400px; left: calc(50% - 200px); }
            @media screen and (max-width: 768px)  { width: 350px; left: calc(50% - 175px); }
            @media screen and (max-width: 500px)  { width: 300px; left: calc(50% - 150px); }
            @media screen and (max-width: 400px)  { width: 280px; left: calc(50% - 140px); }

            .wrapper1 {
                background: $color1;
                padding: 85px 20px;

                @media screen and (max-width: 1024px) { padding: 50px 20px; }
                @media screen and (max-width: 768px)  { padding: 30px 20px; }

                h5 {
                    margin-bottom: 50px;

                    @media screen and (max-width: 1024px) { margin-bottom: 30px; }
                    @media screen and (max-width: 768px)  { margin-bottom: 20px; }
                }

                ul {
                    li {
                        font: 15px/1.3 $font1;
                        color: #fff;

                        &:not(:last-child) {
                            margin-bottom: 20px;

                            @media screen and (max-width: 768px) { margin-bottom: 15px; }
                        }

                        &.note {
                            p { line-height: 1.5;}
                            &:not(:last-child) { margin-bottom: 0; }
                        }
                    }
                }
            }

            .wrapper2 {
                a {
                    padding: 40px 20px;
                    width: 100%;
                    background: #fff;
                    font: 22px/1 $font2b;
                    color: $color1;
                    transition: color 300ms;

                    @media screen and (max-width: 1024px) { padding: 30px 20px;}
                    @media screen and (max-width: 768px) { padding: 25px 20px; }

                    &:hover {
                        color: $color4;
                    }
                }
            }
        }
    }
}
