// UN CHAMP DE TEXTE __________
.input-field {
    input {
        width: 100%;
        height: 45px;
        padding: 0px 0;
        border: none;
        border-bottom: 1px solid $color1;
        font: 18px/45px $font1;
        color: #000;
        background: transparent;
        transition: border 300ms ease;

        &:focus { border-bottom-color: $color1; }
        &.valid { border-bottom: 1px solid $color1; }
    }

    label {
        position: absolute;
        top: 0px;
        left: 0;
        font: 18px/45px $font1;
        color: $color1;
        cursor: text;
        transition: 300ms $ease;

        span {
            font-size: 15px;
            color: $color2;
            transition: color 300ms, font-size 300ms;
        }
    }

    input:focus + label,
    input.valid + label,
    input.error + label,
    input.notEmpty + label {
        top: -12px;
        font-size: 11px;
        line-height: 12px;
        cursor: default;
        pointer-events: none;

        span {
            font-size: 11px;
            color: $color5;
        }
    }

    input.vide + label {
        top: 0px;
        font: 16px/45px $font1;
    }

    &.white {
        input {
            color: #fff;
            border-bottom-color: $color5;

            &:focus { border-bottom-color: $color2; }
            &.valid { border-bottom-color: $color2; }
            &.error { border-color: #eb3f3f; }
        }

        label { color: #fff; }

        input:focus + label,
        input.valid + label,
        input.error + label,
        input.notEmpty + label {
            color: $color5;
            border-bottom-color: $color2;
        }
    }
}
