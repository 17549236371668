#page-inns {

    #scrollUp {
        @media screen and (max-width: 1024px) {
            bottom: 147px;

            &.footerMobile {
                bottom: 45px;
            }
        }
    }

    .headerBlock {
        .myBreadcrumb {
            .btn {
                @media screen and (max-width: 768px) { display: none; }
            }
        }

        .content .extra .btn {
            margin-top: 30px;
        }
    }

    .chambersBlock {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            height: 100px;
            top: 0;
            right: 0;
            left: 0;
            background: $color1;
        }

        .wrapper1300 {
            @media screen and (max-width: 1300px) { margin: 0 auto; }
            @media screen and (max-width: 1200px) { max-width: 850px; }
            @media screen and (max-width: 700px)  { max-width: 400px; }
            @media screen and (max-width: 400px)  { margin: 0; }
        }

        .col8 {
            @include setMarginTop(40px, 3);

            @media screen and (max-width: 1200px) { @include setMarginTop(30px, 2); }
            @media screen and (max-width: 700px)  { @include setMarginTop(30px, 1); }

            .blockStyle2 .text {
                @media screen and (max-width: 1400px) { padding: 40px 20px; }

                .title {
                    @media screen and (max-width: 1300px) { font-size: 20px; }
                }
            }
        }
    }
}
