:root {
    --alert-desktop-width: 100%;
    --alert-mobile-height: 45px;
}

.showOverlayAlert {
    .alerts--desktop {
        display: block;
    }
}

.alerts {
    // Desktop et mobile
    &__close {
        position: absolute;
        width: 20px;
        height: 20px;
        transition: opacity 300ms;
    }

    &__close:before, &__close:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 14px;
        top: 50%;
        left: 50%;
        background: #fff;
    }

    &__close:before { transform: translate(-50%, -50%) rotate(45deg);  }
    &__close:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    &__close:hover  { opacity: 0.6; }


    // Desktop
    &--desktop {
        display: none;
        position: relative;
        width: var(--alert-desktop-width);
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: none;
        z-index: 1;
        background: #eb3f3f;
    }

    &--desktop::-webkit-scrollbar {
        display: none;
    }

    &--desktop &__container {
        position: relative;
        overflow: hidden;
        margin: 0px 60px 0px 80px;
    }

    &--desktop &__close {
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    &--desktop &__alert {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45px;
    }

    &--desktop &__common-container {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        color: #fff;
    }

    &--desktop &__common-text {
        font: rem(16)/1 $font1;
        color: #fff;
    }

    &--desktop &__title {
        font: rem(16)/1 $font1;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &--desktop &__link &__common-container,
    &--desktop &__link &__subtitle,
    &--desktop &__link &__title {
        opacity: 1;
        transition: opacity 300ms;
    }

    &--desktop &__link:hover &__common-container,
    &--desktop &__link:hover &__subtitle,
    &--desktop &__link:hover &__title {
        opacity: 0.6;
    }
}
