.blockStyle2 {
    width: 100%;
    max-width: 400px;

    .image {
        position: relative;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media screen and (max-width: 400px) { height: 200px; }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($color1, 0);
            transition: background 300ms;
        }

        p {
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 50%;
            font: 12px/1.5 $font1b;
            color: #fff;
            opacity: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            transform: translate(-50%, -50%);
            transition: opacity 300ms;

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                top: 200%;
                background: #fff;
                transition: top 300ms $ease;
            }
        }
    }

    .text {
        padding: 40px;
        background: $color1;

        @media screen and (max-width: 500px) { padding: 20px; }

        .title {
            margin-bottom: 10px;
            font: 22px/1.3 $font2b;
            color: #fff;

            @media screen and (max-width: 400px) { font-size: 18px; }
            span:first-child {
                padding-right: 10px;
            }
            span.pied {
                font: 15px/1.3 $font1;
                color: $color4;

                @media screen and (max-width: 400px) { font-size: 13px; }
            }
        }

        .tags {
            p {
                display: inline-block;
                font: 15px/1 $font1;
                color: #fff;
                margin-right: 20px;

                @media screen and (max-width: 400px) { font-size: 13px;  margin-right: 15px; }

                svg {
                    width: 22px;
                    height: 22px;
                    vertical-align: middle;
                    fill: #fff;
                }
            }
        }

        .price {
            padding-top: 20px;
            border-top: 1px solid $color2;
            margin-top: 20px;
            font: 12px/1.5 $font1b;
            color: $color4;
            text-transform: uppercase;
            letter-spacing: 1px;

            & > span {
                display: block;
                margin-top: 5px;
                font: 22px/1 $font2b;

                & > .old {
                    padding-right: 10px;
                    color: $color4;
                    text-decoration: line-through;
                }

                & > span:not(.old) {
                    color: $color2;

                    span { font-size: 15px; text-transform: lowercase; }
                }
            }
        }
    }

    &:hover {
        .image {

            &:before { background: rgba($color1, 0.6); }

            p {
                opacity: 1;
                transition: opacity 300ms linear 100ms;

                &:before {
                    top: 100%;
                    transition: top 300ms $ease 100ms;
                }
            }
        }
    }
}
