.control-multi-file-uploader{
    margin-bottom: 45px;
    position: relative;
    border: 2px dashed $color4;
    padding: 20px;
    border-radius: 5px;

    &:hover {

        color: $color4;

        .dz-clickable{
            background-color: rgba($color4,0.8);
        }
        .placeholder {
            //color: $color4;
        }
    }

    .dz-clickable{
        height: 100px;
        background-color: rgba($color4,0.3);
        cursor: pointer;
        border-radius: 5px;
        transition: color 300ms, background 300ms;
    }

    .content{
        .placeholder{
            position: absolute;
            left: 0;
            right: 0;
            height: 100px;
            line-height: 100px;
            text-align: center;
            top: 20px;
            pointer-events: none;
            transition: color 300ms, background 300ms;
            .upload-full-text { display: block }
            .upload-truncated-text { display: none }

            @media screen and (max-width: 560px) {
                .upload-full-text { display: none }
                .upload-truncated-text { display: block }
            }

        }

        .dz-preview{
            position: relative;
            padding-left: 120px;
            padding-right: 35px;
            min-height: 100px;
            display: flex;
            align-items: center;
            margin-top: 20px;
            background-color: rgba($color4,0.3);
            font-family: $font1;

            .thumbnail{
                position: absolute;
                left: 0;
                top: 0;
                height: 100px;
                width: 100px;
            }

            .dz-details{
                font-size: 1em;
                line-height: 1.5em;
                color: $color1;
                flex-wrap: wrap;
                .dz-filename,
                .dz-size{
                    width: 100%;
                    margin: 6px 0;
                }
            }

            .action-panel{
                position: absolute;
                background: $color2;
                color: $color1;
                border-radius: 50%;
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                z-index: 4;
                transition: color 300ms, background 300ms;
                &:hover {
                    color: $color1;
                    background: $color3;
                }
                a{
                    position: absolute;
                    color: $color1;
                    top: calc(50% - 1px);
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }

            .dz-error-mark{ display: none; }
            .dz-error-message{
                position: absolute;
                bottom: 5px;
                color: $color3;
            }

            .action-panel{
                position: absolute;
            }
        }
    }
}
