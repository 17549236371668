.picturesBlock {
    position: relative;
    background: linear-gradient(180deg, $color3 0%, $color3 65%, rgba(255,255,255,1) 65%, rgba(255,255,255,1) 100%);

    &.style1 {
        background: linear-gradient(180deg, $color1 0%, $color1 65%, rgba(255,255,255,1) 65%, rgba(255,255,255,1) 100%);
    }

    .credits {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px 20px;
        background-color: rgba($color: $color1, $alpha: 0.7);
        font-size: rem(12);
        line-height: 1;
        color: #fff;
    }

    .wrapper {
        position: relative;

        .title {
            position: absolute;
            height: 100px;
            padding: 40px 50px;
            right: 0;
            bottom: 90px;
            background: $color2;
            text-align: center;
            font: rem(22)/1 $font2b;
            color: $color1;
            z-index: 1;

            @media screen and (max-width: 768px) { display: none; }
        }
        .image {
            height: 500px;

            @media screen and (max-width: 1024px) { height: 450px; }
            @media screen and (max-width: 768px)  { height: 375px; }
            @media screen and (max-width: 600px)  { height: 325px; }
        }
    }
}


.picturesBlock2 {
    .content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        padding: 80px 60px;
        min-height: 320px;

        @media screen and (max-width: 600px) { padding: 60px 40px; }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: rgba($color1, 0.5);
        }

        h2 {
            color: #fff;
            z-index: 1;
        }

        .btn {
            margin-top: 30px;
            z-index: 1;
        }
    }
}



.picturesBlockSlick {
    position: relative;
    background: linear-gradient(180deg, $color1 0%, $color1 65%, rgba(255,255,255,1) 65%, rgba(255,255,255,1) 100%);
    padding: 0 80px;

    &.style1 {
        background: linear-gradient(180deg, $color1 0%, $color1 65%, rgba(255,255,255,1) 65%, rgba(255,255,255,1) 100%);
    }

    &.style2 {
        background: linear-gradient(180deg, $color3 0%, $color3 65%, $color1 65%, $color1 100%);

        .pagination span {
            color: $color1;

            &::before {
                background: $color1;
            }
        }
    }

    @media screen and (max-width: 1024px) { padding: 0 0 0 80px; }
    @media screen and (max-width: 768px) { padding: 0 0 0 50px; }
    @media screen and (max-width: 600px) { padding: 0; }

    .pagination {
        position: absolute;
        bottom: 290px;
        left: 0;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);

        @media screen and (max-width: 768px) { left: -11px; }
        @media screen and (max-width: 600px) { opacity: 0; visibility: hidden; }
    }

    .wrapper {
        position: relative;

        .slickNav {
            position: absolute;
            width: 60px;
            height: 35%;
            bottom: 0;
            left: 0;
            background: #fff;
            font-size: 0;
            z-index: 1;

            @media screen and (max-width: 768px) { width: 50px; }
            @media screen and (max-width: 600px) { height: 50px; }

            a {
                position: relative;
                width: 60px;
                background: $color3;
                height: 50%;

                @media screen and (max-width: 768px) { width: 50px; }
                @media screen and (max-width: 600px) { height: 50px; }

                svg {
                    position: absolute;
                    width: 8px;
                    height: 14px;
                    margin-left: 0;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    transition: margin 175ms;
                }

                &:first-child { border-bottom: 1px solid #fff; }
                &:last-child {
                    border-top: 1px solid #fff;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                }

                &:hover svg {
                    margin-left: 5px;
                }
            }
        }

        .btn {
            position: absolute;
            top: 33%;
            right: -80px;
            background-color: $color2;
            font: rem(22)/1.3 $font2b;
            color: $color1;
            text-transform: initial;
            border: none;
            transform: translateY(-50%);
            z-index: 1;

            &:hover {
                color: #fff;
            }

            @media screen and (max-width: 1024px)  { right: 0; }
            @media screen and (max-width: 768px)  { display: none; }
        }

        .slick {
            .slick-slide { font-size: 0; }
            .image {
                height: 600px;

                @media screen and (max-width: 1024px) { height: 500px; }
                @media screen and (max-width: 768px) { height: 400px; }
                @media screen and (max-width: 600px) { height: 350px; }
            }
        }
    }
}
