/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
@media screen and (max-width: 700px) {

    .showOverlayMenu {
        .header-mobile { box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15); }

        .btn-menu {
            &__line1 { width: 0%; top: 16px; left: 50%; opacity: 0; }
            &__line2 { transform: rotate(45deg); width: 100%; }
            &__line3 { transform: rotate(-45deg); width: 100%; }
            &__line4 { width: 0%; top: 16px; left: 50%; opacity: 0; }
        }

        .overlay-menu {
            visibility: visible;
            pointer-events: all;

            &__background { background: rgba(#000, 1); }
            &__container {
                top: 0%;
            }
            &__main-level {
                opacity: 1;
                transform: none;
            }
        }
    }


    /*
    |--------------------------------------------------------------------------
    | OVERLAY SE FERME
    |--------------------------------------------------------------------------
    */
    .showOverlayMenu.closing-overlay-menu {
        .btn-menu {
            &__line1 { width: 100%; top: 0px; left: 0%; opacity: 1; }
            &__line2, &__line3 { top: calc(50% - 1px); transform: none; }
            &__line4 { width: 100%; bottom: 0px; left: 0%; opacity: 1; }
        }

        .overlay-menu {
            &__background { background: rgba(#000, 0); }
            &__container {
                top: -100%;

            }
            &__main-level {
                opacity: 0;
                transform: translateY(-200px);
            }
        }
    }


    /*
    |--------------------------------------------------------------------------
    | SOUS-MENU OUVERT
    |--------------------------------------------------------------------------
    */
    .overlay-menu .wrapper .linksWrapper {
        .js-accordion-opened .overlay-menu__second-level-button { color: $color2 !important; }
        .js-accordion-opened .overlay-menu__second-level-svg { transform: rotate(-90deg); }
        .js-accordion-opened .overlay-menu__third-level { opacity: 1 !important; }
    }
}
