.alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f04848;
    padding: 0 20px;

    @media screen and (max-width: 1024px) { padding-right: 20px; }

    p, a {
        max-width: 100%;
        height: 45px;
        font-size: rem(16);
        font-family: $font1;
        line-height: 45px;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        strong {
            font-family: $font1b;
        }
    }

    .action {
        padding-right: 20px;

        .close {
            display: flex;
            align-items: center;
            position: relative;
            width: 20px;
            height: 20px;
            cursor: pointer;

            @media screen and (max-width: 425px) { width: 25px; }

            span {
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                background: #fff;
                opacity: 1;
                left: 0%;
                transform: rotate(0deg);
                transition: 250ms $ease;

                &:nth-child(1) { width: 0%; top: 15px; left: 50%; opacity: 0; }
                &:nth-child(2) { transform: rotate(45deg); width: 100%; }
                &:nth-child(3) { transform: rotate(-45deg); width: 100%; }
                &:nth-child(4) { width: 0%; top: 15px; left: 50%; opacity: 0; }
            }
        }
    }
}
