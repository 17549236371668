/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-fast-links {
    .banner {
        &__btn-fast-link-show { opacity: 0; }
        &__btn-fast-link-hide { opacity: 1; }
        &__btn-fast-link-plus {
            &:before { transform: translate(-50%, -50%) rotate(-45deg); }
            &:after  { transform: translate(-50%, -50%) rotate(-135deg); }
        }
    }
    

    .overlay-fast-links {
        @media screen and (max-width: 768px) { width: 275px; }
        @media screen and (max-width: 500px) { width: 100%; }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-fast-links.closing-overlay-fast-links {
    .banner {
        &__btn-fast-link-show { opacity: 1; }
        &__btn-fast-link-hide { opacity: 0; }
        &__btn-fast-link-plus {
            &:before { transform: translate(-50%, -50%) rotate(90deg); }
            &:after  { transform: translate(-50%, -50%); }
        }
    }
    .overlay-fast-links {
        width: 275px;
        
        @media screen and (max-width: 1100px), screen and (max-height: 600px) { width: var(--overlay-fast-links-width); }
        @media screen and (max-width: 768px)  { width: 0%; }
    }
}
.show-overlay-fast-links:not(.closing-overlay-fast-links):not(.opening-overlay-fast-links) .overlay-fast-links {
    overflow-x: auto;

    @media screen and (max-width: 768px) { overflow-x: hidden; overflow-y: auto; }
}