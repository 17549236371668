:root {
    --dynamic-ol-inside-left-padding: 35px;
    --dynamic-ul-inside-left-padding: 22px;
    --dynamic-ol-li-padding-top: 20px;
    --dynamic-ul-li-padding-top: 20px;

    @media screen and (max-width: 1024px) {
        --dynamic-ol-li-padding-top: 20px;
    }
    @media screen and (max-width: 768px) {
        --dynamic-ol-inside-left-padding: 25px;
        --dynamic-ul-inside-left-padding: 15px;
        --dynamic-ol-li-padding-top: 15px;
        --dynamic-ul-li-padding-top: 15px;
    }
    @media screen and (max-width: 500px) {
        --dynamic-ol-inside-left-padding: 10px;
        --dynamic-ul-inside-left-padding: 10px;
    }
}

.dynamic {
    > :first-child { margin-top: 0 !important; }
    > :last-child { margin-bottom: 0 !important; }

    h2, .h2 {
        @include large-title();
        margin: 70px 0 30px;

        @media screen and (max-width: 1024px) { margin: 50px 0 25px; }
        @media screen and (max-width: 768px)  { margin: 40px 0 20px; }
        @media screen and (max-width: 500px)  { margin: 30px 0 15px; }
    }

    h3, .h3 {
        @include medium-title();
        margin: 40px 0 20px;

        @media screen and (max-width: 1024px) { margin: 30px 0 20px; }
        @media screen and (max-width: 768px)  { margin: 25px 0 15px; }
    }

    h4, .h4 {
        @include small-title();
        font-family: $font1b;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 40px 0 15px;

        @media screen and (max-width: 1024px) { margin: 35px 0 10px; }
        @media screen and (max-width: 768px)  { margin: 30px 0 10px; }
    }

    p {
        @include paragraph();
        margin: 15px 0;

        @media screen and (max-width: 768px) { margin: 10px 0; }
    }

    strong {
        font-style: normal;
        font-weight: 700;
    }

    em {
        font-style: italic;
        font-weight: normal;
    }

    em strong {
        font-style: italic;
        font-weight: 700;
    }

    pre {
        margin: 10px 0;
        font: rem(14)/1.5 $font1;
        color: $color5;
        white-space: normal;
    }

    blockquote {
        position: relative;
        padding: 0 0 0 50px;
        margin: 50px 0;
        font-family: $font1b;
        line-height: 1.5;
        color: $color1;
        border-left: 1px solid $color1;
        @include responsiveFontSize(22, 18);

        @media screen and (max-width: 1024px) { margin: 30px 0; padding: 0 0 0 45px; }
        @media screen and (max-width: 768px)  { margin: 20px 0; padding: 0 0 0 35px; }

        p {
            margin-bottom: 0;
            font-family: $font1;
            color: $color1;
            @include responsiveFontSize(22, 18);
        }
    }

    a:not(.link):not(.btn) {
        display: inline;
        @include paragraph();
        color: $color1;
        box-shadow: inset 0px -1px 0 $color1;
        transition: color 300ms, box-shadow 300ms;

        &:hover { box-shadow: inset 0px -1px 0 $color9; }
    }

    .btn {
        margin: 20px 0;
        color: #fff;
        background: $color1;
        border: none;

        &:hover {
            color: $color4;
        }
    }

    img { max-width: 100%; }

    /* ----- Image - No Caption ----- */

    img {

        position: relative;
        max-width: 100%;

        &.fr-dib {

            /* block */

            display: block;
            margin: 30px auto;
            float: none;
            vertical-align: top;

            &.fr-fil {
                margin-left: 0;
            }

            &.fr-fir {
                margin-right: 0;
            }
        }

        &.fr-dii {

            /* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
            margin: 10px 20px;
            max-width: calc(100% - (2 * 20px));

            &.fr-fil {
                float: left;
                margin: 10px 20px 10px 0;
                max-width: calc(100% - 20px);
            }

            &.fr-fir {
                float: right;
                margin: 10px 0 10px 20px;
                max-width: calc(100% - 20px);
            }
        }
    }

    /* ----- Image - With Caption ----- */

    .fr-img-caption {

        img {
            width: 100%;
        }

        &.fr-dib {

            /* block */

            display: block;
            margin-left: auto;
            margin-right: auto;
            float: none;
            vertical-align: middle;
            text-align: center;

            &.fr-fil {
                margin-left: 0;
                text-align: left;
            }

            &.fr-fir {
                margin-right: 0;
                text-align: right;
            }

            .fr-img-wrap {

                .fr-inner {
                    display: block;
                    line-height: 1.5;
                    white-space: normal;
                    font: rem(13)/1.5 $font1;
                    color: $color1;
                    white-space: normal;
                }
            }
        }

        &.fr-dii {

            /* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
            margin: 10px 20px;
            max-width: calc(100% - (2 * 20px));
            text-align: center;

            &.fr-fil {
                float: left;
                margin-left: 0;
                text-align: left;
                max-width: calc(100% - 20px);
            }

            &.fr-fir {
                float: right;
                margin-right: 0;
                text-align: right;
                max-width: calc(100% - 20px);
            }
        }
    }

    video {
        max-width: 560px;
        max-height: 315px;
        width: 100%;
    }

    ol {
        list-style: none;
        counter-reset: li;

        > li {
            position: relative;
            padding-top: var(--dynamic-ol-li-padding-top);

            &:last-child { margin-bottom: 0; }

            &:before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                font: 500 rem(15)/1.5 $font1;
                color: $color1;

                @media screen and (max-width: 768px) { font-size: rem(16); }
                @media screen and (max-width: 500px) { font-size: rem(15); }
            }

            ol {
                width: 100%;
                counter-reset: li;
                padding-left: var(--dynamic-ol-inside-left-padding);
            }

            ul {
                width: 100%;
                counter-reset: li;
                padding-left: var(--dynamic-ol-inside-left-padding);
            }
        }
    }

    ul {
        > li {
            position: relative;
            padding-top: var(--dynamic-ul-li-padding-top);

            &:before {
                content: '';
                width: 4px;
                height: 4px;
                background: $color1;
                border: 1px solid $color1;
                border-radius: 50%;
                transform: translateY(-3px);
            }

            &:last-child{ padding-bottom: 0; }

            ul {
                width: 100%;
                padding-left: var(--dynamic-ul-inside-left-padding);

                li {
                    &::before { background: transparent; }

                    ul li:before { background: $color1; }
                }
            }

            ol {
                width: 100%;
                counter-reset: li;
                padding-left: var(--dynamic-ul-inside-left-padding);
            }
        }
    }

    > ol, > ul, table tbody td > ul, table tbody td > ol {
        margin-bottom: 30px;

        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 768px)  { margin-bottom: 20px; }

        > li:first-child { padding-top: 0; }

        li {
            display: flex;
            flex-flow: row wrap;
            align-items: baseline;
            @include paragraph();

            &:before {
                margin-right: 15px;

                @media screen and (max-width: 768px) { margin-right: 10px; }
            }

            p { flex: 1; margin: 0; }
            b, strong { font-weight: 700; }
        }
    }

    > ol > li {
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            top: calc(100% + (var(--dynamic-ol-li-padding-top) / 2));
            left: 0;
            background: rgba($color: $color9, $alpha: 0.5);
        }
    }

    .tableWrapper {
        width: 100%;
        overflow-x: auto;
        margin: 30px 0 50px;

        @media screen and (max-width: 1024px) { margin: 25px 0 40px; }
        @media screen and (max-width: 768px)  { margin: 20px 0 30px; }
        @media screen and (max-width: 500px)  { margin: 15px 0 25px; }
    }

    table {
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        table-layout: fixed;

        & > :first-child > tr > td:not(:only-child) { border-top: 1px solid $color4; }

        thead {
            border-top: none!important;
            tr th, tr td {
                border: 1px solid rgba(#fff, 0.3);
                border-bottom: none;
                padding: 19px 30px;
                color: #fff;
                background: $color1;
                white-space: normal;
                text-align: left;

                @media screen and (max-width: 768px) { font-size: rem(16) }

                &, p, h4, h3, h2 {
                    font: 700 rem(15)/1.5 $font1;

                    @media screen and (max-width: 768px) { font-size: rem(16) }
                }
            }
        }

        tbody {
            tr{
                td,th{
                    &.fonce, &.pale {
                        background: $color3;
                    }
                }
            }
            tr td:first-child, tr th:first-child { border-left: 1px solid $color4; }
            tr:last-child td, tr:last-child th { border-bottom: 1px solid $color4; }
            tr td, tr th {
                padding: 19px 30px;
                margin: 0;
                color: $color1;
                white-space: normal;
                border-right: 1px solid $color4;
                border-bottom: 1px solid $color4;
                vertical-align: middle !important;

                &:not(:only-child) {
                    font: rem(15)/1.5 $font1;
                    font-weight: normal;
                    @media screen and (max-width: 768px) { font-size: rem(16) }
                }

                > :first-child { margin-top: 0 !important; }
                > p, > pre, > strong, > em, > a, > span, > sub, > sup, > ul, > ol {
                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }

                p {
                    color: $color1;
                }

                &:not(:only-child) p {
                    font: rem(15)/1.5 $font1;

                    @media screen and (max-width: 768px) { font-size: rem(16); }
                }
            }

            tr:only-child td:only-child {
                padding: 40px;
                border: 1px solid $color4;
                background: transparent;

                @media screen and (max-width: 1024px) { padding: 30px; }
                @media screen and (max-width: 768px)  { padding: 25px; }

                &.fonce {
                    background-color: $color1;
                    border: none !important;

                    a, h5, h4, h3, h2 {
                        color: #fff;
                    }
                    &, pre, p {
                        color: $color4;
                    }
                    a:not(.link):not(.btn) {
                        color: #fff;
                        box-shadow: inset 0px -1px 0 #fff;

                        &:hover { box-shadow: inset 0px -1px 0 rgba(#fff, 0.2); }
                    }
                    a.btn {
                        color: $color1;
                        background-color: $color3;

                        &:hover { color: $color4; }
                    }
                    ul > li:before {
                        background: #fff;
                        border: 1px solid #fff;
                    }
                    ul > li ul li:before {
                        background: transparent;
                    }
                    ul > li ul li ul li:before {
                        background: #fff;
                        border: 1px solid #fff;
                    }
                    ol > li:before {
                        color: #fff;
                    }
                }

                &.pale {
                    background-color: $color3;
                    border: none !important;

                    &, pre, p {
                        color: $color5;
                    }
                }
            }
        }
    }
}

.pageComponents.zoneA > * {
    padding-bottom: 100px;
}
.pageComponents.zoneB > * {
    &:not(.newsletterBlock) {
        padding-bottom: 100px;
    }
    &.newsletterBlock:not(:last-child) {
        padding-bottom: 100px;
    }
}
