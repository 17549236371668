a {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
}

// Le lien
.link {
    position: relative;
    font: 18px/1.5 $font1;
    color: $color1;

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        width: 100%;
        height: 1px;
        background: $color1;
        transition: background 300ms;
    }
    &:hover:before { background: $color4; }

    &.white {
        color: #fff;

        &:before { background: #fff; }
        &:hover:before { background: $color5; }
    }
}
