.gallery {
    --gallery-height: 370px;
    --gallery-margin: 30px;

    @media screen and (max-width: 768px) { --gallery-height: 330px; --gallery-margin: 20px; }
    @media screen and (max-width: 500px) { --gallery-height: 200px; --gallery-margin: 10px; }
}

.gallery {
    &__container {
        display: flex;
        flex-flow: row nowrap;
        height: var(--gallery-height);
    }

    &__left {
        flex: 1;
        margin-right: var(--gallery-margin);
    }

    &__right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        width: calc((var(--gallery-height) / 2) - (var(--gallery-margin) / 2));
    }

    &__block {
        position: relative;
        height: 100%;
    }

    &__block:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color1;
        opacity: 0;
        transition: opacity 300ms;
        pointer-events: none;
    }

    &__link {
        display: block;
        height: 100%;
    }

    
    &__block {
        display: none;
    }

    &__block-1,
    &__block-2,
    &__block-3 {
        display: block;
    }

    &__block-1 .gallery__link,
    &__block-2 .gallery__link,
    &__block-3 .gallery__link {
        display: block;
        height: 100%;
    }

    &__block-2,
    &__block-3 {
        height: calc((var(--gallery-height) / 2) - (var(--gallery-margin) /2));
    }

    &__block-3:before {
        opacity: 0.8;
    }

    &__block:hover:before {
        opacity: 0.5;
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__svg {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 50%;
        stroke: #fff;
        fill: none;
        stroke-width: 5px;
        z-index: 1;
        pointer-events: none;
        transform: translate(-50%, -50%);
    }

    &__note {
        margin-top: 20px;
        font: rem(14)/1.5 $font1;
        color: $color5;
        white-space: normal;
    }
}
