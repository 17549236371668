/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
@mixin section($section) {
    .showOverlayMenu[data-section="#{$section}"] {

        #overlay-menu .js-trigger-overlay-dropdown[data-section="#{$section}"] {
            color: $color2;
        }

        .overlay-dropdown [data-section="#{$section}"] {
            opacity: 1;
            visibility: visible;
            transition: opacity 800ms $easeInOutQuart;

            .overlay-dropdown__sub-section-container {
                transform: none;
                transition: transform 800ms $easeInOutQuart;
            }
        }
    }
}

@include section(le-sacre-bistro);
@include section(inns);
@include section(shop);
@include section(weddings);
@include section(corporate);
@include section(outdoors);
@include section(agrotourism);
