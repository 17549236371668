.radiobox-field {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 0;

    &__options {
        display: flex;
        align-items: center;
        min-width: 0;
    }

    label {
        position: relative;
        display: flex;
        width: fit-content;
        min-width: 0;
        padding-bottom: 0;
        font: 18px/45px $font1;
        color: $color1;
        transition: 300ms $ease;
    }

    label:not(.radiobox-field__option) {
        margin-right: 70px;
        @media screen and (max-width: 900px) { margin-right: 40px; }
    }

    &.white {
        label { color: #fff; }
    }

    &__option {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 35px;
        cursor: pointer;

        span {
            position: absolute;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: transparent;
            border: 1px solid $color1;
            border-radius: 50%;

            &::before {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                background-color: $color1;
                border-radius: 50%;
                transition: transform 225ms;
            }
        }

        input:checked ~ span {
            &::before {
                transform: translate(-50%, -50%) scale(1);
            }
        }

        input:disabled ~ span {
            background-color: transparent;
        }
    }

    &.white &__option {
        span {
            border: 1px solid #fff;

            &::before {
                background-color: white;
            }
        }
    }

    &__option + &__option {
        margin-left: 35px;
        @media screen and (max-width: 900px) { margin-left: 25px; }
    }
}
