.event {
    margin-bottom: 60px;

    @media screen and (max-width: 1200px) { margin-bottom: 55px; }
    @media screen and (max-width: 1024px) { margin-bottom: 50px; }
    @media screen and (max-width: 768px)  { margin-bottom: 40px; }
    @media screen and (max-width: 500px)  { margin-bottom: 30px; }

    &.primary .image {
        height: 500px;
        padding-top: 0;

        @media screen and (max-width: 1024px) { height: 450px; }
        @media screen and (max-width: 768px)  { height: 375px; }
        @media screen and (max-width: 600px)  { height: 325px; }
    }

    .image {
        position: relative;
        padding-top: ratio(920, 425);

        @media screen and (max-width: 768px) { height: 375px; padding-top: 0; }
        @media screen and (max-width: 600px) { height: 325px; }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($color1, 0);
            transition: background 300ms;
        }

        p {
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 50%;
            font: rem(12)/1.5 $font1b;
            color: #fff;
            opacity: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            transform: translate(-50%, -50%);
            transition: opacity 300ms;

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                top: 200%;
                background: #fff;
                transition: top 300ms $ease;
            }
        }
    }

    .text {
        margin-top: 30px;

        h2 {
            font-size: rem(28);

            @media screen and (max-width: 1024px) { font-size: rem(26); }
            @media screen and (max-width: 768px)  { font-size: rem(24); }
            @media screen and (max-width: 500px)  { font-size: rem(22); }
            @media screen and (max-width: 375px)  { font-size: rem(21); }
        }

        h5 {
            font-size: rem(12);
            color: $color5;
            margin-top: 15px;
        }
    }

    &.primary {
        width: 100% !important;

        .text h2 {
            font-size: rem(35);

            @media screen and (max-width: 1300px) { font-size: rem(33); }
            @media screen and (max-width: 1200px) { font-size: rem(30); }
            @media screen and (max-width: 1024px) { font-size: rem(28); }
            @media screen and (max-width: 768px)  { font-size: rem(24); }
            @media screen and (max-width: 500px)  { font-size: rem(22); }
            @media screen and (max-width: 375px)  { font-size: rem(21); }
        }
    }

    &:hover {
        .image {

            &:before { background: rgba($color1, 0.6); }

            p {
                opacity: 1;
                transition: opacity 300ms linear 100ms;

                &:before {
                    top: 100%;
                    transition: top 300ms $ease 100ms;
                }
            }
        }
    }
}

.eventsBlock {
    .titleSection {
        margin-bottom: 75px;

        @media screen and (max-width: 1024px) { margin-bottom: 50px; }
        @media screen and (max-width: 768px)  { margin-bottom: 30px; }
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media screen and (max-width: 650px) {
            flex-direction: column;
            flex-wrap: initial;
        }

        .event {
            &:not(.primary) {
                &:first-child:nth-last-child(1) {
                    width: 50%;
                    margin-right: 0;

                    @media screen and (max-width: 650px) {
                        width: 100%;
                    }
                }
                &:nth-child(2n) {
                    width: calc(50% - 30px);
                    margin-left: 30px;
                }
                &:nth-child(2n - 1) {
                    width: calc(50% - 30px);
                    margin-right: 30px;
                }
                &:nth-last-child(-n+1) {
                    margin-bottom: 0;
                }

                @media screen and (max-width: 650px) {
                    &:nth-child(2n) {
                        width: 100%;
                        margin-left: 0;
                    }
                    &:nth-child(2n - 1) {
                        width: 100%;
                        margin-right: 0;
                    }
                }

                @media screen and (min-width: 769px) {
                    &:nth-last-child(-n+2) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .btn {
        margin-top: 75px;

        @media screen and (max-width: 1024px) { margin-top: 50px; }
        @media screen and (max-width: 768px)  { margin-top: 30px; }
    }
}

