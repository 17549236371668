#page-jobsDetails {
    .headerBlock .row .content {
        .title h1 {
            @include fontsize(70, 0, 65, 55, 50, 45, 0);
        }

        .extra {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .subtitle {
                font-size: rem(15);
                color: $color1;
            }
        }
    }

    .formBlock {
        h3 {
            color: $color1;
            padding-bottom: 30px;
        }
    }
}
