#page-jobs {
    .headerBlock .row .content .extra {
        border-top: none;
        padding-top: 0;

        .field {
            position: relative;
            width: 33%;
            margin-bottom: 0;

            @media screen and (max-width: 768px) { width: 100%; }

            // Erreur
            &__error {
                margin-top: 5px;
                font: rem(11)/1 $font1;
                color: $color6;
                width: 150px;
            }

            // Select
            &--select &__label {
                color: #fff;
            }

            &--select .select-label {
                border: 1px solid #000;
                background: #000;
                height: 64px;
                @media screen and (max-width: 600px) { height: 45px; }

                &:hover {
                    .label-inner {
                        color: $color5;

                        &:before {
                            background: url('../images/content/icons/arrow_grey.svg') no-repeat center / contain;
                        }
                    }
                }
            }

            &--select .label-inner {
                display: flex;
                align-items: center;
                height: 100%;
                font: 15px/1.3 $font1;
                color: #fff;
                transition: color 500ms $easeInOutCubic;

                @media screen and (max-width: 768px) { font-size: 13px; }

                &:before {
                    background: url('../images/content/icons/arrow_white.svg') no-repeat center / contain;
                    transition: background 500ms $easeInOutCubic;
                }
            }

            // Select natif
            &--select select {
                width: 100%;
                padding: 0 20px;
                font: 15px/1.3 $font1;
                color: #fff;
                border: 1px solid #000;
                outline: none;
                -webkit-appearance: none;
                border-radius: 0;
                background: url('../images/content/icons/arrow_white.svg') no-repeat calc(100% - 20px) 50% / 12px 12px, #000;

                @media screen and (max-width: 768px) { font-size: 13px; }
            }
        }
    }

    .jobsIntro {
        h2 {
            margin-bottom: 20px;
        }
    }

    .jobsList {

        .title {
            padding-bottom: 30px;
        }

        .list {
            display: flex;
            flex-direction: column;

            .jobs {
                width: 100%;
                border-bottom: 1px solid $color9;
                padding-top: 30px;
                padding-bottom: 30px;
                display: flex;
                justify-content: space-between;

                &:first-child {
                    border-top: 1px solid $color9;
                }

                &:hover {
                    .text {
                        h5, h4 {
                            color: $color4;
                        }
                    }
                    svg {
                        padding-right: 0;
                        fill: $color4;
                    }
                }

                .text {
                    h5 {
                        font: rem(12)/1.5 $font1b;
                        color: $color1;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        transition: color 300ms;

                        @media screen and (max-width: 900px)  { font-size: rem(11); }
                    }

                    h4 {
                        font: rem(18)/1.5 $font1b;
                        color: $color1;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        transition: color 300ms;

                        @media screen and (max-width: 768px) { font-size: rem(16); }
                        @media screen and (max-width: 500px) { font-size: rem(15); }
                    }
                }

                svg {
                    height: 20px;
                    width: 20px;
                    align-self: center;
                    fill: $color1;
                    padding-right: 10px;
                    transition: fill 300ms, padding-right 200ms;
                }
            }
        }
    }

    .formBlock {
        h3 {
            color: $color1;
            padding-bottom: 30px;
        }
    }

    // .field {
    //     // File
    //     &--file &__input {
    //         position: absolute;
    //         width: 0.1px;
    //         height: 0.1px;
    //         opacity: 0;
    //         overflow: hidden;
    //         z-index: -1;
    //     }

    //     &--file &__label {
    //         display: block;
    //         position: relative;
    //         width: 100%;
    //         padding: 20px 60px 20px 40px;
    //         border-top: 0;
    //         border-right: 0;
    //         border-bottom: 0;
    //         border-left: 0;
    //         cursor: pointer;
    //         background-color: #ebebeb;
    //         transition: color 300ms, background 300ms;
    //     }

    //     &--file &__text {
    //         // font-family: var(--font1);
    //         // font-size: 1.4rem;
    //         // font-weight: 600;
    //         // line-height: 1.5;
    //         // text-transform: inherit;
    //         // letter-spacing: inherit;
    //         color: black;
    //         transition: color 300ms;
    //     }

    //     &--file &__svg {
    //         position: absolute;
    //         width: 18px;
    //         height: 18px;
    //         top: calc(50% - (18px / 2));
    //         right: 20px;
    //         fill: black;
    //         transition: fill 300ms, opacity 300ms;
    //     }

    //     &--file &__x {
    //         position: absolute;
    //         width: 18px;
    //         height: 18px;
    //         top: calc(50% - (18px / 2));
    //         right: 20px;
    //         opacity: 0;
    //         z-index: 1;
    //         cursor: pointer;
    //         pointer-events: none;
    //         transition: opacity 300ms, visibility 0ms ease 300ms;
    //     }

    //     &--file &__x:before, &--file &__x:after {
    //         content: '';
    //         position: absolute;
    //         width: 16px;
    //         height: 2px;
    //         top: 50%;
    //         left: 50%;
    //         background: black;
    //         transition: background 300ms;

    //         @media screen and (max-width: 600px) { width: 20px; }
    //     }

    //     &--file &__x:before {
    //         transform: translate(-50%, -50%) rotate(45deg);
    //     }

    //     &--file &__x:after {
    //         transform: translate(-50%, -50%) rotate(-45deg);
    //     }

    //     &--file[data-file]:not([data-file=""]) &__label {
    //         pointer-events: none;
    //     }

    //     &--file[data-file]:not([data-file=""]) &__svg {
    //         opacity: 0;
    //     }

    //     &--file[data-file]:not([data-file=""]) &__x {
    //         opacity: 1;
    //         visibility: visible;
    //         pointer-events: all;
    //     }

    //     &--file &__label:hover &__text {
    //         color: #8d8d8d;
    //     }

    //     &--file &__label:hover &__svg {
    //         fill: #8d8d8d;
    //     }

    //     &--file &__x:hover:before, &--file &__x:hover:after {
    //         background: #8d8d8d;
    //     }
    // }

    .input-field input[type=file] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
}
