/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {
    background: $color3;

    .rubric__transition {
        opacity: 1;
        transform: none;
    }

    .rubric__header .rubric__svg {
        transform: translate(-50%, -50%) rotate(-90deg);
    }
}


/*
|--------------------------------------------------------------------------
| SELECT DROPDOWN ACTIF
|--------------------------------------------------------------------------
*/
.field--select .tail-select.active {
    .label-inner:before { transform: translateY(-50%) rotate(-90deg); }
    .select-dropdown {
        opacity: 1;
        top: 100%;
        visibility: visible;
        pointer-events: all;
        transition: top 500ms $easeInOutCubic, opacity 500ms $easeInOutCubic;
    }
}


