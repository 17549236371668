footer {
    position: relative;
    background: $color3;
    z-index: 11;
    height: 460px;

    @media screen and (max-width: 768px) { height: 100%; }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        height: 100%;

        .title {
            margin-bottom: 25px;
            font: rem(22)/1.5 $font1b;
            color: $color1;
            text-transform: uppercase;
            letter-spacing: 3px;

            @media screen and (max-width: 768px) { margin-bottom: 15px; }
        }

        .address {
            font: rem(12)/1.5 $font1b;
            color: $color5;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        ul {
            display: flex;
            justify-content: space-between;
            margin: 70px 0;

            @media screen and (max-width: 1200px) { margin: 60px 0; }
            @media screen and (max-width: 1024px) { margin: 50px 0; }
            @media screen and (max-width: 900px)  { margin: 30px 0; flex-direction: column; }
            @media screen and (max-width: 768px)  { display: none; }

            li {
                @media screen and (max-width: 768px) {
                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }

                a {
                    font: rem(12)/1.5 $font1b;
                    color: $color1;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    transition: color 300ms;

                    @media screen and (max-width: 900px)  { font-size: rem(11); }

                    &:hover {
                        color: $color4;
                    }
                }
            }
        }

        .copyright {
            @media screen and (max-width: 768px) { margin-top: 40px; }

            a, p {
                color: $color4;
            }

            p {
                font: rem(12)/1.5 $font1b;
                margin-bottom: 15px;

                @media screen and (max-width: 1024px) { margin-bottom: 5px; }
            }

            a {
                font: rem(12)/1.5 $font1b;
                transition: color 300ms;

                &:hover {
                    color: $color1;
                }
            }
        }
    }
}
