//------------\\
//--- Helper ---\\
//----------------\\

////////////////////////////////////////////////////////////////////////////////////
// DISPOSITION
////////////////////////////////////////////////////////////////////////////////////
// Display ----------
.block        { display: block;        }
.inline-block { display: inline-block; }
.table        { display: table;        }
.tableCell    { display: table-cell;   }
.flex         { display: -webkit-box; display: -ms-flexbox; display: flex; position: absolute; width: 100%; top: 0; bottom: 0; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; justify-content: center; }

// Position ----------
.absolute { position: absolute; }
.relative { position: relative; }
.fixed    { position: fixed;    }
.sticky   { position: sticky; top: 50px; }

// Float ----------
.floatLeft  { float: left;  }
.floatRight { float: right; }



////////////////////////////////////////////////////////////////////////////////////
// TEXTE
////////////////////////////////////////////////////////////////////////////////////
// Text-align ----------
.textLeft   { text-align: left;   }
.textCenter { text-align: center; }
.textRight  { text-align: right;  }

// Vertical-align ----------
.alignTop    { vertical-align: top; }
.alignMiddle { vertical-align: middle; }
.alignBottom { vertical-align: bottom; }

// Text-transform ----------
.caps   { text-transform: capitalize; }
.allCaps   { text-transform: uppercase; }



////////////////////////////////////////////////////////////////////////////////////
// LES WRAPPERS
////////////////////////////////////////////////////////////////////////////////////
.wrapper1600 { max-width: 1600px; margin: 0 auto; @media screen and (max-width: 1600px) { margin: 0; }}
.wrapper1500 { max-width: 1500px; margin: 0 auto; @media screen and (max-width: 1500px) { margin: 0; }}
.wrapper1400 { max-width: 1400px; margin: 0 auto; @media screen and (max-width: 1400px) { margin: 0; }}
.wrapper1300 { max-width: 1300px; margin: 0 auto; @media screen and (max-width: 1300px) { margin: 0; }}
.wrapper1200 { max-width: 1200px; margin: 0 auto; @media screen and (max-width: 1200px) { margin: 0; }}
.wrapper1100 { max-width: 1100px; margin: 0 auto; @media screen and (max-width: 1100px) { margin: 0; }}
.wrapper1024 { max-width: 1024px; margin: 0 auto; @media screen and (max-width: 1024px) { margin: 0; }}



////////////////////////////////////////////////////////////////////////////////////
// UN PEU DE PADDING EN HAUTEUR
////////////////////////////////////////////////////////////////////////////////////
// Padding de 250px en Y ----------
.yPadding250 { padding-top: 250px; padding-bottom: 250px;
  @media screen and (max-width: 1600px) { padding-top: 200px; padding-bottom: 200px; }
  @media screen and (max-width: 1400px) { padding-top: 150px; padding-bottom: 150px; }
  @media screen and (max-width: 1200px) { padding-top: 125px; padding-bottom: 125px; }
  @media screen and (max-width: 1024px) { padding-top: 80px;  padding-bottom: 80px;  }
  @media screen and (max-width: 768px)  { padding-top: 70px;  padding-bottom: 70px;  }
}
.yTopPadding250 { padding-top: 250px;
  @media screen and (max-width: 1600px) { padding-top: 200px; }
  @media screen and (max-width: 1400px) { padding-top: 150px; }
  @media screen and (max-width: 1200px) { padding-top: 125px; }
  @media screen and (max-width: 1024px) { padding-top: 80px;  }
  @media screen and (max-width: 768px)  { padding-top: 70px;  }
}
.yBottomPadding250 { padding-bottom: 250px;
  @media screen and (max-width: 1600px) { padding-bottom: 200px; }
  @media screen and (max-width: 1400px) { padding-bottom: 150px; }
  @media screen and (max-width: 1200px) { padding-bottom: 125px; }
  @media screen and (max-width: 1024px) { padding-bottom: 80px;  }
  @media screen and (max-width: 768px)  { padding-bottom: 70px;  }
}

// Padding de 200px en Y ----------
.yPadding200 { padding-top: 200px; padding-bottom: 200px;
  @media screen and (max-width: 1600px) { padding-top: 175px; padding-bottom: 175px; }
  @media screen and (max-width: 1400px) { padding-top: 150px; padding-bottom: 150px; }
  @media screen and (max-width: 1200px) { padding-top: 125px; padding-bottom: 125px; }
  @media screen and (max-width: 1024px) { padding-top: 80px;  padding-bottom: 80px;  }
  @media screen and (max-width: 768px)  { padding-top: 70px;  padding-bottom: 70px;  }
}
.yTopPadding200 { padding-top: 200px;
  @media screen and (max-width: 1600px) { padding-top: 175px; }
  @media screen and (max-width: 1400px) { padding-top: 150px; }
  @media screen and (max-width: 1200px) { padding-top: 125px; }
  @media screen and (max-width: 1024px) { padding-top: 80px;  }
  @media screen and (max-width: 768px)  { padding-top: 70px;  }
}
.yBottomPadding200 { padding-bottom: 200px;
  @media screen and (max-width: 1600px) { padding-bottom: 175px; }
  @media screen and (max-width: 1400px) { padding-bottom: 150px; }
  @media screen and (max-width: 1200px) { padding-bottom: 125px; }
  @media screen and (max-width: 1024px) { padding-bottom: 80px;  }
  @media screen and (max-width: 768px)  { padding-bottom: 70px;  }
}

// Padding de 150px en Y ----------
.yPadding150 { padding-top: 150px; padding-bottom: 150px;
  @media screen and (max-width: 1600px) { padding-top: 125px; padding-bottom: 125px; }
  @media screen and (max-width: 1400px) { padding-top: 100px; padding-bottom: 100px; }
  @media screen and (max-width: 1200px) { padding-top: 90px;  padding-bottom: 90px;  }
  @media screen and (max-width: 1024px) { padding-top: 80px;  padding-bottom: 80px;  }
  @media screen and (max-width: 768px)  { padding-top: 70px;  padding-bottom: 70px;  }
}
.yTopPadding150 { padding-top: 150px;
  @media screen and (max-width: 1600px) { padding-top: 125px; }
  @media screen and (max-width: 1400px) { padding-top: 100px; }
  @media screen and (max-width: 1200px) { padding-top: 90px;  }
  @media screen and (max-width: 1024px) { padding-top: 80px;  }
  @media screen and (max-width: 768px)  { padding-top: 70px;  }
}
.yBottomPadding150 { padding-bottom: 150px;
  @media screen and (max-width: 1600px) { padding-bottom: 125px; }
  @media screen and (max-width: 1400px) { padding-bottom: 100px; }
  @media screen and (max-width: 1200px) { padding-bottom: 90px;  }
  @media screen and (max-width: 1024px) { padding-bottom: 80px;  }
  @media screen and (max-width: 768px)  { padding-bottom: 70px;  }
}

// Padding de 100px en Y ----------
.yPadding100 { padding-top: 100px; padding-bottom: 100px;
  @media screen and (max-width: 1400px) { padding-top: 90px; padding-bottom: 90px; }
  @media screen and (max-width: 1024px) { padding-top: 80px; padding-bottom: 80px; }
  @media screen and (max-width: 768px)  { padding-top: 70px; padding-bottom: 70px; }
}
.yTopPadding100 { padding-top: 100px;
  @media screen and (max-width: 1400px) { padding-top: 90px; }
  @media screen and (max-width: 1024px) { padding-top: 80px; }
  @media screen and (max-width: 768px)  { padding-top: 70px; }
}
.yBottomPadding100 { padding-bottom: 100px;
  @media screen and (max-width: 1400px) { padding-bottom: 90px; }
  @media screen and (max-width: 1024px) { padding-bottom: 80px; }
  @media screen and (max-width: 768px)  { padding-bottom: 70px; }
}

// Padding de 80px en Y ----------
.yPadding80 { padding-top: 80px; padding-bottom: 80px;
  @media screen and (max-width: 768px)  { padding-top: 50px; padding-bottom: 50px; }
}
.yTopPadding80 { padding-top: 80px;
  @media screen and (max-width: 768px)  { padding-top: 50px; }
}
.yBottomPadding80 { padding-bottom: 80px;
  @media screen and (max-width: 768px)  { padding-bottom: 50px; }
}

// Padding de 70px en Y ----------
.yPadding70       { padding-top: 70px; padding-bottom: 70px;}
.yTopPadding70    { padding-top: 70px;
  @media screen and (max-width: 600px)  { padding-top: 35px; }
 }
.yBottomPadding70 { padding-bottom: 70px; }

// Padding de 50px en Y ----------
.yPadding50       { padding-top: 50px; padding-bottom: 50px;}
.yTopPadding50    { padding-top: 50px; }
.yBottomPadding50 { padding-bottom: 50px;
  @media screen and (max-width: 600px)  { padding-bottom: 30px; }
 }



////////////////////////////////////////////////////////////////////////////////////
// UN PEU DE PADDING EN LARGEUR
////////////////////////////////////////////////////////////////////////////////////
// Padding de 15% en X ----------
.xPadding15 { padding-right: 15%; padding-left: 15%;
  @media screen and (max-width: 1400px) { padding-right: 12%; padding-left: 12%; }
  @media screen and (max-width: 1200px) { padding-right: 10%; padding-left: 10%; }
  @media screen and (max-width: 1024px) { padding-right: 30px; padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding15 { padding-right: 15%;
  @media screen and (max-width: 1400px) { padding-right: 12%;  }
  @media screen and (max-width: 1200px) { padding-right: 10%;  }
  @media screen and (max-width: 1024px) { padding-right: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; }
}
.xLeftPadding15 { padding-left: 15%;
  @media screen and (max-width: 1400px) { padding-left: 12%;  }
  @media screen and (max-width: 1200px) { padding-left: 10%;  }
  @media screen and (max-width: 1024px) { padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-left: 20px; }
}

// Padding de 12% en X ----------
.xPadding12 { padding-right: 12%; padding-left: 12%;
  @media screen and (max-width: 1200px) { padding-right: 10%; padding-left: 10%; }
  @media screen and (max-width: 1024px) { padding-right: 30px; padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding12 { padding-right: 12%;
  @media screen and (max-width: 1200px) { padding-right: 10%;  }
  @media screen and (max-width: 1024px) { padding-right: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; }
}
.xLeftPadding12 { padding-left: 12%;
  @media screen and (max-width: 1200px) { padding-left: 10%;  }
  @media screen and (max-width: 1024px) { padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-left: 20px; }
}

// Padding de 10% en X ----------
.xPadding10 { padding-right: 10%; padding-left: 10%;
  @media screen and (max-width: 1200px) { padding-right: 8%; padding-left: 8%; }
  @media screen and (max-width: 1024px) { padding-right: 30px; padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding10 { padding-right: 10%;
  @media screen and (max-width: 1200px) { padding-right: 8%;  }
  @media screen and (max-width: 1024px) { padding-right: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; }
}
.xLeftPadding10 { padding-left: 10%;
  @media screen and (max-width: 1200px) { padding-left: 8%;  }
  @media screen and (max-width: 1024px) { padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-left: 20px; }
}

// Padding de 8% en X ----------
.xPadding8 { padding-right: 8%; padding-left: 8%;
  @media screen and (max-width: 1200px) { padding-right: 6%;   padding-left: 6%;   }
  @media screen and (max-width: 1024px) { padding-right: 30px; padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding8 { padding-right: 8%;
  @media screen and (max-width: 1200px) { padding-right: 6%;   }
  @media screen and (max-width: 1024px) { padding-right: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; }
}
.xLeftPadding8 { padding-left: 8%;
  @media screen and (max-width: 1200px) { padding-left: 6%;   }
  @media screen and (max-width: 1024px) { padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-left: 20px; }
}

// Padding de 6% en X ----------
.xPadding6 { padding-right: 6%; padding-left: 6%;
  @media screen and (max-width: 1200px) { padding-right: 5%;   padding-left: 5%;   }
  @media screen and (max-width: 1024px) { padding-right: 30px; padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding6 { padding-right: 6%;
  @media screen and (max-width: 1200px) { padding-right: 5%;   }
  @media screen and (max-width: 1024px) { padding-right: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; }
}
.xLeftPadding6 { padding-left: 6%;
  @media screen and (max-width: 1200px) { padding-left: 5%;   }
  @media screen and (max-width: 1024px) { padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-left: 20px; }
}

// Padding de 5% en X ----------
.xPadding5 { padding-right: 5%; padding-left: 5%;
  @media screen and (max-width: 1024px) { padding-right: 30px; padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding5 { padding-right: 5%;
  @media screen and (max-width: 1024px) { padding-right: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; }
}
.xLeftPadding5 { padding-left: 5%;
  @media screen and (max-width: 1024px) { padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-left: 20px; }
}
// Padding de 50px en X ----------
.xPadding80 { padding-right: 80px; padding-left: 80px;
  @media screen and (max-width: 1024px) { padding-right: 30px; padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding80 { padding-right: 80px;
  @media screen and (max-width: 1024px) { padding-right: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; }
}
.xLeftPadding80 { padding-left: 80px;
  @media screen and (max-width: 1024px) { padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-left: 20px; }
}
// Padding de 50px en X ----------
.xPadding50 { padding-right: 50px; padding-left: 50px;
  @media screen and (max-width: 1024px) { padding-right: 30px; padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; padding-left: 20px; }
}
.xRightPadding50 { padding-right: 50px;
  @media screen and (max-width: 1024px) { padding-right: 30px; }
  @media screen and (max-width: 768px)  { padding-right: 20px; }
}
.xLeftPadding50 { padding-left: 50px;
  @media screen and (max-width: 1024px) { padding-left: 30px; }
  @media screen and (max-width: 768px)  { padding-left: 20px; }
}



////////////////////////////////////////////////////////////////////////////////////
// LE 0 ABSOLUE
////////////////////////////////////////////////////////////////////////////////////
// Padding ----------
.noTopPadding     { padding-top: 0;    }
.noRightPadding     { padding-right: 0;  }
.noRightPadding1024     { @media screen and (max-width: 1024px) { padding-right: 0; }}
.noBottomPadding { padding-bottom: 0; }
.noLeftPadding   { padding-left: 0;   }
.noXPadding      { padding-right: 0; padding-left: 0; }
.noYPadding      { padding-top: 0; padding-bottom: 0; }
.noPadding       { padding: 0; }

// Margin ----------
.noTopMargin    { margin-top: 0;    }
.noRightMargin  { margin-right: 0;  }
.noBottomMargin { margin-bottom: 0; }
.noLeftMargin   { margin-left: 0;   }
.noXMargin      { margin-right: 0; margin-left: 0; }
.noYMargin      { margin-top: 0; margin-bottom: 0; }

// Positionnement ----------
.top0    { top: 0;    }
.right0  { right: 0;  }
.bottom0 { bottom: 0; }
.left0   { left: 0;   }
.trbl0   { top: 0; right: 0; bottom: 0; left: 0; }



////////////////////////////////////////////////////////////////////////////////////
// MISCELLANEOUS
////////////////////////////////////////////////////////////////////////////////////
// White-space ----------
.nowrap { white-space: nowrap; }

// Clear les float ----------
.clearfix { display: block; clear: both; }

// Empêche le scroll
.noscroll {
    position: fixed;
    width: 100%;
    overflow-y: scroll;

    @media screen and (max-width: 768px) {
        overflow-y: hidden;
    }
}

// Empêche la sélection du texte
.noselect { user-select: none; }



////////////////////////////////////////////////////////////////////////////////////
// HIDE AND SEEK
////////////////////////////////////////////////////////////////////////////////////
// Hide ----------
.hide { display: none;  }
@media screen and (max-width: 1800px) { .hide_1800 { display: none; }}
@media screen and (max-width: 1700px) { .hide_1700 { display: none; }}
@media screen and (max-width: 1600px) { .hide_1600 { display: none; }}
@media screen and (max-width: 1500px) { .hide_1500 { display: none; }}
@media screen and (max-width: 1400px) { .hide_1400 { display: none; }}
@media screen and (max-width: 1300px) { .hide_1300 { display: none; }}
@media screen and (max-width: 1200px) { .hide_1200 { display: none; }}
@media screen and (max-width: 1100px) { .hide_1100 { display: none; }}
@media screen and (max-width: 1024px) { .hide_1024 { display: none; }}
@media screen and (max-width: 1000px) { .hide_1000 { display: none; }}
@media screen and (max-width: 900px)  { .hide_900  { display: none; }}
@media screen and (max-width: 800px)  { .hide_800  { display: none; }}
@media screen and (max-width: 768px)  { .hide_768  { display: none; }}
@media screen and (max-width: 700px)  { .hide_700  { display: none; }}
@media screen and (max-width: 600px)  { .hide_600  { display: none; }}
@media screen and (max-width: 500px)  { .hide_500  { display: none; }}
@media screen and (max-width: 400px)  { .hide_400  { display: none; }}
@media screen and (max-width: 300px)  { .hide_300  { display: none; }}



////////////////////////////////////////////////////////////////////////////////////
// I SEE YOU
////////////////////////////////////////////////////////////////////////////////////
// Show ----------
.show { display: block;  }
.show_1800 { display: none; @media screen and (max-width: 1800px) { display: block; }}
.show_1700 { display: none; @media screen and (max-width: 1700px) { display: block; }}
.show_1600 { display: none; @media screen and (max-width: 1600px) { display: block; }}
.show_1500 { display: none; @media screen and (max-width: 1500px) { display: block; }}
.show_1400 { display: none; @media screen and (max-width: 1400px) { display: block; }}
.show_1300 { display: none; @media screen and (max-width: 1300px) { display: block; }}
.show_1200 { display: none; @media screen and (max-width: 1200px) { display: block; }}
.show_1100 { display: none; @media screen and (max-width: 1100px) { display: block; }}
.show_1024 { display: none; @media screen and (max-width: 1024px) { display: block; }}
.show_1000 { display: none; @media screen and (max-width: 1000px) { display: block; }}
.show_900  { display: none; @media screen and (max-width: 900px)  { display: block; }}
.show_800  { display: none; @media screen and (max-width: 800px)  { display: block; }}
.show_768  { display: none; @media screen and (max-width: 768px)  { display: block; }}
.show_700  { display: none; @media screen and (max-width: 700px)  { display: block; }}
.show_600  { display: none; @media screen and (max-width: 600px)  { display: block; }}
.show_500  { display: none; @media screen and (max-width: 500px)  { display: block; }}
.show_400  { display: none; @media screen and (max-width: 400px)  { display: block; }}
.show_300  { display: none; @media screen and (max-width: 300px)  { display: block; }}



////////////////////////////////////////////////////////////////////////////////////
// EN PROFONDEUR
////////////////////////////////////////////////////////////////////////////////////
// Z-index ----------
.zIndex-1 { z-index: -1; }
.zIndex0  { z-index: 0;  }
.zIndex1  { z-index: 1;  }
.zIndex2  { z-index: 2;  }
.zIndex3  { z-index: 3;  }
.zIndex4  { z-index: 4;  }
.zIndex5  { z-index: 5;  }
.zIndex6  { z-index: 6;  }
.zIndex7  { z-index: 7;  }
.zIndex8  { z-index: 8;  }
.zIndex9  { z-index: 9;  }
.zIndex10 { z-index: 10; }
