.arrowSection {
    .wrapper {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-right: 15%;
        margin-left: 15%;
        @media screen and (max-width: 1400px) { margin-right: 12%; margin-left: 12%; }
        @media screen and (max-width: 1200px) { margin-right: 10%; margin-left: 10%; }
        @media screen and (max-width: 1024px) { margin-right: 30px; margin-left: 30px; }
        @media screen and (max-width: 768px)  { margin-right: 20px; margin-left: 20px; }

        .prev {
            display: flex;
            align-items: center;
            color: $color1;
            text-transform: uppercase;
            font: rem(12) $font1b;
            padding: 30px 0;
            transition: color 300ms;

            @media screen and (max-width: 768px)  { padding: 20px 0; }

            &:hover {
                color: $color4;

                svg {
                    fill: $color4;
                }
            }

            svg {
                width: 15px;
                height: 15px;
                margin-right: 20px;
                fill: $color1;
                transition: fill 300ms;
                transform: scale(-1, -1);
            }
        }

        .next {
            display: flex;
            align-items: center;
            color: $color1;
            text-transform: uppercase;
            font: rem(12) $font1b;
            padding: 30px 0;
            transition: color 300ms;

            @media screen and (max-width: 768px)  { padding: 20px 0; }

            &:hover {
                color: $color4;

                svg {
                    fill: $color4;
                }
            }

            svg {
                width: 15px;
                height: 15px;
                margin-left: 20px;
                fill: $color1;
                transition: fill 300ms;
            }
        }

        .prev, .next {
            &.no-more-page {
                color: $color4;
                cursor: default;
                pointer-events: none;

                svg {
                    fill: $color4;
                }
            }
        }
    }
}
