.textField {
    textarea {
        width: 100%;
        height: 190px;
        padding: 20px;
        font: rem(21)/1 $font1;
        color: $color1;
        background: transparent;
        border: 1px solid $color1;
        box-shadow: none;
        outline: none;
        resize: none;
        transition: border 300ms;

        @media screen and (max-width: 768px) { font-size: rem(18); padding: 15px 10px; }
    }

    ::-webkit-input-placeholder { color: rgba($color1, 0.5); }
    ::-moz-placeholder { color: rgba($color1, 0.5); }
    :-ms-input-placeholder {  color: rgba($color1, 0.5); }
    :-moz-placeholder { color: rgba($color1, 0.5); }
}
