#page-eventsDetails {
    .headerBlock .row .content .extra {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 768px) { flex-direction: column; align-items: flex-start; }

        .subtitle {
            font-size: rem(15);
            color: $color1;
            display: flex;

            @media screen and (max-width: 768px) { flex-direction: column; }

            span {
                margin-left: 20px;

                @media screen and (max-width: 768px) { margin-left: 0; }
            }
        }

        .price {
            display: flex;
            align-items: center;
            font: rem(22) $font2b;
            color: $color1;

            @media screen and (max-width: 768px) { margin-top: 15px; }

            svg {
                height: 40px;
                width: 40px;
                margin-right: 20px;
                fill: $color1;
            }
        }
    }
}
