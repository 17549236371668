// TECHNIQUE
$browser-context: 16; // La taille initiale de la typo
$gaps: 1%; // Padding entre chaque colonne d'une row
$duration: 800ms;

// COULEURS
$color1: #2a3037; // Noir gris
$color2: #f8d2a4; // Beige
$color3: #f0f0f1; // Blanc gris
$color4: #b2b6bb; // Gris
$color5: #7f8387; // Gris bleu
$color6: #ecdede; // Rose
$color9: #e2e2e4; // gris très pâle

// FONTS
$font1 : 'nunitosans-regular', sans-serif;
$font1l: 'nunitosans-light',   sans-serif;
$font1b: 'nunitosans-bold',    sans-serif;
$font2b: 'noedisplay-bold',    sans-serif;

/*** ----- EASINGS ----- ***/
$ease: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$easeInOutQuart: cubic-bezier(0.65, 0, 0.35, 1);
$easeInOutCubic: cubic-bezier(0.76, 0, 0.24, 1);
