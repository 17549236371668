@font-face {
    font-family: 'nunitosans-regular';
    src: url('../fonts/nunito/nunitosans-regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nunitosans-bold';
    src: url('../fonts/nunito/nunitosans-bold.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nunitosans-light';
    src: url('../fonts/nunito/nunitosans-light.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'noedisplay-bold';
    src: url('../fonts/noe/noedisplay-bold.eot');
    src: url('../fonts/noe/noedisplay-bold.woff2') format('woff2'),
        url('../fonts/noe/noedisplay-bold.woff') format('woff'),
        url('../fonts/noe/noedisplay-bold.ttf') format('truetype'),
        url('../fonts/noe/noedisplay-bold.svg#MaisonNeue-Demi') format('svg'),
        url('../fonts/noe/noedisplay-bold.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
