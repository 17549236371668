.side-and-list {
    &--search-results &__container {
        padding-top: 0;
    }
    &--search-results &__main {
        padding-right: 0px;
        padding-left: 0px;

        @media screen and (max-width: 600px)  {
            width: calc(100% + 50px);
            margin-top: 25px;
            margin-right: -25px;
            margin-left: -25px;
        }
    }
    &--search-results &__list {
        padding: 60px 100px;
        max-width: 100%;
        background: #fff;

        @media screen and (max-width: 1024px) { padding-right: 50px; padding-left: 50px; }
        @media screen and (max-width: 768px)  { padding: 50px 30px; }
        @media screen and (max-width: 600px)  { padding: 40px 25px; }
    }
    &--search-results &__side {
        margin-top: 60px;

        @media screen and (max-width: 500px) { padding-top: 50px; }
    }
}

.search-result {
    padding-bottom: 50px;
    width: 100%;

    @media screen and (max-width: 1024px) { padding-bottom: 40px; }
    @media screen and (max-width: 768px)  { padding-bottom: 30px; }

    &:not(:first-child) {
        padding-top: 50px;
        border-top: 1px solid rgba($color1, 0.2);

        @media screen and (max-width: 1024px) { padding-top: 40px; }
        @media screen and (max-width: 768px)  { padding-top: 30px; }
    }

    &__subtitle {
        margin-bottom: 10px;
        @extend .tiny-title;
    }

    &__title {
        font: rem(28)/1.3 $font1;
        color: $color1;
        font-style: normal;

        @media screen and (max-width: 1024px) { font-size: rem(25); }
        @media screen and (max-width: 768px)  { font-size: rem(22); }
    }

    &__text {
        @extend .top-margin-30;
        @extend .bottom-margin-40;
    }

    &__btn {
        display: inline-block;
    }

    mark {
        font-weight: normal;
        font-style: normal;
        color: $color1;
        background: $color3;
    }

    &:hover &__btn:before { transform: scale(1.05); }
}
