.wrapperBtnFixed {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;

    #btnFixed {
        display: block;
        position: fixed;
        width: auto;
        padding: 40px 50px;
        bottom: 0;
        right: 0;
        font: 22px/1 $font2b;
        color: $color2;
        background: #000;
        text-align: center;
        transition: color 300ms;

        &:hover {
            color: #fff;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 40px 0;
        }

        &.light {
            color: #000;
            background: $color2;

            &:hover {
                color: #fff;
            }
        }
    }
}
