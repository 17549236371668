.blockStyle3 {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 380px;
    height: 420px;
    align-items: center;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;


    @media screen and (max-width: 1024px) { height: 380px; }
    @media screen and (max-width: 900px)  { height: 340px; }
    @media screen and (max-width: 768px)  { height: 300px; }
    @media screen and (max-width: 500px)  { height: 350px; }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($color1, 0.6);
    }

    & > div {
        position: relative;
        width: 100%;
        padding-bottom: 0;
        transition: padding 300ms $ease 175ms;

        h5 {
            margin-bottom: 20px;
            font-size: 12px;

            @media screen and (max-width: 768px) { margin-bottom: 15px; }
        }

        h4 {
            margin-bottom: 20px;
            color: #fff;

            @media screen and (max-width: 768px) { margin-bottom: 15px; }
        }

        h3 {
            color: $color2;
        }

        div {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            text-align: center;

            p {
                display: inline-block;
                opacity: 0;
                transition: opacity 175ms linear;
            }
        }

    }

    /*&:hover {
        & > div {
            padding-bottom: 50px;
            transition: padding 300ms $ease;

            @media screen and (max-width: 768px) { padding-bottom: 30px; }

            p {
                opacity: 1;
                transition: opacity 175ms linear 300ms;
            }
        }
    }*/
}
