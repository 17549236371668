// Documents normal
.documents {
    .document {
        position: relative;
        border-bottom: 1px solid rgba($color1, 0.2);

        &:first-child { border-top: 1px solid rgba($color1, 0.2); }

        &__link {
            display: block;
            position: relative;
            padding-top: 50px;
            padding-bottom: 50px;
            cursor: pointer;

            @media screen and (max-width: 1024px) { padding-top: 40px; padding-bottom: 40px; }
            @media screen and (max-width: 600px)  { padding-top: 30px; padding-bottom: 30px; }
        }

        &__link:hover .document__svg {
            fill: $color4;
        }

        &__link:hover .document__title {
            color: $color4;
        }

        &__title {
            padding-right: 50px;
            font: rem(18)/1.5 $font1b;
            color: $color1;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: color 300ms;

            @media screen and (max-width: 768px) { font-size: rem(16); }
            @media screen and (max-width: 500px) { font-size: rem(15); }
        }

        &__icon {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 50%;
            right: var(--x-padding-160);
            transform: translateY(-50%);
        }

        &__icon:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #fff;
            border-radius: 50%;
        }

        &__svg {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            left: 50%;
            fill: $color1;
            transform: translate(-50%, -50%);
            transition: fill 300ms;
        }

        &__svg-upload {
            width: 10px;
            height: 10px;
        }
    }
}

// Documents plus
.documents.documents--plus {
    .document {
        &:not(.document__no-image) .document__link {
            padding-top: 20px;
            padding-bottom: 20px;

            @media screen and (max-width: 600px) { padding-top: 30px; padding-bottom: 30px; }
        }

        &__flex {
            display: flex;
            align-items: center;
        }

        &__text {
            width: calc(100% - 170px);

            @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
            @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
            @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
            @media screen and (max-width: 600px)  { width: 100%; }
        }

        &__picture {
            width: 100px;
            height: 100px;
            margin-right: 30px;
            overflow: hidden;

            @media screen and (max-width: 1024px) { margin-right: 25px; }
            @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
            @media screen and (max-width: 600px)  { display: none; }
        }

        &__subtitle {
            margin-bottom: 5px;
            padding-right: 50px;
            font: rem(12)/1.5 $font1b;
            color: $color1;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: color 300ms;
        }

        &__link:hover .document__subtitle {
            color: $color4;
        }

        &__plus {
            height: 140px;
            border-radius: 50%;
            overflow: hidden;

            @media screen and (max-width: 1200px) { width: 125px; height: 125px; }
            @media screen and (max-width: 1024px) { width: 100px; height: 100px; margin-right: 25px; }
            @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
            @media screen and (max-width: 600px)  { display: none; }

            &__picture {
                width: 140px;
                margin-right: 30px;
            }
        }
    }
}

// Document normal dans une rubrique
.documents-in-rubric {
    .document {
        border-bottom: 1px solid $color4;

        &:first-child {
            .document__link { padding-top: 0; }
            .document__svg  { top: calc(50% - (25px / 2)); }
        }

        &__link {
            display: block;
            position: relative;
            padding: 15px 60px 15px 0;
        }

        &__link:hover {
            .document__title { color: $color4; }
        }

        &__title {
            padding-right: 50px;
            font: rem(14)/1.5 $font1b;
            color: $color1;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: color 300ms;
        }

        &__svg {
            position: absolute;
            width: 14px;
            height: 16px;
            top: 50%;
            right: 30px;
            fill: $color1;
            transform: translateY(-50%);
            z-index: 1;
            transition: fill 300ms;
        }

        &__svg-upload {
            width: 11px;
            height: 11px;
        }
    }
}

// Document normal dans une rubrique
.documents-in-rubric.documents-in-rubric--plus {
    .document {
        &__flex {
            display: flex;
            align-items: center;
        }

        &__text {
            width: calc(100% - 130px);

            @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
            @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
            @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
            @media screen and (max-width: 600px)  { width: 100%; }
        }

        &__picture {
            width: 75px;
            height: 75px;
            margin-right: 30px;
            overflow: hidden;

            @media screen and (max-width: 1024px) { margin-right: 25px; }
            @media screen and (max-width: 768px)  { margin-right: 20px; }
            @media screen and (max-width: 600px)  { display: none; }
        }

        &__subtitle {
            margin-bottom: 5px;
            font: rem(12)/1.5 $font1b;
            color: $color1;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: color 300ms;
        }

        &__link:hover .document__subtitle {
            color: $color4;
        }
    }
}

// Document dans une rubrique en tant que composante
.documents-in-rubric.documents-in-rubric--small {
    $this: '.documents-in-rubric';

    #{$this}__title {
        font: rem(28)/1.5 $font2b;
        color: $color1;
        margin-bottom: 30px;

        @media screen and (max-width: 1024px) { font-size: rem(25); }
        @media screen and (max-width: 768px)  { font-size: rem(22); margin-bottom: 20px; }
        @media screen and (max-width: 500px)  { font-size: rem(20); }
    }

    .document {
        max-width: 475px;

        &__link {
            padding-right: 40px;
        }

        &__svg {
            right: 15px;
        }

        &__title {
            font: rem(14)/1.5 $font1b;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}
