.sectionBlock {
    margin-bottom: 60px;

    @media screen and (max-width: 768px) { margin-bottom: 45px; }
    @media screen and (max-width: 500px) { margin-bottom: 30px; }

    .image {
        position: relative;
        height: 300px;

        @media screen and (max-width: 1600px) { height: 260px; }
        @media screen and (max-width: 1440px) { height: 225px; }
        @media screen and (max-width: 768px) { height: 340px; }
        @media screen and (max-width: 500px) { height: 250px; }

        .overlay {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            transition: opacity 300ms $ease;
            background-color: rgba($color: $color1, $alpha: 0.4);

            h5 {
                font-size: rem(12);
                color: #fff;
                border-bottom: 1px solid #fff;
            }
        }
    }

    .text {
        margin-top: 30px;

        h2 {
            font-size: rem(28);
        }

        h5 {
            font-size: rem(12);
            color: $color5;
            margin-bottom: 15px;
        }
    }

    &:hover {
        .image .overlay {
            opacity: 1;
        }
    }
}
