h1, .h1, .huge-title {
    @include huge-title();
}

h2, .h2, .large-title {
    @include large-title();
}

h3, .h3, .medium-title {
    @include medium-title();
}

h4, .h4, .small-title {
    @include small-title();
}

h5, .h5, .tiny-title {
    @include tiny-title();
}

p {
    @include paragraph();
}

a {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
}


.line-title-text {
    position: relative;
    padding-left: 50px;
    padding-right: 20px;

    @media screen and (max-width: 1024px) { padding-left: 30px; }

    &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 0;
        width: 30px;
        height: 1px;
        background: $color1;

        @media screen and (max-width: 1024px) { width: 15px; top: 17px; }
        @media screen and (max-width: 768px)  { top: 16px; }
        @media screen and (max-width: 500px)  { top: 15px; }
    }

    &__title {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.3;
        color: $color1;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: rem(30);

        @media screen and (max-width: 1024px) { font-size: rem(26); letter-spacing: 2px; }
        @media screen and (max-width: 768px)  { font-size: rem(24); letter-spacing: 1px; }
        @media screen and (max-width: 500px)  { font-size: rem(22); }
    }

    &__text {
        margin-top: 5px;
    }
}
