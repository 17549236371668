#page-search {
    .headerBlock .content .title h1 {
        margin-bottom: 0;
    }

    .searchContainer {
        background-color: $color3;

        .search__form {
            margin-bottom: 30px;
        }

        .field__input {
            color: $color1;
            border-bottom: 1px solid $color4;
        }

        .field--search .field__erase {
            position: absolute;
            top: 50%;
            right: 60px;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            opacity: 0;
            visibility: hidden;
            cursor: pointer;
            transition: opacity 300ms, visibility 0ms ease 300ms;

            @media screen and (max-width: 600px) {
                right: 35px;
            }

            &:before, &:after {
                content: '';
                position: absolute;
                width: 1px;
                height: 14px;
                top: 50%;
                left: 50%;
                background: $color1;
            }
            &:before { transform: translate(-50%, -50%) rotate(45deg);  }
            &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover  { opacity: 0.6 !important; }
        }

        .field--search[data-inputvalue]:not([data-inputvalue=""]) .field__erase {
            opacity: 1;
            visibility: visible;
            transition: opacity 300ms;
        }

        .field--search .field__svg {
            fill: $color1;
        }

        .field--search {
            @media screen and (max-width: 600px) { margin-bottom: 0; }
            ::-webkit-input-placeholder { color: rgba($color1, 0.2); font-weight: normal; }
            ::-moz-placeholder { color: rgba($color1, 0.2); font-weight: normal; }
            :-ms-input-placeholder { color: rgba($color1, 0.2); font-weight: normal; }
            :-moz-placeholder { color: rgba($color1, 0.2); font-weight: normal; }
        }

        input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill,
        textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill,
        select:-webkit-autofill:hover, select:-webkit-autofill:focus { -webkit-box-shadow: 0 0 0 1000px $color3 inset !important; }

        input:-webkit-autofill {
            -webkit-text-fill-color: $color1 !important;
        }

        .searchIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: opacity 300ms;

            &:hover svg {
                opacity: 0.6;
                transition: opacity 300ms;
            }

            svg {
                fill: $color1;
                width: 24px;
                height: 24px;

                @media screen and (max-width: 600px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .extra {
            .field {
                position: relative;
                width: 33%;
                margin-bottom: 0;

                @media screen and (max-width: 768px) { width: 100%; }

                // Erreur
                &__error {
                    margin-top: 5px;
                    font: rem(11)/1 $font1;
                    color: $color6;
                    width: 150px;
                }

                // Select
                &--select &__label {
                    color: $color1;
                }

                &--select .select-label {
                    border: 1px solid $color4;
                    height: 64px;
                    @media screen and (max-width: 600px) { height: 45px; }

                    &:hover {
                        background: transparent;

                        .label-inner {
                            color: $color5;

                            &:before {
                                background: url('../images/content/icons/arrow_grey.svg') no-repeat center / contain;
                            }
                        }
                    }
                }

                &--select .label-inner {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    font: 15px/1.3 $font1;
                    color: $color1;
                    transition: color 500ms $easeInOutCubic;

                    @media screen and (max-width: 768px) { font-size: 13px; }

                    &:before {
                        background: url('../images/content/icons/arrow.svg') no-repeat center / contain;
                        transition: background 500ms $easeInOutCubic;
                    }
                }

                // Select natif
                &--select select {
                    width: 100%;
                    padding: 0 20px;
                    font: 15px/1.3 $font1;
                    color: #fff;
                    border: 1px solid #000;
                    outline: none;
                    -webkit-appearance: none;
                    border-radius: 0;
                    background: url('../images/content/icons/arrow_white.svg') no-repeat calc(100% - 20px) 50% / 12px 12px, #000;

                    @media screen and (max-width: 768px) { font-size: 13px; }
                }
            }
        }
    }

    .searchResultsContainer {
        mark {
            font-weight: normal;
            font-style: normal;
            color: $color1;
            background: $color2;
        }

    }
    .noResults {
        margin-bottom: 30px;
    }
}
