.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }

    ul {
        display: flex;

        li {
            a, span {
                min-width: 42px;
                height: 42px;
                color: $color1;
                text-align: center;
                display: inline-block;
                padding: 0 8px;
                transition: all $ease 300ms;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: rem(12);
                font-family: $font1b;

                &.active {
                    background-color: $color1;
                    color: #fff;
                }
            }

            a {
                &:hover {
                    background-color: $color3;
                }
            }

            span {
                pointer-events: none;
            }

            &.btnGroup {
                display: flex;

                a {
                    min-width: auto;
                    padding: 0 10px;

                    svg {
                        width: 13px;
                        height: 13px;
                        fill: $color1;
                        transition: fill $color1 300ms;
                    }
                }

                &.prev {
                    margin-right: 20px;

                    a {
                        transform: scaleX(-1);
                    }
                }

                &.next {
                    margin-left: 20px;
                }
            }
        }
    }

    .nbResults {
        margin-left: auto;
        color: $color6;
        font-size: rem(15);
    }
}
