#overlay-search {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden;
    visibility: hidden;
    z-index: 105;
    pointer-events: none;
    transition: visibility 0ms ease 700ms;

    .closeOverlay.x {
        position: absolute;
        top: 41px;
        right: 81px;
        width: 30px;
        height: 30px;
        transition: opacity 300ms;

        @media screen and (max-width: 768px) {
            right: 20px;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            width: 1px;
            height: 25px;
            top: 50%;
            left: 50%;
            background: #fff;
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg);  }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover  { opacity: 0.6; }
    }

    .wrapper {
        display: flex;
        position: fixed;
        height: 500px;
        top: -500px; right: 0; left: 0;
        background: #000;
        align-items: center;
        overflow-y: hidden;
        pointer-events: all;
        scrollbar-width: none;
        z-index: 11;
        transition: top 600ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms, bottom 600ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;

        @media screen and (max-width: 1024px) { right: 0; }
        @media screen and (max-height: 650px) { height: 100%; top: -100%; bottom: -100%; }

        .searchInput {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            justify-content: center;

            .title {
                margin-bottom: 80px;

                h2 {
                    color: #fff;
                }
            }

            .field__input {
                color: #fff;
                border-bottom: 1px solid $color4;
                padding-left: 0;

                width: 100%;
                height: 45px;
                border: none;
                border-bottom: 1px solid $color5;
                font: 22px/45px $font1;
                color: #fff;
                background: transparent;
                transition: border 300ms ease;

                &:focus { border-bottom-color: $color3; }

            }

            .field--search .field__erase {
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%);
                width: 30px;
                height: 30px;
                opacity: 0;
                visibility: hidden;
                cursor: pointer;
                transition: opacity 300ms, visibility 0ms ease 300ms;

                &:before, &:after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 14px;
                    top: 50%;
                    left: 50%;
                    background: #fff;
                }
                &:before { transform: translate(-50%, -50%) rotate(45deg);  }
                &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
                &:hover  { opacity: 0.6 !important; }
            }

            .field--search[data-inputvalue]:not([data-inputvalue=""]) .field__erase {
                opacity: 1;
                visibility: visible;
                transition: opacity 300ms;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:active
            input:-webkit-autofill:focus input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 1000px #000 inset !important;
                -webkit-text-fill-color: #fff !important;
            }

            input:-webkit-autofill::first-line {
                font: 22px/45px $font1;
            }

            .searchIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                transition: opacity 300ms;

                &:hover svg {
                    opacity: 0.6;
                    transition: opacity 300ms;
                }

                svg {
                    fill: #fff;
                    width: 24px;
                    height: 24px;

                    @media screen and (max-width: 600px) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .field--search {
                @media screen and (max-width: 600px) { margin-bottom: 0; }
                ::-webkit-input-placeholder { color: $color5; font-weight: normal; }
                ::-moz-placeholder { color: $color5; font-weight: normal; }
                :-ms-input-placeholder { color: $color5; font-weight: normal; }
                :-moz-placeholder { color: $color5; font-weight: normal; }
            }
        }

        .popular {
            width: 40%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 100px;

            @media screen and (max-width: 768px) {
                display: none;
            }

            h5 {
                font-size: rem(12);
                margin-bottom: 30px;
            }
            ul li {

                &:not(:last-child) {

                    margin-bottom: 15px;
                }
                a {
                    font: rem(15) $font1;
                    color: $color5;
                    transition: color 300ms;

                    &:hover {
                        color: #fff;
                    }
                }
            }

        }
    }
}

.showOverlaySearch {
    #overlay {
        background: rgba(#000, 0.3);
        visibility: visible;
        z-index: 104;
        transition: background 700ms $ease;
    }

    #overlay-search {
        overflow-y: auto;
        visibility: visible;
        transition: right 500ms ease;

        &::-webkit-scrollbar { display: none; }

        .wrapper {
            top: 0px;

            @media screen and (max-height: 650px) { top: 0%; bottom: 0%; }

            .linksWrapper {
                ul {
                    opacity: 1;
                    visibility: visible;
                    transform: none;
                    transition: opacity 500ms linear 500ms, transform 700ms $ease 500ms;
                }
            }
        }
    }
}
