.convenienceBlock {
        position: relative;
        margin-top: 80px;
        padding-right: 80px;
        padding-left: 80px;

        @media screen and (max-width: 1024px) { padding-right: 0; padding-left: 0; }

        .row {
            .col12 {
                height: 900px;

                @media screen and (max-width: 1400px) { height: 800px; }
                @media screen and (max-width: 1200px) { height: 700px; }
                @media screen and (max-width: 1024px) { height: 600px; }
                @media screen and (max-width: 768px)  { height: 500px; }
            }
        }

        .convenience {
            position: absolute;
            width: 450px;
            top: -80px;
            left: calc(50% - 225px);
            text-align: center;

            @media screen and (max-width: 1024px) { width: 400px; left: calc(50% - 200px); }
            @media screen and (max-width: 768px)  { width: 350px; left: calc(50% - 175px); }
            @media screen and (max-width: 500px)  { width: 300px; left: calc(50% - 150px); }
            @media screen and (max-width: 400px)  { width: 280px; left: calc(50% - 140px); }

            .wrapper1 {
                background: $color1;
                padding: 85px 20px;

                @media screen and (max-width: 1024px) { padding: 50px 20px; }
                @media screen and (max-width: 768px)  { padding: 30px 20px; }

                h5 {
                    margin-bottom: 50px;

                    @media screen and (max-width: 1024px) { margin-bottom: 30px; }
                    @media screen and (max-width: 768px)  { margin-bottom: 20px; }
                }

                ul {
                    li {
                        font: 15px/1.3 $font1;
                        color: #fff;

                        &:not(:last-child) {
                            margin-bottom: 20px;

                            @media screen and (max-width: 768px) { margin-bottom: 15px; }
                        }

                        &.note {
                            p { line-height: 1.5;}
                            &:not(:last-child) { margin-bottom: 0; }
                        }
                    }
                }
            }

            .wrapper2 {
                a {
                    padding: 40px 20px;
                    width: 100%;
                    background: #fff;
                    font: 22px/1 $font2b;
                    color: $color1;
                    transition: color 300ms;

                    @media screen and (max-width: 1024px) { padding: 30px 20px;}
                    @media screen and (max-width: 768px) { padding: 25px 20px; }

                    &:hover {
                        color: $color4;
                    }
                }
            }
        }
    }
