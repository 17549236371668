:root {
    --header-height: 75px;

    @media screen and (max-width: 1024px) { --header-height: 70px; }
    @media screen and (max-width: 768px)  { --header-height: 65px; }
    @media screen and (max-width: 600px)  { --header-height: 60px; }
}

header {
    overflow: hidden;

    @media screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        width: 100%;
        overflow: visible;
    }

    .newMenu {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        z-index: 105;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: top 300ms $ease;

        .logo {
            width: auto;
            height: var(--header-height);
            transition: width 200ms, height 300ms;

            @media screen and (max-width: 1024px) { width: 275px; }
            @media screen and (max-width: 500px)  { width: 240px; }
            @media screen and (max-width: 425px)  { width: 220px; }
            @media screen and (max-width: 375px)  { width: 100%; padding-right: 30px; }

            &.light svg {
                fill: #fff;
            }

            &.dark svg {
                fill: $color1;
            }

            svg {
                height: 100%;
                width: 100%;
                transition: fill 600ms $ease;
            }
        }

        .main {
            display: flex;
            height: var(--header-height);
            background-color: #000;
            align-items: center;
            transition: height 300ms;

            .item {
                display: flex;
                align-items: center;
                padding: 0 24px;
                height: 26px;
                transition: opacity 300ms;

                @media screen and (max-width: 1024px) { padding: 0 20px; }

                &:hover {
                    opacity: 0.6;
                }

                &.facebook {
                    padding: 0 13px 0 26px;

                    @media screen and (max-width: 1024px) { padding: 0 11px 0 22px; }
                    @media screen and (max-width: 600px) { display: none; }

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                &.instagram {
                    padding: 0 26px 0 13px;

                    @media screen and (max-width: 1024px) { padding: 0 22px 0 11px; }
                    @media screen and (max-width: 600px) { display: none; }

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                &.lang {
                    font: rem(15)/1 $font1b;
                    color: #fff;
                    padding: 3px 26px 0;

                    @media screen and (max-width: 1024px) { padding: 0 22px; }
                    @media screen and (max-width: 500px) { display: none; }
                }

                &:nth-child(4) {
                    border-left: 1px solid rgba(255,255,255, 0.25);

                    @media screen and (max-width: 600px) { border-left: none; }

                    .btnSearch {
                        width: 20px;
                        height: 20px;
                    }
                }
                &:nth-child(5) {
                    border-left: 1px solid rgba(255,255,255, 0.25);

                    @media screen and (max-width: 500px) { border-left: none; }

                    .btnSearch {
                        width: 20px;
                        height: 20px;
                    }
                }

                &:nth-child(6) {
                    border-left: 1px solid rgba(255,255,255, 0.25);
                }

                svg {
                    width: 20px;
                    height: 20px;
                    fill: #fff;
                }
            }
        }

        .contact {
            background-color: $color2;
            height: var(--header-height);
            display: flex;
            align-items: center;
            text-align: center;

            padding: 0 50px;
            font: 12px/1 $font1b;
            color: $color1;
            text-transform: uppercase;
            transition: color 300ms;

            @media screen and (max-width: 1024px) { padding: 0 40px; }
            @media screen and (max-width: 768px) { display: none; }

            &:hover {
                color: #fff;
            }
        }
    }

    .sideNav {
        position: fixed;
        top: 20px;
        right: 0;
        bottom: 0;
        z-index: 102;

        @media screen and (max-width: 1024px) { right: 75px; }

        .rotateAsc {

            @media screen and (max-width: 1024px) { display: none; }
            transform: rotate(-90deg);

            #scrollDown {
                padding-left: 100px;
                left: -700px;
                opacity: 0;
                transform: translateY(calc(50% + 3px));

                &:before, &:after { transform: translateY(calc(-50% - 0.5px)); }
                &:before { left: 65px; transition: left 300ms; }
                &:after  { left: 0px; }
                &:hover {
                    &:before { left: 50px; }
                }
            }
        }

        .rotateDesc {
            transform: rotate(90deg);
            @media screen and (max-width: 1024px) { transform: none; }

            #scrollUp {
                padding-left: 100px;
                left: 525px;

                @media screen and (max-height: 900px) { left: 400px; }
                @media screen and (max-height: 800px) { left: 325px; }

                &:before, &:after { transform: translateY(calc(-50% - 0.5px)); }
                &:before { left: 65px; transition: left 300ms; }
                &:after  { left: 0px; }
                &:hover {
                    &:before { left: 50px; }
                }

                svg {
                    display: none;
                }
                
                @media screen and (max-width: 1024px) { 
                    width: 55px;
                    height: 55px;
                    justify-content: center;
                    background-color: #000;
                    padding-left: 0 !important;
                    left: 0;
                    right: 45px;
                    bottom: 45px;

    
                    &:before, &:after {
                        display: none;
                    }
    
                    span {
                        display: none;
                    }
                    svg {
                        display: block;
                        height: 25%;
                        width: 25%;
                        fill: #fff;
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        #scrollDown, #scrollUp {
            display: flex;
            align-items: center;
            position: absolute;
            height: 33px;
            font: rem(11) $font1b;
            text-transform: uppercase;
            letter-spacing: 2px;
            opacity: 0;
            visibility: hidden;
            white-space: nowrap;
            transform: translateY(50%);
            transition: 300ms opacity, visibility 0ms linear 300ms;

            &.active {
                opacity: 1;
                visibility: visible;
                transition: 300ms opacity linear 300ms;
            }

            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
            }

            &:before {
                width: 11px;
                height: 11px;
                border-radius: 50%;
                border: 1px solid #000;
            }

            &:after {
                width: 70px;
                height: 1px;
                background: $color4;
                opacity: 0.4;
            }
        }
    }
}

.showOverlayAlert {
    header .newMenu {
        top: 65px;
    }
}

.showOverlayAlert.showOverlayMenu {
    header .newMenu {
        top: 20px;
    }
}
