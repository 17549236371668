#page-generic {
    #scrollUp {
        @media screen and (max-width: 1024px) {
            bottom: 147px;

            &.footerMobile {
                bottom: 45px;
            }
        }
    }

    .sectionList {
        background: $color3;

        .col24 {
            display: flex;
            flex-wrap: wrap;

            .sectionBlock {
                &:nth-child(2n - 1) {
                    width: calc(50% - 30px);
                    margin-right: 30px;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                        margin-right: 0;
                    }
                }

                &:nth-child(2n) {
                    width: calc(50% - 30px);
                    margin-left: 30px;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                        margin-left: 0;
                    }
                }

                &:nth-last-child(-n+1) {
                    margin-bottom: 0;
                }

                @media screen and (min-width: 769px) {
                    &:nth-last-child(-n+2) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
