.overlay-calendar {
    position: absolute;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;
    @media screen and (max-width: 600px) { top: 45px; }

    &::-webkit-scrollbar { display: none; }

    &__close {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 20px;
        right: 20px;
        cursor: pointer;

        &:hover { &:before, &:after { background: #fff; }}

        &:before, &:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: $color1;
            transition: background 300ms;
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg) ; }
        &:after { transform: translate(-50%, -50%) rotate(-45deg) ; }
    }

    &__word {
        display: inline-block;
        font: 700 rem(13)/1 $font1;
        color: $color1;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: absolute;
        top: 24px;
        right: 55px;
        cursor: pointer;
    }

    &__container {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color3;
        opacity: 0;
        text-align: center;
        z-index: 5;
        pointer-events: none;
        transition: opacity 800ms $easeOutQuart;
    }

    &__content {
        width: 100%;
        opacity: 0;
        transform: translateY(0px);
        transition: transform 800ms $easeOutQuart, opacity 800ms $easeOutQuart;
    }

    &__calendar {
        padding: 20px;
        box-shadow: 0px 0px 10px 0px rgba($color1, 0.3);

        @media screen and (max-width: 425px) { padding: 30px; }
        @media screen and (max-width: 375px) { padding: 25px; }
    }
}
