.rubrics {
    padding-bottom: 100px;

    @media screen and (max-width: 1400px) { padding-bottom: 90px; }
    @media screen and (max-width: 1024px) { padding-bottom: 80px; }
    @media screen and (max-width: 768px)  { padding-bottom: 70px; }
}

.rubric {
    position: relative;
    border-bottom: 1px solid rgba($color1, 0.2);
    background: transparent;
    overflow: hidden;
    transition: background 700ms;

    &:first-child { border-top: 1px solid rgba($color1, 0.2); }

    &__header {
        display: block;
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
        cursor: pointer;

        @media screen and (max-width: 1024px) { padding-top: 40px; padding-bottom: 40px; }
        @media screen and (max-width: 600px)  { padding-top: 30px; padding-bottom: 30px; }
    }

    &__header:hover &__title {
        color: $color4;
    }

    &__header:hover &__svg {
        fill: $color4;
    }

    &__title {
        padding-right: 50px;
        font: rem(18)/1.5 $font1b;
        color: $color1;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: color 300ms;

        @media screen and (max-width: 768px) { font-size: rem(16); }
        @media screen and (max-width: 500px) { font-size: rem(15); }
    }

    &__icon {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        right: var(--x-padding-160);
        transform: translateY(-50%);

        @media screen and (max-width: 600px) { width: 34px; height: 34px; }
    }

    &__icon:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        transition: background 300ms, transform 300ms $easeOutCubic;
    }

    &__svg {
        position: absolute;
        width: 12px;
        height: 16px;
        top: 50%;
        left: 50%;
        fill: $color1;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: transform 300ms $easeOutCubic, fill 300ms;
    }

    &__content {
        height: 0;
        will-change: height;
        overflow: hidden;
    }

    &__transition {
        position: relative;
        padding-bottom: 100px;
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 700ms $easeInOutQuart, transform 700ms $easeInOutQuart;

        @media screen and (max-width: 768px) { padding-bottom: 80px; }
    }

    &__transition > :first-child {
        margin-top: 0;
    }

    &__dynamic {
        h3 { @include fontsize(28, 0, 0, 0, 26, 0, 0); }
        h4 { text-transform: unset; @include fontsize(22, 0, 20, 18, 16, 0, 0); }

        table {
            tbody {
                tr{
                    td,th{
                        &.fonce, &.pale {
                            background: #fff;
                        }
                    }
                }

                tr:only-child td:only-child {
                    &.pale {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    &__document, &__gallery {
        @extend .top-margin-50;
    }
}

.rubrics.rubrics--plus {
    .rubric {
        &:not(.rubric__no-image) .rubric__header {
            padding-top: 20px;
            padding-bottom: 20px;

            @media screen and (max-width: 600px) { padding-top: 30px; padding-bottom: 30px; }
        }

        &__flex {
            display: flex;
            align-items: center;
        }

        &__text {
            width: calc(100% - 170px);

            @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
            @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
            @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
            @media screen and (max-width: 600px)  { width: 100%; }
        }

        &__picture {
            width: 100px;
            height: 100px;
            margin-right: 30px;
            overflow: hidden;

            @media screen and (max-width: 1024px) { margin-right: 25px; }
            @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
            @media screen and (max-width: 600px)  { display: none; }
        }

        &__subtitle {
            margin-bottom: 5px;
            padding-right: 50px;
            font: rem(12)/1.5 $font1b;
            color: $color1;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: color 300ms;
        }

        &__header:hover .rubric__subtitle {
            color: $color4;
        }

        &:not(.rubric__no-image) .rubric__transition {
            padding-top: 30px;

            @media screen and (max-width: 1024px) { padding-top: 15px; }
            @media screen and (max-width: 600px)  { padding-top: 0px; }
        }
    }
}


.fancybox-caption__body {
    font-family: $font1;
}
