#page-events {
    .headerBlock .row .content .extra {
        display: flex;
        border-top: none;
        padding-top: 0;

        @media screen and (max-width: 600px) { flex-direction: column; }

        .field {
            position: relative;
            width: 33%;
            margin-bottom: 0;

            @media screen and (max-width: 768px) { width: 100%; }

            // Erreur
            &__error {
                margin-top: 5px;
                font: rem(11)/1 $font1;
                color: $color6;
                width: 150px;
            }

            // Select
            &--select &__label {
                color: #fff;
            }

            &--select .select-label {
                border: 1px solid #000;
                background: #000;
                height: 64px;
                @media screen and (max-width: 600px) { height: 45px; }

                &:hover {
                    .label-inner {
                        color: $color5;

                        &:before {
                            background: url('../images/content/icons/arrow_grey.svg') no-repeat center / contain;
                        }
                    }
                }
            }

            &--select .label-inner {
                display: flex;
                align-items: center;
                height: 100%;
                font: 15px/1.3 $font1;
                color: #fff;
                transition: color 500ms $easeInOutCubic;

                @media screen and (max-width: 768px) { font-size: 13px; }

                &:before {
                    background: url('../images/content/icons/arrow_white.svg') no-repeat center / contain;
                    transition: background 500ms $easeInOutCubic;
                }
            }

            // Select natif
            &--select select {
                width: 100%;
                padding: 0 20px;
                font: 15px/1.3 $font1;
                color: #fff;
                border: 1px solid #000;
                outline: none;
                -webkit-appearance: none;
                border-radius: 0;
                background: url('../images/content/icons/arrow_white.svg') no-repeat calc(100% - 20px) 50% / 12px 12px, #000;

                @media screen and (max-width: 768px) { font-size: 13px; }
            }
        }
        .page-title {
            &__btn-calendar {
                position: relative;
                width: 33%;
                height: 64px;
                margin-bottom: 0;
                padding: 20px 30px;
                @media screen and (max-width: 768px) { width: 100%; }
                @media screen and (max-width: 600px) { height: 45px; padding: 20px; }

                    border: 1px solid #000;
                    display: flex;
                    align-items: center;
                    background-color: #000;
                    margin-left: 20px;
                    @media screen and (max-width: 600px) { margin-left: 0; }

                    &:hover {
                        .label-inner {
                            color: $color5;

                            svg {
                                fill: $color5;
                            }
                        }
                    }

                .label-inner {
                    font: 15px/1.3 $font1;
                    color: #fff;
                    transition: color 500ms $easeInOutCubic;

                    @media screen and (max-width: 768px) { font-size: 13px; }

                    svg {
                        fill: #fff;
                        transition: fill 500ms $easeInOutCubic;
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        top: 50%;
                        right: 30px;
                        transform: translateY(-50%);
                        @media screen and (max-width: 600px) { right: 20px; }
                    }
                }
            }

            &__calendar {
                position: absolute;
                width: 400px;
                top: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;

                @media screen and (max-width: 1024px) { width: 350px; }
                @media screen and (max-width: 700px)  { display: none; }
            }
        }
    }

    .eventsList {
        background: #fff;

        .col24 {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            @media screen and (max-width: 768px) {
                justify-content: center;
            }

            .currentDate {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 30px;

                @media screen and (max-width: 500px) {
                    flex-direction: column-reverse;
                    align-items: flex-start;

                    a {
                        margin-bottom: 20px;
                    }
                }
            }

            .event {
                &:not(.primary) {
                    &:nth-child(2n - 1) {
                        width: calc(50% - 30px);
                        margin-left: 30px;
                    }
                    &:nth-child(2n) {
                        width: calc(50% - 30px);
                        margin-right: 30px;
                    }
                    &:nth-last-child(-n+1) {
                        margin-bottom: 0;
                    }

                    @media screen and (max-width: 768px) {
                        &:nth-child(2n - 1) {
                            width: 100%;
                            margin-left: 0;
                        }
                        &:nth-child(2n) {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    @media screen and (min-width: 769px) {
                        &:nth-last-child(-n+2) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .sectionPagination {
        background: #fff;
    }
}
