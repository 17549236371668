.fileField {
    .uploadBtnWrapper {
        position: relative;
        width: 100%;
        display: inline-block;

        input[type=file] {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        .btnUpload {
            display: block;
            position: relative;
            width: 100%;
            padding: 20px 80px 20px 20px;
            font: rem(21)/1.4 $font1;
            color: $color1;
            border: 1px solid $color1;
            cursor: pointer;
            transition: color 300ms, background 300ms;

            @media screen and (max-width: 768px)  { font-size: rem(18); padding: 15px 45px 15px 15px; }

            &:hover:after { left: 0; right: auto; width: 100%; }

            svg {
                position: absolute;
                width: 20px;
                height: 20px;
                top: 50%;
                right: 20px;
                stroke: $color1;
                transition: stroke 300ms;
                transform: translateY(-50%);

                @media screen and (max-width: 768px) { right: 15px; }
            }
        }

        &:hover .btnUpload { color: $color1; }
    }
}
