#wrapperStickyBtn {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 12;
    opacity: 1;
    visibility: visible;
    transition: 300ms opacity linear 300ms;

    &.fadeOut {
        opacity: 0;
        visibility: hidden;
        transition: 300ms opacity, visibility 0ms linear 300ms;
    }

    .button {
        display: block;
        position: fixed;
        width: auto;
        padding: 40px 50px;
        bottom: 0;
        right: 0;
        font: rem(22)/1 $font2b;
        color: $color2;
        background-color: #000;
        text-align: center;
        transition: color 300ms;
        border: none;
        text-transform: none;

        &.gold{
            color: $color1;
            background-color: $color2;
        }

        &:hover {
            color: #fff;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 40px 0;
        }
    }
}
