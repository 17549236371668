.newsletterField {
    position: relative;
    max-width: 390px;

    input {
        width: 100%;
        padding: 25px 128px 25px 25px;
        font: rem(17)/1 $font1;
        color: #fff;
        border: 1px solid #fff;
        background: transparent;

        @media screen and (max-width: 1300px) { padding: 20px 113px 20px 20px; }
        @media screen and (max-width: 768px)  { padding: 20px 85px 20px 20px; }
        @media screen and (max-width: 425px)  { padding: 20px 93px  20px 15px; }
    }

    a {
        position: absolute;
        width: 77px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        background: #fff;
        overflow: hidden;

        @media screen and (max-width: 768px) { width: 60px; }

        &:hover {
            &:before { right: 0%; }
            svg { stroke: #fff; }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            bottom: 0;
            left: 0;
            background: $color3;
            transition: right 500ms $easeOutQuart;
        }

        svg {
            position: absolute;
            width: 18px;
            height: 18px;
            top: 50%;
            left: 50%;
            stroke: $color1;
            transform: translate(-50%, -50%);
            transition: stroke 500ms;
        }
    }

    ::-webkit-input-placeholder { color: #fff; font-weight: normal; }
    ::-moz-placeholder { color: #fff; font-weight: normal; }
    :-ms-input-placeholder {  color: #fff; font-weight: normal; }
    :-moz-placeholder { color: #fff; font-weight: normal; }
}
