#page-contact {
    .extra {
        display: flex;

        @media screen and (max-width: 600px)  {
            flex-direction: column;
        }

        &.noBorder {
            border: none;

            @media screen and (max-width: 600px)  {
                border-top: 1px solid $color4;
            }
        }

        div:nth-child(1) {
            width: 450px;

            @media screen and (max-width: 800px)  {
                width: 350px;
            }
            @media screen and (max-width: 700px)  {
                width: 325px;
            }
            @media screen and (max-width: 600px)  {
                width: 100%;
                margin-bottom: 40px;
            }

            .address {
                margin-bottom: 20px;
            }

            .phone {
                margin-top: 40px;
            }
            .phone {
                margin-top: 40px;
            }
        }

        div:nth-child(2) {
            @media screen and (max-width: 600px)  {
                display: flex;
                flex-direction: column;
            }

            .social {
                display: flex;
                padding-top: 27px;
                padding-bottom: 7px;
                @media screen and (max-width: 1024px) { padding-top: 21px; }
                @media screen and (max-width: 768px)  { padding-top: 15px; }
                @media screen and (max-width: 600px)  { padding-top: 0; margin-top: 0; flex-direction: row; }

                a {
                    display: flex;
                    align-items: center;
                    width: 50px;
                    height: 50px;

                    svg {
                        width: 50%;
                        height: 50%;
                        fill: $color1;
                    }
                }
            }

            .email {
                display: block;

                @media screen and (max-width: 600px)  {
                    margin-bottom: 15px;
                }
            }

            .subtitle {
                font: 18px/1.5 $font1b;
                color: $color1;

                @media screen and (max-width: 1300px) { font-size: 20px; line-height: 1.75; }
                @media screen and (max-width: 1024px) { font-size: 18px; }
                @media screen and (max-width: 768px)  { font-size: 16px; }
            }

            .note {
                margin-top: 25px;
            }
        }
    }

    .formBlock {
        padding-top: 140px;
        padding-bottom: 140px;
        background: $color1;

        @media screen and (max-width: 1200px) { padding-top: 110px; padding-bottom: 110px; }
        @media screen and (max-width: 1024px) { padding-top: 100px; padding-bottom: 100px; }
        @media screen and (max-width: 768px)  { padding-top: 90px; padding-bottom: 90px; }

        h2, h5 {
            text-align: center;
        }

        h2, h3, p { color: #fff; }
        h5 {
            margin-bottom: 100px;

            @media screen and (max-width: 1024px) { margin-bottom: 75px; }
            @media screen and (max-width: 768px)  { margin-bottom: 50px; }
        }

        input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill,
        textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill,
        select:-webkit-autofill:hover, select:-webkit-autofill:focus { -webkit-box-shadow: 0 0 0 1000px $color1 inset !important; }

        input:-webkit-autofill {
            -webkit-text-fill-color: #fff !important;
        }
    }
}
