///////////////////////
// LES CHOSEN SELECT //
///////////////////////

// CSS GLOBAL S'APPLIQUANT À TOUT LES SELECT
.chosen-container .chosen-results li.highlighted { color: $color1; }
.chosen-container-single .chosen-search { display: none; }
.chosen-disabled .chosen-single { cursor: default; }
select {
    display: block;
    width: 100%;
    opacity: 0;

    &::-ms-expand { display: none; }
}

.chosen-disabled {
    opacity: 0.35 !important;
    cursor: default;
}

.chosen-container {
    position: relative;
    display: inline-block;
    width: 100% !important;
    font-size: 15px;
    vertical-align: middle;
    background: #fff;
    user-select: none;
    z-index: 50;
    text-align: left;

    .chosen-single {
        display: block;
        position: relative;
        width: 100%;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        z-index: 49;
        transition: border 500ms, border-radius 500ms;

        span {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

        }

        div {
            display: block;
            position: absolute;
            width: 40px;
            height: 100%;
            top: 0;
            right: 0;
            transition: transform 500ms;

            b { display: none; }
        }
    }

    .chosen-drop {
        position: absolute;
        width: 100%;
        top: 0;
        border: none;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        z-index: 48;
        transition: padding-top 500ms, opacity 500ms, box-shadow 500ms, visibility 0ms ease 500ms;
    }

    &.chosen-with-drop .chosen-drop {
        top: 0;
        padding-top: 55px;
        visibility: visible;
        opacity: 1;
        box-shadow: 2px 2px 7px rgba(#000,0.1);
        transition: padding 500ms, opacity 500ms, box-shadow 500ms;
    }

    .chosen-results {
        position: relative;
        max-height: 170px;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        li {
            display: none;
            padding: 15px 20px;
            margin: 0;
            font: 15px/20px $font1;
            list-style: none;
            word-wrap: break-word;
            -webkit-touch-callout: none;
            transition: color 300ms;

            &:first-child { padding-top: 25px; }
            &:last-child { padding-bottom: 25px; }
            &.active-result {
                display: list-item;
                cursor: pointer;
                color: $color1;
            }
            &.disabled-result {
                display: list-item;
                color: rgba($color1, 0.4);
                cursor: default;
            }
            &.result-selected {
                font-family: $font1;
                background: rgba(#fff, 0.05) !important;
                cursor: default;
            }
        }
    }
}

.chosen-container-active {
    z-index: 50;

    &.chosen-with-drop .chosen-single div { transform: rotate(-90deg); }
}


// CSS SPÉCIFIQUE À UN TYPE DE SELECT (EX: STYLE1)
.select-field.style1 {
    margin-bottom: 80px;

    &:after {
        height: 56px;
        background: url('../images/content/icons/arrow.svg') no-repeat center center;
        background-size: 8px auto;
    }

    select {
        position: relative;
        display: block;
        padding: 0 45px 0 20px;
        font: 15px/56px $font1;
        height: 56px;
        border: 1px solid #fff;
        opacity: 1;
        color: $color1;
        background: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        outline: none;
        appearance: none;

        @media screen and (max-width: 768px) { font-size: 13px; }
    }

    .chosen-container {
        background: transparent;
        box-shadow: 2px 2px 7px rgba(#000,0.1);

        .chosen-single:not(.chosen-default) { color: $color1; }
        .chosen-single {
            padding: 0 45px 0 20px;
            font: 15px/56px $font1;
            color: $color4;
            border-bottom: 1px solid rgba($color4, 0);
            background: #fff;
            letter-spacing: 0;
            transition: background 300ms, color 300ms, border 300ms;

            div {
                background: url('../images/content/icons/arrow.svg') no-repeat center center;
                background-size: 8px auto;
                display: block;
                top: 0;
                right: -2px;
                transform: rotate(90deg);
                transition: transform 300ms;
            }
        }

        .chosen-default {
            font: 15px/56px $font1;
            color: $color4;
            letter-spacing: 0;
        }

        .chosen-results {
            border-top: 1px solid rgba(#fff, 0.5);
        }

        &.chosen-with-drop {
            .chosen-default, .chosen-single {
                color: $color1 !important;
                background: #fff;
                border-bottom: 1px solid rgba($color1, 0.05);
            }
        }

        .active-result.result-selected { color: $color1 !important; }
        .active-result {
            color: $color1 !important;
            transition: background 300ms;

            &:hover {
                background: rgba($color1, 0.05) !important;
            }
        }
    }
}

select {
    position: relative;
    display: block;
    font: 15px/45px $font1;
    height: 45px;
    border: none;
    border-bottom: 1px solid $color1;
    opacity: 1;
    color: $color1;
    background: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    outline: none;
    appearance: none;

    @media screen and (max-width: 768px) { font-size: 13px; }

    &:focus, &:active {
        outline: none !important;
        border-color:  $color1;
    }
}
select::-ms-expand {
    display: none;
}

.select-field {
    &:after {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 56px;
        content: "";
        cursor: pointer;
        pointer-events: none;
        background: url("../images/content/icons/arrow_white.svg") no-repeat center center;
        background-size: 8px auto;
        transform: rotate(90deg);
    }

    select {
        width: 100%;
        height: 45px;
        padding: 0px 0;
        border: none;
        border-bottom: 1px solid $color1;
        font: 18px/45px $font1;
        color: #000;
        background: transparent;
        transition: border 300ms ease;
    }

    label {
        position: absolute;
        top: 0px;
        left: 0;
        font: 18px/45px $font1;
        color: $color1;
        cursor: text;
        transition: 300ms $ease;

        span {
            font-size: 15px;
            color: $color2;
            transition: color 300ms, font-size 300ms;
        }
    }

    select:focus + label,
    select.valid + label,
    select.error + label,
    select.notEmpty + label {
        top: -12px;
        font-size: 11px;
        line-height: 12px;
        cursor: default;
        pointer-events: none;

        span {
            font-size: 11px;
            color: $color5;
        }
    }

    select.vide + label {
        top: 0px;
        font: 16px/45px $font1;
    }

    &.white {
        select {
            color: #fff;
            border-bottom-color: $color5;

            &:focus { border-bottom-color: $color2; }
            &.valid { border-bottom-color: $color2; }
            &.error { border-color: #eb3f3f; }
        }

        label { color: #fff; }

        select:focus + label,
        select.valid + label,
        select.error + label,
        select.notEmpty + label {
            color: $color5;
            border-bottom-color: $color2;
        }
    }
}
