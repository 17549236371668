#page-home {
    .bannerBlock {
        --fast-links-height: 130px;

        @media screen and (max-width: 1200px) {
            --fast-links-height: 100px;
        }
        @media screen and (max-width: 1024px) {
            --fast-links-height: 80px;
        }
        @media screen and (max-width: 768px) {
            --fast-links-height: 70px;
        }
    }

    .bannerBlock {
        position: relative;
        height: calc(var(--vh, 1vh) * 100);
        min-height: 750px;
        padding-bottom: calc(var(--fast-links-height) / 2);

        @media screen and (max-width: 1024px) { padding-bottom: var(--fast-links-height) }

        .slick {
            width: 100%;
            min-height: 700px;
            margin-bottom: 0;
            overflow: hidden;

            @media screen and (max-width: 768px) { min-height: 750px; }

            &, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .image { height: 100%; }

            .image {
                position: relative;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                background-attachment: fixed;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba($color1, 0.65);
                }

                @media screen and (max-width: 768px) { height: 100%; background-attachment: scroll; min-height: 750px;}

                .flex {
                    text-align: center;

                    @media screen and (max-width: 1024px) {
                        width: auto;
                        right: 30px;
                        left: 30px;
                    }
                    @media screen and (max-width: 768px) {
                        right: 20px;
                        left: 20px;
                    }

                    .textCenter {
                        display: none;
                        margin-top: 50px;

                        @media screen and (max-width: 768px) { display: block; }
                    }
                }

                h5 {
                    color: #fff;
                }

                h1 {
                    width: 100%;
                    margin: 30px 0;
                    padding: 0px 100px;
                    @include fontsize(85, 0, 80, 70, 50, 45, 0);

                    @media screen and (max-width: 768px) { padding: 0 30px; }
                    @media screen and (max-width: 600px) { padding: 0 25px; }
                }
            }
        }

        .slickDots {
            position: absolute;
            top: 50%;
            left: 50px;
            z-index: 1;
            transform: translateY(-50%);

            @media screen and (max-width: 768px) {
                top: auto;
                bottom: calc(var(--fast-links-height) + 30px);
                left: 50%;
                transform: translateX(-50%);
            }

            ul {
                position: relative;
                bottom: 0;
                text-align: left;

                li {
                    position: relative;
                    display: block;
                    width: 8px;
                    height: 8px;
                    margin: 0 0 20px;

                    @media screen and (max-width: 768px) {
                        display: inline-block;
                        margin-right: 20px;
                        margin-bottom: 0;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    button {
                        position: absolute;
                        width: inherit;
                        height: inherit;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        padding: 0;
                        background: rgba(#fff, 0.5);
                        border-radius: 50%;
                        border: 0.5px solid rgba(#fff, 0);
                        transition: background 300ms, border-color 300ms, transform 300ms $ease;

                        &:before { display: none; }
                    }

                    &.slick-active {
                        button {
                            transform: scale(1.5);
                            background: rgba(#fff, 0);
                            border-color: #fff;
                        }
                    }
                }
            }
        }

        #scrolldown {
            display: flex;
            position: absolute;
            width: 270px;
            height: 80px;
            right: -55px;
            padding-left: 100px;
            bottom: 50%;
            font: 11px/80px $font1b;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 2px;
            white-space: nowrap;
            transform: rotate(-90deg) translateY(50%);
            z-index: 1;

            @media screen and (max-width: 768px) { display: none; }

            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(calc(-50% - 0.5px));
            }

            &:before {
                left: 65px;
                transition: left 300ms;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                border: 1px solid #fff;
            }

            &:after  {
                left: 0px;
                width: 70px;
                height: 1px;
                background: $color4;
                opacity: 0.4;
            }

            &:hover {
                &:before { left: 50px; }
            }
        }

        .reserve {
            position: absolute;
            right: 0;
            bottom: 0;

            a {
                padding: 50px 60px;
                background: #000;
                font: 22px/1 $font2b;
                color: $color2;
                transition: color 300ms;

                @media screen and (max-width: 1200px) { padding: 40px 50px; }
                @media screen and (max-width: 1100px) { padding: 30px 40px; }
                @media screen and (max-width: 900px)  { display: none; }

                &:hover { color: #fff; }
            }
        }
    }

    .fastLinks {
        position: relative;
        background-color: $color3;
        height: calc(var(--fast-links-height) / 2);

        @media screen and (max-width: 1024px) { height: auto; }

        .list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: var(--fast-links-height);
            transform: translateY(-50%);
            background-color: #fff;
            margin: 0 10%;
            padding: 0 80px;

            @media screen and (max-width: 1600px) { padding: 0 60px; margin: 0 8%; }
            @media screen and (max-width: 1400px) { padding: 0 50px; margin: 0 6%; }
            @media screen and (max-width: 1200px) { padding: 0 40px; margin: 0 30px; }
            @media screen and (max-width: 1024px) {
                padding: 0;
                margin: 0;
                font-size: 0;
                position: relative;
                display: inline-block;
                width: 100%;
                overflow-x: scroll;
                overflow-y: hidden;
                white-space: nowrap;
                transition: all 0.2s;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                scrollbar-width: none;
                overflow: -moz-scrollbars-none;
                -ms-overflow-style: none;
                transform: none;

                &::-webkit-scrollbar { display: none; }
            }

            @media screen and (max-width: 900px)  { padding: 0; }


            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                @media screen and (max-width: 1024px) { padding-right: 50px; }
                @media screen and (max-width: 768px)  { padding-right: 30px; }
                @media screen and (max-width: 600px)  { padding-right: 25px; }

                &:first-child {
                    @media screen and (max-width: 1024px) { padding-left: 50px; }
                    @media screen and (max-width: 768px)  { padding-left: 30px; }
                    @media screen and (max-width: 600px)  { padding-left: 25px; }
                }

                &:last-child {
                    @media screen and (max-width: 1024px) { padding-right: 50px; }
                    @media screen and (max-width: 768px)  { padding-right: 30px; }
                    @media screen and (max-width: 600px)  { padding-right: 25px; }
                }

                h5 {
                    font-size: rem(12);
                    color: $color1;
                    text-align: center;
                    transition: color 300ms;

                    @media screen and (max-width: 900px)  { font-size: rem(11); }
                }

                &:hover h5 {
                    color: $color4;
                }
            }
        }
    }

    .eventsList {
        background-color: $color3;

        .col24 {
            display: flex;
            flex-wrap: wrap;

            .event {
                &:not(.primary) {
                    &:nth-child(2n - 1) {
                        width: calc(50% - 30px);
                        margin-left: 30px;

                        @media screen and (max-width: 1200px) {
                            width: calc(50% - 20px);
                            margin-left: 20px;
                        }
                        @media screen and (max-width: 1200px) {
                            width: calc(50% - 10px);
                            margin-left: 10px;
                        }
                    }
                    &:nth-child(2n) {
                        width: calc(50% - 30px);
                        margin-right: 30px;

                        @media screen and (max-width: 1200px) {
                            width: calc(50% - 20px);
                            margin-right: 20px;
                        }
                        @media screen and (max-width: 1200px) {
                            width: calc(50% - 10px);
                            margin-right: 10px;
                        }
                    }
                    &:nth-last-child(-n+1) {
                        margin-bottom: 0;
                    }

                    @media screen and (max-width: 768px) {
                        &:nth-child(2n - 1) {
                            width: 100%;
                            margin-left: 0;
                        }
                        &:nth-child(2n) {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    @media screen and (min-width: 769px) {
                        &:nth-last-child(-n+2) {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .textCenter {
                width: 100%;
                margin-top: 75px;

                @media screen and (max-width: 1024px) { margin-top: 50px; }
                @media screen and (max-width: 768px) { margin-top: 30px; }
            }
        }
    }

    .homeVideo {
        height: 100vh;
        min-height: 500px;
        background-color: $color1;

        .content {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-repeat: no-repeat;
            background-position: right;
            background-size: cover;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba($color1, 0.5);
            }

            video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            div {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
                text-align: center;
                z-index: 1;
                padding: 0 30px;

                @media screen and (max-width: 600px) { padding: 0 25px; }

                svg {
                    width: 67px;
                    height: 67px;
                    fill: $color4;
                }

                h2 {
                    color: $color2;
                    margin-top: 30px;
                }
            }

            a {
                position: relative;
                font: 12px/1.5 $font1b;
                color: #fff;
                text-transform: uppercase;
                z-index: 1;
                transition: color 300ms;

                &:hover { color: $color4; }
            }
        }
    }

    .chambersBlock {
        position: relative;
        background: $color3;
        min-height: 100vh;

        @media screen and (max-width: 1024px) { padding-right: 0; }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 420px;
            height: 100%;
            left: 0;
            background: #000;

            @media screen and (max-width: 1200px) { width: 300px; }
            @media screen and (max-width: 900px)  { width: 200px; }
            @media screen and (max-width: 768px)  { width: 100px; }
            @media screen and (max-width: 500px)  { width: 50px; }
            @media screen and (max-width: 400px)  { display: none; }
        }

        .titleSection {
            margin-bottom: 75px;

            @media screen and (max-width: 1024px) { margin-bottom: 50px; }
            @media screen and (max-width: 768px)  { margin-bottom: 30px; }
        }

        .slickNav {
            position: absolute;
            bottom: 420px;
            left: 10px;
            z-index: 1;

            @media screen and (max-width: 768px) {
                position: relative;
                padding-right: 20px;
                margin-bottom: 40px;
                bottom: 0;
                left: 0;
                text-align: center;
            }

            .navigation {
                transform: rotate(-90deg);

                @media screen and (max-width: 768px) { transform: none; }

                a {
                    font: 12px/1.5 $font1b;
                    color: $color2;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    transition: color 300ms;

                    @media screen and (max-width: 768px) {
                        color: $color1;
                    }

                    &:hover {
                        color: #fff;

                        @media screen and (max-width: 768px) {
                            color: $color4;
                        }
                    }

                    &:first-child {
                        margin-right: 40px;

                        @media screen and (max-width: 768px) { margin-right: 20px; }
                    }
                }
            }
            .pagination {
                position: absolute;
                top: 170px;
                left: 50%;
                transform: translateX(-50%);

                @media screen and (max-width: 768px) {
                    position: relative;
                    width: 100%;
                    margin-top: 10px;
                    top: 0;
                    left: 0;
                    transform: none;

                    span {
                        color: $color1;
                    }
                }
            }
        }

        .slick {
            margin-left: 145px;

            @media screen and (max-width: 768px) { margin-left: 0; }

            .slick-list {
                padding-right: 100px;

                @media screen and (max-width: 1700px) { padding-right: 350px; }
                @media screen and (max-width: 1500px) { padding-right: 250px; }
                @media screen and (max-width: 1400px) { padding-right: 150px; }
                @media screen and (max-width: 1300px) { padding-right: 50px;  }
                @media screen and (max-width: 1200px) { padding-right: 380px; }
                @media screen and (max-width: 1100px) { padding-right: 250px; }
                @media screen and (max-width: 900px)  { padding-right: 200px; }
                @media screen and (max-width: 800px)  { padding-right: 100px; }
                @media screen and (max-width: 768px)  { padding-right: 250px; }
                @media screen and (max-width: 700px)  { padding-right: 150px; }
                @media screen and (max-width: 600px)  { padding-right: 50px;  }
                @media screen and (max-width: 500px)  { padding-right: 0px;   }
            }

            .slick-slide {
                @media screen and (max-width: 500px) { padding-right: 20px; }
            }

            .blockStyle2 {
                @media screen and (max-width: 500px) { max-width: 100%; }
            }
        }

        .textCenter {
            @media screen and (max-width: 1024px) { padding-right: 30px; }
            @media screen and (max-width: 768px)  { padding-right: 20px; }

            .btn {
                margin-top: 75px;

                @media screen and (max-width: 1024px) { margin-top: 50px; }
                @media screen and (max-width: 768px)  { margin-top: 30px; }
            }
        }
    }

    .packagesBlock {
        padding-top: 100px;
        padding-bottom: 100px;

        @media screen and (max-width: 1400px) { padding-top: 90px; padding-bottom: 90px; }
        @media screen and (max-width: 1024px) { padding-top: 80px; padding-bottom: 80px; }
        @media screen and (max-width: 768px)  { padding-top: 70px; padding-bottom: 70px; }
    }

    .home-text {
        padding: 80px 15% 0;
        background-color: #f0f0f1;

        p {
            line-height: 1.8;
            color: #4d5156;
            font-size: 22px;
            text-align: center;
        }
    }
}
