// VISUEL DES TRANSITIONS DE PAGE ET DU CHARGEMENT
// La barre de chargement
#loaderbar {
    position: absolute;
    width: 0%;
    height: 5px;
    top: 0;
    z-index: 999999;
    background-color: #fff;
    transition: width 100ms;
}


// La transition entre les pages
#page-transition, #landing {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 9998;
    background: $color1;
    visibility: hidden;
    transition: opacity 600ms, visibility 0ms ease 600ms;

    svg {
        position: absolute;
        width: 274px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #fff;
    }
}

#page-transition { background: #fff; }
#landing { background: $color1; }


// Lors d'un changement de page, on enlève certain élément à l'écran avec une animation
.transition, {
    #page-transition {
        opacity: 1;
        visibility: visible;
        transition: opacity 600ms;
    }
}

.chargement {
    #landing {
        opacity: 1;
        visibility: visible;
        transition: opacity 600ms;
    }
}
