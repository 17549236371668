#overlay-menu {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden;
    visibility: hidden;
    z-index: 101;
    pointer-events: none;
    transition: visibility 0ms ease 700ms, opacity 300ms;

    .wrapper {
        display: flex;
        position: fixed;
        top: -100%; right: 0; bottom: 100%; left: 0;
        background: #000;
        align-items: center;
        pointer-events: all;
        z-index: 11;
        transition: top 600ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms, bottom 600ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;

        .headerBackground {
            position: absolute;
            top: 0;
            left: 0;
            right: 75%;
            height: 105px;
            background-color: #000;
            z-index: 100;

            @media screen and (max-width: 1800px) { right: calc(100% - 27%); }
            @media screen and (max-width: 1600px) { right: calc(100% - 30%); }
            @media screen and (max-width: 1400px) { right: calc(100% - 35%); }
            @media screen and (max-width: 1200px) { right: calc(100% - 45%); }
            @media screen and (max-width: 768px)  { right: calc(100% - 50%); }
        }

        .linksWrapper {
            width: 25%;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding-top: 200px;
            padding-left: 130px;

            overflow-x: hidden;
            overflow-y: auto;

            scrollbar-width: none;

            &::-webkit-scrollbar { display: none; }

            @media screen and (max-height: 900px) { padding-top: 175px; }
            @media screen and (max-height: 800px) { padding-top: 155px; }

            @media screen and (max-width: 1800px) { width: 27%; }
            @media screen and (max-width: 1600px) { width: 30%; }
            @media screen and (max-width: 1400px) { width: 35%; }
            @media screen and (max-width: 1200px) { width: 45%; }
            @media screen and (max-width: 1024px) { padding-left: 80px; }
            @media screen and (max-width: 768px) { width: 50%; padding-left: 70px; }
            @media screen and (max-width: 700px) { width: 100%; }

            & > ul, .contact {
                float: left;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-100px);
                transition: opacity 300ms, transform 300ms, visibility 0ms ease 300ms;

                li.title p {
                    margin-bottom: 50px;
                    font: rem(23)/1 $font2b;
                    color: $color2;

                    @media screen and (max-width: 900px) { margin-bottom: 30px; }
                }
            }

            .primary {
                margin-bottom: 60px;

                @media screen and (max-width: 900px)  {
                    margin-bottom: 50px;
                }

                li {
                    &:not(:last-child) {
                        margin-bottom: 15px;

                        @media screen and (max-width: 900px) { margin-bottom: 10px; }
                    }


                    a {
                        color: $color4;
                        transition: color 300ms;
                        font-size: rem(22);

                        &:hover, &:active { color: $color2; }
                    }
                }
            }

            .secondary {
                margin-bottom: 50px;
                li {
                    &:not(:last-child) {
                        margin-bottom: 15px;

                        @media screen and (max-width: 900px) { margin-bottom: 10px; }
                    }

                    // &:nth-last-child(2) {
                    //     margin-bottom: 30px;
                    //     @media screen and (max-width: 900px) { margin-bottom: 25px; }
                    // }

                    a {
                        font: rem(15)/1 $font1l;
                        color: $color4;
                        transition: color 300ms;

                        &:hover { color: #fff; }
                    }

                    &.separate {
                        position: relative;
                        padding-bottom: 60px;
                        margin-bottom: 0;

                        &:after {
                            content: '';
                            position: absolute;
                            width: 40px;
                            height: 1px;
                            left: 0;
                            bottom: 30px;
                            background: $color2;

                            @media screen and (max-width: 900px) { left: 50%; transform: translateX(-50%); }
                        }
                    }

                    &.margin {
                        margin-bottom: 115px;

                        @media screen and (max-width: 900px) { margin-bottom: 50px; }
                        @media screen and (max-width: 700px) { margin-bottom: 20px; }
                    }
                }
            }

            .contact {
                width: 100%;

                @media screen and (min-width: 700px)  { display: none; }

                p, .email {
                    margin-bottom: 5px;
                    font: rem(15)/1.5 $font1;
                    color: $color4;
                }

                a {
                    position: relative;
                    padding-bottom: 5px;
                    font: rem(15)/1 $font1l;
                    color: #fff;
                    transition: color 300ms;

                    &:hover { color: $color2; }
                }

                &:last-child, &:nth-last-child(2) {

                    a {
                        font: rem(12)/1 $font1b;
                        color: $color4;
                        text-transform: uppercase;
                        transition: color 300ms;

                        &:hover { color: #fff; }
                    }
                }
            }
        }

        .moreInfos {
            width: 75%;
            height: 100%;

            @media screen and (max-width: 1800px) { width: calc(100% - 27%); }
            @media screen and (max-width: 1600px) { width: calc(100% - 30%); }
            @media screen and (max-width: 1400px) { width: calc(100% - 35%); }
            @media screen and (max-width: 1200px) { width: calc(100% - 45%); }
            @media screen and (max-width: 768px) { width: calc(100% - 50%); }
            @media screen and (max-width: 700px) { display: none; }

            .lazyBackground {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: right;
                background-size: cover;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($color1, 0.85);
                }

                .container {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    padding-top: 200px;
                    padding-bottom: 165px;

                    @media screen and (max-height: 900px) { padding-top: 175px; padding-bottom: 100px; }
                    @media screen and (max-height: 800px) { padding-top: 155px; padding-bottom: 50px; }

                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        z-index: 1;
                        width: 100%;

                        li {
                            &:not(:last-child) {
                                margin-bottom: 15px;

                                @media screen and (max-width: 900px) { margin-bottom: 10px; }
                            }

                            a {
                                color: $color4;
                                transition: color 300ms;

                                &:hover, &:active { color: $color2; }
                            }
                        }

                        .contact {
                            width: 100%;

                            @media screen and (max-height: 700px) { display: none; }

                            p, .email {
                                margin-bottom: 5px;
                                font: rem(15)/1.5 $font1;
                                color: $color4;
                            }

                            a {
                                position: relative;
                                padding-bottom: 5px;
                                font: rem(15)/1 $font1l;
                                color: #fff;
                                transition: color 300ms;

                                &:hover { color: $color2; }
                            }

                            &:last-child, &:nth-last-child(2) {

                                a {
                                    font: rem(12)/1 $font1b;
                                    color: $color4;
                                    text-transform: uppercase;
                                    transition: color 300ms;

                                    &:hover { color: #fff; }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .showOverlayMenu {
        #overlay {
            background: rgba(#000, 0.3);
            visibility: visible;
            z-index: 100;
            transition: background 700ms $ease;
        }

        #page-shop header > .hamburger .box,
        #page-contact header > .hamburger .box,
        #page-comingSoon header > .hamburger .box {
            .inner, &:before, &:after { background: #fff; }
        }

        header .newMenu {
            .logo svg {
                fill: #fff;
                transition: fill 600ms $ease;
            }
        }
        header .sideNav {
            .sideNavOverlay {
                bottom: 0%;

                a {
                    opacity: 1;
                    visibility: visible;
                    transition: color 300ms, opacity 300ms linear 500ms;
                }
            }
        }

        #overlay-menu {
            overflow-y: auto;
            visibility: visible;
            transition: right 500ms ease;

            &::-webkit-scrollbar { display: none; }

            .wrapper {
                top: 0%; bottom: 0%;

                @media screen and (max-width: 768px) { left: 0; }

                .linksWrapper {
                    & > ul, .contact {
                        opacity: 1;
                        visibility: visible;
                        transform: none;
                        transition: opacity 700ms linear 500ms, transform 700ms $ease 500ms;
                    }
                }
            }
        }
    }
}
