.radioField {
    label {
        display: inline-block;
        position: relative;
        padding-left: 50px;
        margin: 0 50px 20px 0;
        font: rem(21)/1.5 $font1;
        color: $color1;
        cursor: pointer;
        user-select: none;

        @media screen and (max-width: 768px) { font-size: rem(18); margin: 0 30px 15px 0; padding-left: 40px; }

        input { position: absolute; opacity: 0; cursor: pointer; }

        span {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 4px;
            left: 0;
            border: 1px solid $color1;
            border-radius: 50%;
            transition: background 225ms, border-color 225ms;

            @media screen and (max-width: 768px) { top: 3px; }

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                width: 8px;
                height: 8px;
                top: 50%;
                background: #fff;
                border-radius: 50%;
                transform: translate(-50%, -50%) scale(0);
                transition: transform 225ms;
            }
        }

        input:checked ~ span { background: $color3; border-color: $color3; }
        input:checked ~ span:before { transform: translate(-50%, -50%) scale(1); }
    }
}
.radioWrapper .radioField { display: inline-block; height: 30px; label { margin-bottom: 0; }}
