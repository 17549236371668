/*
|--------------------------------------------------------------------------
| COMMON STYLES
|--------------------------------------------------------------------------
*/
@mixin huge-title() { // H1
    font-family: $font2b;
    line-height: 1.05;
    color: $color2;
    // $base, 1440, 1024, 768, 425, 375, 320
    @include fontsize(80, 0, 75, 65, 45, 40, 0);

    @media screen and (max-width: 1300px) { line-height: 1.1; }
    @media screen and (max-height: 750px) { line-height: 1.1; }
    @media screen and (max-width: 500px) { font-size: rem(50); }
}

@mixin large-title() { // H2
    font-family: $font2b;
    line-height: 1.3;
    color: $color1;
    // $base, 1440, 1024, 768, 425, 375, 320
    @include fontsize(50, 50, 45, 40, 35, 35, 30);
}

@mixin medium-title() { // H3
    font-family: $font2b;
    line-height: 1.3;
    color: $color1;
    // $base, 1440, 1024, 768, 425, 375, 320
    @include fontsize(35, 0, 30, 28, 26, 0, 0);
}

@mixin small-title() { // H4
    font-family: $font2b;
    line-height: 1.3;
    color: $color1;
    // $base, 1440, 1024, 768, 425, 375, 320
    @include fontsize(22, 0, 20, 18, 16, 0, 0);
}

@mixin tiny-title() { // H5
    font-family: $font1b;
    line-height: 1.3;
    color: $color2;
    text-transform: uppercase;
    letter-spacing: 2px;
    // $base, 1440, 1024, 768, 425, 375, 320
    @include fontsize(22, 0, 20, 18, 16, 0, 0);
}

@mixin paragraph() {
    font-family: $font1l;
    line-height: 1.6;
    color: $color5;
    // $base, 1440, 1024, 768, 425, 375, 320
    @include fontsize(18, 0, 0, 16, 0, 0, 0);

    &.small { font-size: rem(15); line-height: 1.2; }
}



/*
|--------------------------------------------------------------------------
| FUNCTIONS
|--------------------------------------------------------------------------
*/
// Converti les pixels en Rem
@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem;
}


// Détermine le ratio d'un image
@function ratio($width, $height) {
    @return #{$height/$width * 100%};
}


// Créer une font responsive
@mixin responsiveFontSize($maxSize, $minSize, $maxViewport: 1440, $minViewport: 320) {
    $maxViewportPx: $maxViewport + px;
    $minViewportPx: $minViewport + px;

    $minSizeRem:     ($minSize     / $browser-context) + rem;
    $minViewportRem: ($minViewport / $browser-context) + rem;

    @media screen and (min-width: $maxViewportPx) {
        font-size: rem($maxSize);
    }

    @media screen and (min-width: $minViewportPx) and (max-width: $maxViewportPx) {
        font-size: calc(#{$minSizeRem} + (#{$maxSize} - #{$minSize}) * ((100vw - #{$minViewportRem}) / (#{$maxViewport} - #{$minViewport})));
    }

    @media screen and (max-width: $minViewportPx) {
        font-size: rem($minSize);
    }
}


// Créer une font responsive selon des valeurs définies
@mixin fontsize($base, $fs1440: 0, $fs1024: 0, $fs768: 0, $fs425: 0, $fs375: 0, $fs320: 0) {
    font-size: rem($base);
    @if $fs1440 != 0 { @media screen and (max-width: 1440px) { font-size: rem($fs1440); }}
    @if $fs1024 != 0 { @media screen and (max-width: 1024px) { font-size: rem($fs1024); }}
    @if $fs768 != 0  { @media screen and (max-width: 768px)  { font-size: rem($fs768);  }}
    @if $fs425 != 0  { @media screen and (max-width: 425px)  { font-size: rem($fs425);  }}
    @if $fs375 != 0  { @media screen and (max-width: 375px)  { font-size: rem($fs375);  }}
    @if $fs320 != 0  { @media screen and (max-width: 320px)  { font-size: rem($fs320);  }}
}
