.field {
    position: relative;
    margin-bottom: 60px;

    @media screen and (max-width: 1024px) { margin-bottom: 50px; }
    @media screen and (max-width: 768px) { margin-bottom: 40px; }

    // Erreur
    &__error {
        margin-top: 5px;
        font: rem(13)/1 $font1;
        color: #eb3f3f;
        width: 150px;
    }

    // Input textarea et newsletter
    &--input &__input, &--textarea &__input, &--newsletter &__input {
        width: 100%;
        height: 32px;
        padding: 0 0 5px;
        font: rem(18)/1.3 $font1;
        color: $color1;
        background: transparent;
        border: none;
        border-bottom: 1px solid $color1;
        transition: border 300ms;
    }

    &--input &__label, &--textarea &__label, &--newsletter &__label {
        position: absolute;
        top: 0px;
        left: 0;
        pointer-events: none;
        font: rem(18)/1.3 $font1;
        color: $color1;
        transition: font-size 300ms, top 300ms $easeOutCubic;
    }

    &--input &__input:focus ~ &__label, &--input[data-inputvalue]:not([data-inputvalue=""]) &__label,
    &--textarea &__input:focus ~ &__label, &--textarea[data-textareavalue]:not([data-textareavalue=""]) &__label,
    &--newsletter &__input:focus ~ &__label, &--newsletter[data-inputvalue]:not([data-inputvalue=""]) &__label {
        font-size: rem(12);
        top: -20px;
    }


    // Textarea
    &--textarea &__input {
        box-shadow: none;
        outline: none;
        resize: none;
        overflow: hidden;
    }


    // Checkbox et radio
    &--checkbox &__label, &--radio &__label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    &--checkbox-inline &__label, &--radio-inline &__label {
        display: inline-block;
        margin: 0 30px 20px 0;
    }

    &--checkbox &__text, &--radio &__text {
        font: rem(18)/1.3 $font1;
        color: $color1;
        user-select: none;
    }

    &--checkbox &__input, &--radio &__input { position: absolute; opacity: 0; cursor: pointer; }
    &--checkbox &__input:checked ~ &__box, &--radio &__input:checked ~ &__box { background: $color5; }
    &--checkbox &__input:checked ~ &__box:before, &--radio &__input:checked ~ &__box:before { transform: translate(-50%, -50%) scale(1); }

    &--checkbox &__box, &--radio &__box {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 2px;
        left: 0;
        background: $color3;
        transition: background 225ms;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms $easeOutCubic;
        }
    }

    // Checkbox
    &--checkbox &__box {
        &:before {
            width: 12px;
            height: 12px;
            background: url('../images/content/icons/crochet.svg') no-repeat center / contain;
        }
    }

    &--checkbox.white &__text {
        color: white;
    }

    &--checkbox &__link {
        span {
            padding-bottom: 3px;
            box-shadow: inset 0 -0.1em 0 0 rgba($color1, 0.3);
            transition: box-shadow 300ms var(--out-cubic);
            line-height: 1.5em;
            color: $color1;

            &:hover {
                box-shadow: inset 0 -0.1em 0 0 $color1;
            }
        }
    }

    &--checkbox.white &__link {
        span {
            padding-bottom: 3px;
            box-shadow: inset 0 -0.1em 0 0 rgba($color2, 0.3);
            color: $color2;

            &:hover {
                box-shadow: inset 0 -0.1em 0 0 $color2;
            }
        }
    }


    // Radio
    &--radio &__box {
        border-radius: 50%;

        &:before {
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
        }
    }


    // File
    &--file &__input { width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1; }

    &--file &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: 0 60px 0 0;
        color: $color1;
        border: none;
        border-bottom: 1px solid $color1;
        cursor: pointer;
        transition: color 300ms, background 300ms;

        &:hover {
            color: $color4;

            .field__svg { fill: $color4; }
        }
    }

    &--file &__text {
        font: 18px/45px $font1;
        color: $color1;

        span {
            font-size: 15px;
            color: $color2;
            transition: color 300ms, font-size 300ms;
        }
    }

    &--file &__svg {
        position: absolute;
        width: 14px;
        height: 16px;
        top: 16px;
        right: 5px;
        fill: $color1;
        transition: fill 300ms, opacity 300ms;
    }

    &--file &__x {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 16px;
        right: 5px;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transition: opacity 300ms, visibility 0ms ease 300ms;

        &:hover {
            &:before, &:after { background: $color4; }
        }

        &:before, &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: $color1;
            transition: background 300ms;

            @media screen and (max-width: 600px) { width: 20px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    }

    &--file[data-file]:not([data-file=""]) &__label { pointer-events: none; }
    &--file[data-file]:not([data-file=""]) &__svg   { opacity: 0; }
    &--file[data-file]:not([data-file=""]) &__x     { opacity: 1; visibility: visible; pointer-events: all; }


    // Select
    &--select &__label {
        display: block;
        font: 15px/1.3 $font1;
        color: $color1;
        padding-bottom: 10px;

        @media screen and (max-width: 768px) { font-size: 13px; }
    }

    &--select .tail-select {
        position: relative;
        z-index: 1;
    }

    &--select .select-label {
        padding: 20px 30px;
        border: 1px solid $color1;
        transition: background 500ms $easeInOutCubic, border-color 500ms $easeInOutCubic;
        cursor: pointer;

        &:hover { background: #fff; }
    }

    &--select .label-inner {
        display: block;
        position: relative;
        font: 15px/1.3 $font1;
        color: $color1;

        @media screen and (max-width: 768px) { font-size: 13px; }

        &:before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(90deg);
            background: url('../images/content/icons/arrow.svg') no-repeat center / contain;
            transition: transform 500ms $easeInOutCubic;
        }
    }

    &--select .select-dropdown {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        opacity: 0;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba($color1, 0.3);
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition: top 500ms $easeInOutCubic, opacity 500ms $easeInOutCubic, visibility 0ms ease 500ms;
    }

    &--select .dropdown-inner {
        overflow: auto;
        max-height: 195px;
    }

    &--select .dropdown-option {
        font: 15px/1.3 $font1;
        color: $color1;
        padding: 20px 30px;
        background: #fff;
        cursor: pointer;
        transition: background 500ms $easeInOutCubic;

        @media screen and (max-width: 768px) { font-size: 13px; }

        &:hover { background: $color3; }

        &.selected { background: $color3; }
    }

    // Select natif
    &--select select {
        width: 100%;
        padding: 20px;
        font: 15px/1.3 $font1;
        color: $color1;
        border: 1px solid $color1;
        background: transparent;
        outline: none;
        -webkit-appearance: none;
        background: url('../images/content/icons/arrow.svg') no-repeat calc(100% - 20px) 50% / 12px 12px;

        @media screen and (max-width: 768px) { font-size: 13px; }
    }

    select::-ms-expand { display: none; }


    // Recherche
    &--search {
        text-align: right;
    }

    &--search &__input {
        width: 100%;
        height: 70px;
        padding: 0 60px 0 0;
        font: rem(40)/1.3 $font1;
        color: #fff;
        text-align: left;
        background: transparent;
        border: none;
        border-bottom: 1px solid #fff;
        transition: border 300ms;

        @media screen and (max-width: 1100px) { height: 70px; font-size: rem(30); }
        @media screen and (max-width: 600px)  { height: 50px; padding-right: 35px; font-size: rem(23); }
        @media screen and (max-width: 500px)  { height: 50px; font-size: rem(21); }
        @media screen and (max-width: 425px)  { height: 50px; font-size: rem(19); }
    }

    &--search[data-inputvalue]:not([data-inputvalue=""]) &__x {
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms;
    }

    &--search {
        @media screen and (max-width: 600px) { margin-bottom: 0; }
        ::-webkit-input-placeholder { color: rgba(#fff, 0.2); font-weight: normal; }
        ::-moz-placeholder { color: rgba(#fff, 0.2); font-weight: normal; }
        :-ms-input-placeholder { color: rgba(#fff, 0.2); font-weight: normal; }
        :-moz-placeholder { color: rgba(#fff, 0.2); font-weight: normal; }
    }

    &--search &__x {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 50%;
        right: 180px;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        transition: opacity 300ms, visibility 0ms ease 300ms;

        @media screen and (max-width: 600px) {
            top: 13px;
            right: 0;
            transform: none;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            width: 25px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: #fff;

            @media screen and (max-width: 600px) { width: 20px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    }

    &--search &__svg {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 50%;
        left: 0;
        stroke: #fff;
        transform: translateY(-50%);

        @media screen and (max-width: 1100px) { width: 24px; height: 24px; }
        @media screen and (max-width: 600px)  {
            width: 18px;
            height: 18px;
            top: 16px;
            transform: none;
        }
    }

    &--search &__btn {
        position: absolute;
        padding: 25px 35px;
        top: 50%;
        right: 0;
        stroke: #fff;
        transform: translateY(-50%);

        @media screen and (max-width: 600px) {
            position: relative;
            width: 100%;
            margin-top: 20px;
            top: 0;
            text-align: center;
            transform: none;
        }
    }


    // Newsletter
    &--newsletter { margin-bottom: 0; }
    &--newsletter &__input { height: 47px; padding-right: 60px; }
    &--newsletter &__label { top: 7px; }
    &--newsletter &__input:focus ~ &__label, &--newsletter[data-inputvalue]:not([data-inputvalue=""]) &__label { top: -10px; }

    &--newsletter &__btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $color5;
            border-radius: 50%;
            transition: transform 300ms $easeOutCubic;
            z-index: -1;
        }

        &:hover:before { transform: scale(1.1); }
    }

    &--newsletter &__svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        stroke: #fff;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
}

// Select dans le formulaire contact-form
.contact-form .field {
    margin-bottom: 80px;

    @media screen and (max-width: 1024px) { margin-bottom: 65px; }
    @media screen and (max-width: 600px) { margin-bottom: 45px; }

    &--select .tail-select {
        transition: z-index 0ms 500ms;
    }

    &--select .tail-select.active {
        z-index: 2;
        transition: z-index 0ms 0ms;
    }

    &--select &__label {
        color: $color1;
    }

    &--select.white &__label {
        color: white;
    }

    &--select .select-label {
        height: 45px;
        padding: 0;
        background-color: white;
        border: none;
        border-bottom: 1px solid $color1;
        transition: border 300ms;
    }

    &--select.white .select-label {
        background-color: $color1;
        border-bottom: 1px solid $color5;
    }

    &--select .label-inner {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 0 5px;
        font: rem(18)/1.3 $font1;
        color: $color1;

        &:before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(90deg);
            background: url('../images/content/icons/arrow.svg') no-repeat center / contain;
            transition: transform 500ms $easeInOutCubic, background 500ms;
        }
    }

    &--select.white .label-inner {
        color: white;

        &::before {
            background: url('../images/content/icons/arrow_white.svg') no-repeat center / contain;
        }
    }

    &--select .select-dropdown {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        opacity: 0;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba($color1, 0.3);
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition: top 500ms $easeInOutCubic, opacity 500ms $easeInOutCubic, visibility 0ms ease 500ms;
    }

    &--select .dropdown-inner {
        overflow: auto;
        max-height: 195px;
    }

    &--select .dropdown-option {
        font: 15px/1.3 $font1;
        color: $color1;
        padding: 20px 30px;
        background: #fff;
        cursor: pointer;
        transition: background 500ms $easeInOutCubic;

        @media screen and (max-width: 768px) { font-size: 13px; }

        &:hover { background: $color3; }

        &.selected { background: $color3; }
    }

    // Select natif
    &--select select {
        width: 100%;
        padding: 0 0 5px;
        font: rem(18)/1.3 $font1;
        color: $color1;
        border: none;
        border-bottom: 1px solid $color1;
        background: transparent;
        outline: none;
        -webkit-appearance: none;
        background: url('../images/content/icons/arrow_select.svg') no-repeat 100% 50% / 12px 12px;
    }

    &--select.white select {
        color: white;
        border-bottom: 1px solid $color5;
        background: url('../images/content/icons/arrow_select_white.svg') no-repeat 100% 50% / 12px 12px;
    }

    select::-ms-expand { display: none; }
}
