.overlay-menu {
    position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	overflow: hidden;
	visibility: hidden;
	z-index: 101;
    pointer-events: none;
    transition: visibility 0ms ease 700ms, opacity 300ms;

    .wrapper {
        display: flex;
		position: fixed;
        top: -100%; right: 0; bottom: 100%; left: 0;
		background: #000;
        align-items: center;
		overflow-x: hidden;
		overflow-y: auto;
        pointer-events: all;
        z-index: 11;
        scrollbar-width: none;
        transition: top 600ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms, bottom 600ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;

        &::-webkit-scrollbar { display: none; }

        .headerBackground {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 105px;
            background-color: #000;
            z-index: 100;
        }

        .linksWrapper {
            width: 25%;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding-top: 115px;
            padding-left: 130px;
            padding-bottom: 100px;

            @media screen and (max-width: 1800px) { width: 27%; }
            @media screen and (max-width: 1600px) { width: 30%; }
            @media screen and (max-width: 1400px) { width: 35%; }
            @media screen and (max-width: 1200px) { width: 45%; }
            @media screen and (max-width: 1024px) { padding-left: 80px; }
            @media screen and (max-width: 768px) { width: 50%; padding-left: 30px; }
            @media screen and (max-width: 700px) { width: 100%; }

            & > ul, .contact {
                float: left;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-100px);
                transition: opacity 300ms, transform 300ms, visibility 0ms ease 300ms;

                li.title p {
                    margin-bottom: 50px;
                    font: rem(23)/1 $font2b;
                    color: $color2;

                    @media screen and (max-width: 900px) { margin-bottom: 30px; }
                }
            }

            .overlay-menu {

                &__main-level {
                    opacity: 0;
                    transform: translateY(-200px);
                    transition: opacity 1000ms $easeInOutQuart, transform 1000ms $easeInOutQuart;
                }

                &__main-level-title {
                    display: block;
                    color: $color1;
                    padding-bottom: 10px;
                    margin: 0 30px 10px;
                    border-bottom: 1px solid rgba($color1, 0.2);

                    @media screen and (max-width: 600px) { font-size: rem(26); margin: 0 20px 10px; }
                    @media screen and (max-width: 425px) { font-size: rem(24); }
                }

                &__second-level-li:last-child {
                    margin-bottom: 30px;
                }

                &__second-level-button {
                    position: relative;
                    padding-bottom: 20px;
                    color: $color4;
                    transition: padding 700ms $easeInOutQuart, color 300ms $easeInOutQuart;

                    @media screen and (max-width: 500px) {
                        max-width: 230px;
                    }
                }

                &__second-level-svg {
                    width: 10px;
                    height: 10px;
                    margin-left: 10px;
                    fill: $color4;
                    transform: rotate(90deg);
                    transition: transform 700ms $easeInOutQuart;
                }

                &__third-level {
                    height: 0;
                    overflow: hidden;
                    opacity: 0;
                    transition: opacity 700ms $easeInOutQuart, margin-bottom 700ms $easeInOutQuart;
                }

                &__third-level-li { padding-bottom: 20px; }
                &__third-level-li:first-child &__third-level-link{ margin-top: 20px; }
                &__third-level:last-child { margin-bottom: 0; }

                &__third-level-link {
                    margin: 0 30px 0;
                    font-size: rem(25);
                    color: $color4;
                    transition: color 300ms $easeInOutQuart;

                    &:hover {
                        color: $color2;
                    }

                    @media screen and (max-width: 600px) { margin: 0 20px 0; }
                }
            }

            .primary {
                margin-bottom: 60px;

                @media screen and (max-width: 900px)  {
                    margin-bottom: 50px;
                }

                li {
                    &:not(:last-child) {
                        margin-bottom: 15px;

                        @media screen and (max-width: 900px) { margin-bottom: 10px; }
                    }


                    a {
                        color: $color4;
                        transition: color 300ms;
                        font-size: rem(22);

                        &:hover, &:active { color: $color2; }
                    }
                }
            }

            .secondary {
                li {
                    &:not(:last-child) {
                        margin-bottom: 15px;

                        @media screen and (max-width: 900px) { margin-bottom: 20px; }
                    }

                    // &:nth-last-child(2) {
                    //     margin-bottom: 30px;
                    //     @media screen and (max-width: 900px) { margin-bottom: 35px; }
                    // }

                    a {
                        font: rem(15)/1 $font1l;
                        color: $color4;
                        transition: color 300ms;

                        &:hover { color: #fff; }
                    }

                    &.separate {
                        position: relative;
                        padding-bottom: 60px;
                        margin-bottom: 0;

                        &:after {
                            content: '';
                            position: absolute;
                            width: 40px;
                            height: 1px;
                            left: 0;
                            bottom: 30px;
                            background: $color2;

                            @media screen and (max-width: 900px) { left: 50%; transform: translateX(-50%); }
                        }
                    }

                    &.margin {
                        margin-bottom: 115px;

                        @media screen and (max-width: 900px) { margin-bottom: 50px; }
                        @media screen and (max-width: 700px) { margin-bottom: 20px; }
                    }
                }
            }

            .contact {
                width: 100%;
                margin-top: 50px;

                @media screen and (min-width: 700px) { display: none; }

                p, .email {
                    margin-bottom: 5px;
                    font: rem(15)/1.5 $font1;
                    color: $color4;
                }

                a {
                    position: relative;
                    padding-bottom: 5px;
                    font: rem(15)/1 $font1l;
                    color: #fff;
                    transition: color 300ms;

                    &:hover { color: $color2; }
                }

                &:last-child, &:nth-last-child(2) {

                    a {
                        font: rem(12)/1 $font1b;
                        color: $color4;
                        text-transform: uppercase;
                        transition: color 300ms;

                        &:hover { color: #fff; }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .showOverlayMenu {
        #overlay {
            background: rgba(#000, 0.3);
            visibility: visible;
            z-index: 100;
            transition: background 700ms $ease;
        }

        #page-shop header > .hamburger .box,
        #page-contact header > .hamburger .box,
        #page-comingSoon header > .hamburger .box {
            .inner, &:before, &:after { background: #fff; }
        }

        header .newMenu {
            .logo svg {
                fill: #fff;
                transition: fill 600ms $ease;
            }
        }
        header .sideNav {
            .sideNavOverlay {
                bottom: 0%;

                a {
                    opacity: 1;
                    visibility: visible;
                    transition: color 300ms, opacity 300ms linear 500ms;
                }
            }
        }

        .overlay-menu {
            overflow-y: auto;
            visibility: visible;
            transition: right 500ms ease;

            &::-webkit-scrollbar { display: none; }

            .wrapper {
                top: 0%; bottom: 0%;

                @media screen and (max-width: 768px) { left: 0; }

                .linksWrapper {
                    & > ul, .contact {
                        opacity: 1;
                        visibility: visible;
                        transform: none;
                        transition: opacity 700ms linear 500ms, transform 700ms $ease 500ms;
                    }
                }
            }
        }
    }
}
