.today {
    position: relative;
    overflow: hidden;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__shape {
        position: absolute;
        bottom: 0px;
        left: -150px;
        opacity: 0.15;
        height: 970px;
        transform: rotate(30deg);

        @media screen and (max-width: 1400px) { bottom: -50px; }
        @media screen and (max-width: 1200px) { height: 800px; }
        @media screen and (max-width: 1024px) { height: 700px; }
        @media screen and (max-width: 850px)  { height: 600px; }
        @media screen and (max-width: 850px)  { height: 600px; }
    }

    &__image {
        position: absolute;
    }

    &__image-1 {
        width: 479px;
        height: 411px;
        top: 0;
        right: 130px;

        @media screen and (max-width: 1300px) { right: 80px; width: 420px; height: 370px; }
        @media screen and (max-width: 1100px) { right: 50px; width: 380px; height: 320px; }
        @media screen and (max-width: 1024px) { right: 30px; width: 360px; height: 310px; }
        @media screen and (max-width: 850px)  { right: 20px; width: 320px; height: 290px; }
        @media screen and (max-width: 768px)  { width: 240px; height: 240px; }
        @media screen and (max-width: 600px)  {
            top: 320px;
            right: 0;
            width: 200px;
            height: 220px;
        }
        @media screen and (max-width: 500px) {
            width: auto;
            top: auto;
            right: 180px;
            bottom: 0;
            left: 0;
        }
        @media screen and (max-width: 375px) { right: 140px; }
    }

    &__image-2 {
        width: 500px;
        height: 365px;
        bottom: 0;
        left: 700px;

        @media screen and (max-width: 1500px) { left: 550px; }
        @media screen and (max-width: 1400px) { left: 500px; width: 450px; height: 330px; }
        @media screen and (max-width: 1200px) { left: auto; right: 250px; width: 400px; height: 300px; }
        @media screen and (max-width: 1100px) { right: 230px; width: 360px; height: 280px; }
        @media screen and (max-width: 1024px) { right: 150px; width: 320px; height: 250px; }
        @media screen and (max-width: 850px)  { right: 120px; width: 280px; height: 240px; }
        @media screen and (max-width: 768px)  { right: 90px; width: 220px; height: 210px; }
        @media screen and (max-width: 600px)  { display: none; }
    }

    &__image-3 {
        width: 247px;
        height: 423px;
        bottom: 0;
        right: 0;

        @media screen and (max-width: 1300px) { width: 200px; height: 380px; }
        @media screen and (max-width: 1200px) { width: 180px; height: 360px; }
        @media screen and (max-width: 1024px) { width: 120px; height: 300px; }
        @media screen and (max-width: 850px)  { width: 90px;  height: 280px; }
        @media screen and (max-width: 768px)  { width: 70px;  height: 260px; }
        @media screen and (max-width: 600px)  {
            width: 150px;
            height: 300px;
            top: 0;
            bottom: auto;
        }
        @media screen and (max-width: 500px) {
            top: auto;
            bottom: 30px;
            width: 150px;
            height: 280px;
        }
        @media screen and (max-width: 375px) { width: 110px; }
    }

    &__img {
        border-radius: 5px;
    }

    &__container {
        position: relative;
        @extend .x-padding-160;
        padding-top: 215px;
        padding-bottom: 175px;

        @media screen and (max-width: 1400px) { padding-top: 195px; padding-bottom: 155px; }
        @media screen and (max-width: 1300px) { padding-top: 175px; padding-bottom: 130px; }
        @media screen and (max-width: 1200px) { padding-top: 160px; padding-bottom: 120px; }
        @media screen and (max-width: 1100px) { padding-top: 150px; padding-bottom: 110px; }
        @media screen and (max-width: 1024px) { padding-top: 120px; padding-bottom: 100px; }
        @media screen and (max-width: 850px)  { padding-top: 100px; padding-bottom: 80px;  }
        @media screen and (max-width: 768px)  { padding-top: 70px;  padding-bottom: 60px;  }
        @media screen and (max-width: 600px)  { padding-bottom: 70px; }
        @media screen and (max-width: 500px)  { padding-bottom: 270px; }
    }

    &__subtitle {
        @extend .bottom-margin-40;
    }

    &__subtitle-span {
        font: 500 rem(18)/1 $font1;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        vertical-align: middle;
    }

    &__subtitle-svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        stroke: #fff;
        vertical-align: middle;
    }

    &__title {
        font-family: $font1;
        font-weight: 700;
        color: #fff;
        line-height: 1.1;
        @include responsiveFontSize(82, 35);
    }

    &__title-span {
        display: block;
        color: $color3;
    }

    &__text {
        max-width: 250px;
        @extend .top-margin-70;
        font-size: rem(15);
        color: #fff;

        @media screen and (max-width: 500px) { max-width: 400px; }
    }

    &__btn {
        @extend .top-margin-40;
    }
}


.side-and-list {
    &--today &__container {
        padding-top: 0;

        @media screen and (max-width: 1024px) { padding-right: 0; padding-left: 0; }
    }

    &--today &__main {
        padding: 0;
    }

    &--today &__list {
        max-width: 100%;
    }
    &--today &__today {
        width: 100%;
    }

    &--today &__side {
        top: 50px;

        @media screen and (max-width: 1024px) {
            top: 0;
            padding-right: var(--x-padding-160);
            padding-left: var(--x-padding-160);
            padding-top: 50px;
            padding-bottom: 50px;
        }
        @media screen and (max-width: 768px) { padding-top: 40px; padding-bottom: 40px; }
        @media screen and (max-width: 600px) { padding-top: 30px; padding-bottom: 30px; }
    }

    &--today .field {
        margin-bottom: 0 !important;
    }

    &--today &__items {
        background: #fff;
    }

    &--today &__items {
        padding: 0 70px;

        @media screen and (max-width: 1024px) { padding: 0 var(--x-padding-160); }
    }
    &--today &__events {
        padding-bottom: var(--y-margin-70);
        padding-right: 70px;

        @media screen and (max-width: 1024px) { padding-right: var(--x-padding-160); padding-left: var(--x-padding-160); }
    }

    &--today &__title {
        padding-top: var(--y-margin-70);
        @extend .large-title;
    }
    &--today &__title-event {
        padding-bottom: var(--y-margin-50);
    }

    &--today &__next {
        width: 100%;
    }
    &--today &__next &__events {
        padding-bottom: 0;
    }
}


.today-block {
    display: flex;
    flex-flow: row wrap;
    padding: 50px 0;
    align-items: center;

    @media screen and (max-width: 1300px) { padding: 40px 0; }
    @media screen and (max-width: 1200px) { padding: 30px 0; }

    &:not(:last-child) {
        border-bottom: 1px solid $color4;
    }

    &__picture-container {
        align-self: stretch;
    }

    &__picture {
        width: 220px;
        height: 220px;
        margin-right: 60px;
        border-radius: 50%;
        overflow: hidden;

        @media screen and (max-width: 1300px) { width: 200px; height: 200px; margin-right: 50px; }
        @media screen and (max-width: 1200px) { width: 180px; height: 180px; margin-right: 40px; }
        @media screen and (max-width: 768px)  { width: 150px; height: 150px; margin-right: 25px; }
        @media screen and (max-width: 600px)  { display: none; }
    }

    &__content {
        flex: 1;

        @media screen and (max-width: 600px)  { padding-top: 0; flex: initial; width: 100%; }
    }

    &__subtitle {
        @extend .tiny-title;
        margin-bottom: 20px;

        @media screen and (max-width: 1300px) { margin-bottom: 15px; }
        @media screen and (max-width: 1200px) { margin-bottom: 10px; }
    }

    &__title {
        font: rem(28)/1.5 $font1;
        color: $color1;

        @media screen and (max-width: 1024px) { font-size: rem(25); }
        @media screen and (max-width: 768px)  { font-size: rem(22); }
    }

    &__time {
        margin-top: 20px;

        @media screen and (max-width: 1300px) { margin-top: 15px; }
        @media screen and (max-width: 1200px) { margin-top: 10px; }
    }

    &__label-more {
        display: inline;
    }

    &__label-less {
        display: none;
    }

    &__toggle {
        position: relative;
        @extend .tiny-title;
        color: $color6;
        padding-right: 20px;
        margin-top: 50px;

        @media screen and (max-width: 1300px) { margin-top: 40px; }
        @media screen and (max-width: 1200px) { margin-top: 30px; }
    }

    &__svg {
        position: absolute;
        top: 3px;
        right: 0;
        width: 12px;
        height: 12px;
        stroke: $color6;
        transform: rotate(90deg);
        transition: transform 500ms $easeInOutCubic;
    }

    &__accordion-content {
        height: 0;
        will-change: height;
        overflow: hidden;
    }

    &__transition {
        max-width: 600px;
        padding-top: 10px;
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 500ms $easeInOutCubic, transform 500ms $easeInOutCubic;
    }
}
