#page-packages {
    .headerBlock .row .content .extra {
        border-top: none;
        padding-top: 0;

        .field {
            position: relative;
            width: 33%;
            margin-bottom: 0;

            @media screen and (max-width: 768px) { width: 100%; }

            // Erreur
            &__error {
                margin-top: 5px;
                font: rem(11)/1 $font1;
                color: $color6;
                width: 150px;
            }

            // Select
            &--select &__label {
                color: #fff;
            }

            &--select .select-label {
                border: 1px solid #000;
                background: #000;
                height: 64px;
                @media screen and (max-width: 600px) { height: 45px; }

                &:hover {
                    .label-inner {
                        color: $color5;

                        &:before {
                            background: url('../images/content/icons/arrow_grey.svg') no-repeat center / contain;
                        }
                    }
                }
            }

            &--select .label-inner {
                display: flex;
                align-items: center;
                height: 100%;
                font: 15px/1.3 $font1;
                color: #fff;
                transition: color 500ms $easeInOutCubic;

                @media screen and (max-width: 768px) { font-size: 13px; }

                &:before {
                    background: url('../images/content/icons/arrow_white.svg') no-repeat center / contain;
                    transition: background 500ms $easeInOutCubic;
                }
            }

            // Select natif
            &--select select {
                width: 100%;
                padding: 0 20px;
                font: 15px/1.3 $font1;
                color: #fff;
                border: 1px solid #000;
                outline: none;
                -webkit-appearance: none;
                border-radius: 0;
                background: url('../images/content/icons/arrow_white.svg') no-repeat calc(100% - 20px) 50% / 12px 12px, #000;

                @media screen and (max-width: 768px) { font-size: 13px; }
            }
        }
    }

    .packagesBlock {
        background: #fff;

        .col24 {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .forfait {
                margin-bottom: 60px;

                &:not(.primary) {
                    &:nth-child(2n - 1) {
                        width: calc(50% - 30px);
                        margin-right: 30px;
                    }
                    &:nth-child(2n) {
                        width: calc(50% - 30px);
                        margin-left: 30px;
                    }

                    @media screen and (max-width: 900px) {
                        &:nth-child(2n - 1) {
                            width: 100%;
                            margin-right: 0;
                        }
                        &:nth-child(2n) {
                            width: 100%;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    .sectionPagination {
        background: #fff;
    }
}
