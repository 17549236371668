.custom-select {
    position: relative;
    z-index: 1;

    select { display: none !important; }

    &.custom-select-active {
        //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        transition: box-shadow 300ms;

        .custom-select-items {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transition: opacity 300ms;
        }
    }

    .custom-select-selected {
        position: relative;
        z-index: 1;
    }

    .custom-select-items {
        position: absolute;
        max-height: 200px;
        overflow: auto;
        top: 100%;
        right: 0;
        left: 0;
        opacity: 0;
        z-index: 19;
        visibility: hidden;
        pointer-events: none;
        overflow-x: hidden;
        scrollbar-width: none;
        //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        transition: opacity 300ms, visibility 0ms ease 300ms;

        &::-webkit-scrollbar { display: none; }

        .custom-select-item {
            cursor: pointer;
        }
    }
}

.custom-select.style1 {
    max-width: 100%;

    .custom-select-selected {
        padding: 20px 50px 20px 30px;
        font: rem(21)/1 $font1;
        color: $color1;
        background: #fff;
        border: 1px solid $color1;
        cursor: pointer;
        transition: color 300ms, border-color 300ms;

        @media screen and (max-width: 1024px) { padding: 20px 40px 20px 20px; }
        @media screen and (max-width: 768px)  { padding: 15px 40px 15px 20px; font-size: rem(18); }

        &:after {
            content: '';
            position: absolute;
            width: 14px;
            height: 10px;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            transition: transform 300ms;
            background: url('../images/content/icons/greenPointer.svg') no-repeat center / contain;

            @media screen and (max-width: 1024px) { right: 20px; }
        }
    }

    &.custom-select-active {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

        .custom-select-selected {
            border-color: #fff;
            color: rgba($color1, 0.5);

            &:after { transform: translateY(-50%) rotate(-180deg); }
        }
        .custom-select-items {
            transform: none;
            transition: opacity 300ms, transform 300ms;
        }
    }

    .custom-select-items {
        background: #fff;
        border-top: none;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transform: translateY(-30px);
        transition: opacity 300ms, transform 300ms, visibility 0ms ease 300ms;

        .custom-select-item {
            padding: 20px 30px 20px 30px;
            font: rem(21)/1 $font1;
            color: $color1;
            transition: background 300ms, color 300ms;

            @media screen and (max-width: 1024px) { padding: 20px; }
            @media screen and (max-width: 768px)  { padding: 15px 20px 15px 20px; font-size: rem(18); }

            &:hover { background: $color5; }
            &.custom-select-same { background: $color5; }
        }
    }
}
