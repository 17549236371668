.checkboxField {
    label {
        display: inline-block;
        position: relative;
        padding-left: 50px;
        margin: 0 30px 20px 0;
        font: rem(21)/1.5 $font1;
        color: $color1;
        cursor: pointer;
        user-select: none;

        @media screen and (max-width: 768px) { font-size: rem(18); margin: 0 20px 15px 0; padding-left: 40px; }

        input { position: absolute; opacity: 0; cursor: pointer; }

        span {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 3px;
            left: 0;
            border: 1px solid $color1;
            transition: background 225ms, border-color 225ms;

            @media screen and (max-width: 768px) { top: 3px; }

            &:before {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                top: 50%;
                left: 50%;
                background: url('../images/content/icons/crochet.svg') no-repeat center / contain;
                transform: translate(-50%, -50%) scale(0);
                transition: transform 225ms;
            }
        }

        input:checked ~ span { background: $color3; border-color: $color3; }
        input:checked ~ span:before { transform: translate(-50%, -50%) scale(1); }
    }
}
