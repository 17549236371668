#page-packagesDetails {
    .headerBlock .row .content .extra {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 768px) { flex-direction: column; align-items: flex-start; }

        .subtitle {
            font-size: rem(15);
            color: $color5;
        }

        .price {
            display: flex;
            align-items: center;
            font: rem(22) $font2b;
            color: $color2;

            @media screen and (max-width: 768px) { margin-top: 15px; }

            svg {
                height: 40px;
                width: 40px;
                margin-right: 20px;
                fill: #fff;
            }
        }
    }
}
