// Si ces variables ne sont pas accessibles dans ce site, il suffit de les décommenter pour les utiliser.
.c-overlay-cookies {
    --y-30: max(22px + (30 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
    --y-nus-25: max(20px + (25 - 20) * ((100vw - 500px) / (1440 - 500)), 20px);
    --y-nus-20: max(16px + (20 - 16) * ((100vw - 500px) / (1440 - 500)), 16px);
    --x-30: max(22px + (30 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
    --x-nus-25: max(22px + (25 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
}

.c-overlay-cookies {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 10px;
    visibility: hidden;
    pointer-events: none;
    z-index: 110;
}

.c-overlay-cookies__container {
    position: absolute;
    bottom: var(--y-30);
    left: var(--x-30);
    width: 450px;
    padding: var(--y-nus-25) var(--x-nus-25);
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.10);
    opacity: 0;
    pointer-events: none;
    transform: translateY(var(--y-30));
    transition: opacity 800ms cubic-bezier(0.76, 0, 0.24, 1), transform 800ms cubic-bezier(0.76, 0, 0.24, 1);
    will-change: transform;
}

@media screen and (max-width: 500px) {
    .c-overlay-cookies__container {
        width: calc(100% - (var(--x-30) * 2));
    }
}

.c-overlay-cookies__title {
    font-family: $font1b;
    font-size: 1.6em;
    font-weight: 700;
    line-height: 1.6em;
    color: $color1;
}

@media screen and (max-width: 500px) {
    .c-overlay-cookies__title {
        font-size: 1.5em;
    }
}

@media screen and (max-width: 350px) {
    .c-overlay-cookies__title {
        font-size: 1.4em;
    }
}

.c-overlay-cookies__text {
    display: block;
    font-size: 1.4em;
    line-height: 1.6em;
    margin-top: 7px;
}

.c-overlay-cookies__buttons {
    display: flex;
    margin-top: var(--y-nus-20);
}

@media screen and (max-width: 450px) {
    .c-overlay-cookies__buttons {
        flex-direction: column;
    }
}

.c-overlay-cookies__button-find-out-more,
.c-overlay-cookies__button-accept {
    display: flex;
    justify-content: center;
    width: 50%;
    padding-top: 14px;
    padding-right: 0;
    padding-bottom: 14px;
    padding-left: 0;
}

@media screen and (max-width: 450px) {
    .c-overlay-cookies__button-find-out-more,
    .c-overlay-cookies__button-accept {
        width: 100%;
    }
}

.c-overlay-cookies__button-find-out-more {
    /* Ajoutez les styles nécessaires ici */
    margin-right: 10px;
}

@media screen and (max-width: 450px) {
    .c-overlay-cookies__button-find-out-more {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.c-overlay-cookies__button-accept {
    /* Ajoutez les styles nécessaires ici */
    cursor: pointer;
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-cookies .c-overlay-cookies {
    visibility: visible;
}

.show-overlay-cookies .c-overlay-cookies__container {
    opacity: 1;
    pointer-events: all;
    transform: none;
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-cookies.closing-overlay-cookies .c-overlay-cookies__container {
    opacity: 0;
    transform: translateY(var(--y-30));
}

