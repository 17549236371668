.headerBlock {
    position: relative;
    padding-top: 200px;

    @media screen and (max-width: 768px) { padding-top: 150px; }
    @media screen and (max-width: 600px) { padding-top: 125px; }

    &.style1 {
        background: $color1;

        .logo {
            margin-bottom: 120px;
            @media screen and (max-width: 768px) { display: none; }

            svg {
                fill: $color2;
                width: 350px;
                height: 75px;
            }
        }

        .myBreadcrumb {
            display: flex;
            justify-content: space-between;

            li {
                a {
                    color: $color5;
                    transition: color 300ms;

                    &:hover { color: #fff; }
                }

                &:last-child a, &:last-child p {
                    color: #fff;
                }

                &:last-child svg {
                    fill: #fff !important;
                }
            }

            .btnShare {
                color: #fff;

                &:hover {
                    color: $color5;

                    svg {
                        fill: $color5;
                    }
                }

                svg {
                    fill: #fff;
                }
            }
        }

        .content {
            .title {
                h1 {
                    color: $color2;
                }
            }

            .extra {
                border-top: 1px solid $color2;

                & > p {
                    color: $color4;
                }
            }

            & > p {
                color: $color4;
            }

            a {
                color: #fff;

                &:hover {
                    color: $color4;
                }
            }

            .myFilter a {
                color: $color5;

                &:hover { color: #fff; }
                &.active { color: #fff; }
            }

            &.auberge {
                .extra {
                    padding-top: 0;
                }
            }
        }
    }

    &.style2 {
        background: $color3;

        .logo {
            @media screen and (max-width: 768px) { display: none; }

            svg {
                fill: $color1;
                width: 350px;
                height: 75px;
            }
        }

        .myBreadcrumb {
            li {
                a {
                    color: $color5;

                    &:hover { color: $color1; }
                }

                &:last-child a, &:last-child p {
                    color: $color1;
                }
            }

            .btnShare {
                color: $color1;

                &:hover {
                    color: $color5;

                    svg {
                        fill: $color5;
                    }
                }

                svg {
                    fill: $color1;
                }
            }
        }

        .title {
            h1 {
                color: $color1;
            }
        }

        .extra {
            border-top: 1px solid $color4;
        }
    }

    .logo {
        margin-bottom: 120px;
        @media screen and (max-width: 768px) { display: none; }

        svg {
            fill: $color2;
            width: 350px;
            height: 75px;
        }
    }

    .myBreadcrumb {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 600px) { flex-direction: column; }

        .breadcrumb {
            display: flex;
            align-items: center;
            white-space: nowrap;

            & > li {
                display: flex;
                position: relative;

                a, p {
                    font: 15px/1 $font1;

                    @media screen and (max-width: 768px) { font-size: 13px; }
                }

                &:last-child {
                    align-items: center;
                    cursor: default;

                    svg {
                        width: 10px;
                        height: 10px;
                        transform: rotate(90deg);
                        margin-left: 15px;
                        fill: $color1;
                    }
                }

                &:not(:last-child) {
                    margin-right: 30px;
                    transition: color 300ms;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        background: $color5;
                        border-radius: 50%;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -19px;
                        pointer-events: none;
                    }
                }
            }
        }

        .btnShare {
            font: 12px/1 $font1b;
            text-transform: uppercase;
            transition: color 300ms;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media screen and (max-width: 600px) { justify-content: flex-start; margin-top: 30px; }

            svg {
                width: 15px;
                height: 15px;
                transition: fill 300ms;
                margin-left: 10px;
                overflow: visible;
            }
        }
    }

    .content {
        position: relative;

        .title {
            margin-top: 80px;

            @media screen and (max-width: 768px) { margin-top: 70px; }
            @media screen and (max-width: 600px) { margin-top: 60px; }

            h1 {
                line-height: 1.1;
            }

            .btn:not(.btn.darkWhite) {
                @media screen and (max-width: 768px) {
                    display: inline-block;
                }
            }

            &.--contact .btn {
                margin-top: 20px;
                margin-bottom: 40px;
                @media screen and (max-width: 1024px) { margin-top: 15px; margin-bottom: 35px; }
                @media screen and (max-width: 768px)  { margin-top: 10px; margin-bottom: 25px; }
            }

            &.--contact h1 {
                line-height: 1;
                height: 1;
            }

            .inns {
                margin-top: 30px;

                p {
                    font-size: rem(14);
                    color: $color5;
                }

                .smallMarginTop {
                    margin-top: 5px;
                }
            }
        }

        .extra {
            margin-top: 35px;
            padding-top: 35px;

            & > p {
                font-size: rem(22);
                line-height: 1.9;
            }

            &.--contact {
                margin-top: 0px;
            }

            @media screen and (max-width: 1024px) { margin-top: 20px; padding-top: 20px; }
            @media screen and (max-width: 768px)  { margin-top: 15px; padding-top: 15px; }
        }

        & > p {
            margin-top: 50px;
            font-size: rem(22);
            line-height: 1.9;
        }
    }

    .myFilter {
        margin: 65px 0 100px;
        text-align: center;

        @media screen and (max-width: 1024px) { margin: 50px 0 75px; }
        @media screen and (max-width: 768px)  { margin: 30px 0 50px; }

        h5 {
            margin-bottom: 40px;

            @media screen and (max-width: 768px) { margin-bottom: 30px; }
        }

        & > ul {
            @media screen and (max-width: 600px) { display: none; }

            li {
                display: inline-block;
                position: relative;

                a {
                    position: relative;
                    font: 22px/1 $font1l;
                    transition: color 300ms;

                    &:before {
                        content: '';
                        position: absolute;
                        height: 1px;
                        bottom: -5px;
                        right: 50%;
                        left: 50%;
                        background: #fff;
                        transition: right 300ms ease, left 300ms ease;
                    }

                    &.active {
                        &:before { right: 0; left: 0; }
                    }
                }

                &:not(:last-child) {
                    margin-right: 75px;

                    @media screen and (max-width: 800px) { margin-right: 30px; }

                    &:after {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        background: #fff;
                        border-radius: 50%;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -40px;
                        pointer-events: none;

                        @media screen and (max-width: 800px) { right: -20px; }
                    }
                }
            }
        }

        .select-field {
            display: none;
            margin-bottom: 0;

            @media screen and (max-width: 600px) { display: block; }
        }
    }
}
